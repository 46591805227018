import {Link} from "react-router-dom";
import Datatables from "../../components/Datatables";
import { useNavigate,useLocation } from 'react-router-dom';
import {Helmet} from "react-helmet";
import useHook from "./hook";
import { useEffect } from "react";


function ScheduleList() {
    const navigate = useNavigate()
    const location = useLocation();
    const {handleScheduleTab
    } =  useHook()

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>XTLMS | Schedule List</title>
            </Helmet>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h5 className="m-0 heading-lines pb-2 fs-20px">Schedule List</h5>
                        </div>
                        <div className="col-auto mb-2">
                            <Link to="/schedule/create" className="btn btn-blue mb-1" title="Create Schedule">
                                Create Schedule
                            </Link>
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                            <button
                                                className="nav-link text-blue active fw-medium"
                                                data-bs-toggle="tab"
                                                data-bs-target="#activeSchedules"
                                                type="button"
                                                role="tab"
                                                title="Active Schedules"
                                                onClick={() => handleScheduleTab("active")}
                                            >
                                                Active
                                            </button>
                                        </li>
                                        {/* <li className="nav-item" role="presentation">
                                            <button
                                                className="nav-link text-blue  fw-medium"
                                                data-bs-toggle="tab"
                                                data-bs-target="#allSchedules"
                                                type="button"
                                                role="tab"
                                                title="Active Schedules"
                                                onClick={() => handleScheduleTab("all")}
                                            >
                                                All
                                            </button>
                                        </li> */}
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className="nav-link text-dark fw-medium"
                                                data-bs-toggle="tab"
                                                data-bs-target="#inactiveSchedules"
                                                type="button"
                                                role="tab"
                                                title="Inactive Schedules"
                                                onClick={() => handleScheduleTab("inactive")}
                                            >
                                                Inactive
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className="nav-link text-dark fw-medium"
                                                data-bs-toggle="tab"
                                                data-bs-target="#expired"
                                                type="button"
                                                role="tab"
                                                onClick={() => handleScheduleTab("expired")}
                                                title="Expired"
                                            >
                                                Expired
                                            </button>
                                        </li>
                                    </ul>
                                    <div className="tab-content border p-3 border-top-0">
                                        {/* <div className="tab-pane fade" id="allSchedules" role="tabpanel">
                                            <Datatables dt_name="schedule_all" />
                                        </div> */}
                                        <div className="tab-pane fade show active" id="activeSchedules" role="tabpanel">
                                            <Datatables dt_name="schedule_active" />
                                        </div>
                                        <div className="tab-pane fade" id="inactiveSchedules" role="tabpanel">
                                            <Datatables dt_name="schedule_inactive" />
                                        </div>

                                        <div className="tab-pane fade" id="expired" role="tabpanel">
                                            <Datatables dt_name="schedule_expired" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ScheduleList;
