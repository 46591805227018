import { createRoot } from "react-dom/client";
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import Datatables, { reloadUrlDataTable } from '../../components/Datatables';
import { ModalSection } from '../../components/Helper';

function  Recipient(props) {
    const listType = useParams().list_type

    const [recipient_dt] = useState({
        dt_url: `recipient-list`,
        dt_name: 'recipient_table',
        dt_column: [
            { data:"id", name:'id', title: "#ID"},
            { data:"name", name:'users.name', title: "Name"},
            { data:"email", name:'users.email', title: "Email"},
            { data:"user_type", name:'roles.name', title: "User Type"},
            { data:"time", name:"time", title:"Last Notified", searchable:false,},
            { data:"id", title: 'Action', sortable:false, searchable:false, class:"text-center text-nowrap", width:160}
        ],
        dt_column_defs: [
            {
                targets: 5,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(<>
                        <button type="button" className="table-icons-text" title="View" data-bs-target="#recipientDetails" data-bs-toggle="modal" onClick={() => recipientDetails(rowData.id, 'all')}>
                            View
                        </button>                                      
                    </>)
                }
            },
        ]
    })


    useEffect(() => {
        if(listType === 'recipient'){
            reloadUrlDataTable(recipient_dt, `recipient-list`)
        }
    }, [listType, recipient_dt])

    const recipientDetails = (id, type) => {
        let dt_recipients_list_details = {
            dt_url: `recipient-list/${id}/${type}`,
            dt_name: 'recipient_details_list',
            dt_column: [
                { data:"id", name:'id', title: "No."},
                { data:"time", name:'time', title:"Time", searchable:false,},
                { data:"subject", name:'subject', title: "Title"},
                { data:"message", name:'message', title: "EngLish Message"},
                { data:"arabic_message", name:'arabic_message', title: "Arabic Message"},
                { data:"notification_type", name:'notification_type', title: "Type (Auto/Manual)"},
                { data:"status", name:'status', title: "Status"},
            ],
            dt_column_defs: [
                {
                    targets: 4,
                    createdCell: (td, cellData, rowData, row, col) => {
                        createRoot(td).render(<>
                            <span dir='rtl'>{rowData.arabic_message}</span>
                        </>)
                    }
                },
                {
                    targets: 5,
                    createdCell: (td, cellData, rowData, row, col) => {
                        createRoot(td).render(<>
                            <span>{rowData.notification_type === 'autofi' ? 'Auto' : 'Manual'}</span>
                        </>)
                    }
                },
            ]
        };
        reloadUrlDataTable(dt_recipients_list_details, `recipient-list/${id}/${type}`)
    }


    return(
        <>
            <div className="table-responsive p-1">
                <Datatables dt_name="recipient_table"/>
            </div>

            <ModalSection title="Recipients Details" modal_id="recipientDetails" size="xl">
                <div className="table-responsive p-1">
                    <Datatables dt_name="recipient_details_list"/>
                </div>
            </ModalSection>
        </>
    )
}

export default Recipient
