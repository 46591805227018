import React, { useEffect, useState } from 'react';
import { Route, Routes } from "react-router-dom";
import {useLocation} from "react-router";

import AboutUs from '../modules/AboutUs';
import AccountCreate from '../modules/account/AccountCreate';
import AccountEdit from '../modules/account/AccountEdit';
import AccountList from '../modules/account/AccountList';
import AccountView from '../modules/account/AccountView';
import ContactUs from '../modules/ContactUs';
import Course from '../modules/Course';
import EduClsList from '../modules/educls/EduClsList';
import EduClsCreate from '../modules/educls/EduClsCreate';
import Dashboard from '../modules/Dashboard';
import Home from '../modules/Home';
import JoinCourse from '../modules/JoinCourse';
import Login from '../modules/Login';
import ScheduleCreate from '../modules/schedule/ScheduleCreate';
import ScheduleEdit from '../modules/schedule/ScheduleEdit';
import ScheduleList from '../modules/schedule/ScheduleList';
import SignUp from '../modules/SignUp';
import Teacher from '../modules/Teacher';
import TeacherInfo from '../modules/TeacherInfo';
import TimeTable from '../modules/TimeTable';
import Context from './Context';
import ContactList from '../modules/contact/ContactList';
import { fetchData, clearAllIntervals } from './Helper';
import FrontendLayout, { BackendLayout, FrontendProfileLayout } from './Layout';
import EduClsEdit from '../modules/educls/EduClsEdit';
import CourseList from '../modules/course/CourseList';
import CourseCreate from '../modules/course/CourseCreate';
import CourseEdit from '../modules/course/CourseEdit';
import FeedbackList from '../modules/feedback/FeedbackList';
import FeedbackCreate from '../modules/feedback/FeedbackCreate';
import FeedbackEdit from '../modules/feedback/FeedbackEdit';
import NotificationList from '../modules/notification/NotificationList';
import ActivityList from '../modules/notification/ActivityLogList';
import CourseView from '../modules/course/CourseView';
import ForgotPassword from '../modules/ForgotPassword';
import ResetPassword from '../modules/ResetPassword';
import Payment from '../modules/Payment';
import TeacherCourses from '../modules/account/TeacherCourses';
import NotFound from './NotFound';
import ProfileChangePassword from '../modules/myaccount/ProfileChangePassword';
import VerifyEmail from '../modules/VerifyEmail';
import Privacy from '../modules/Privacy';
import TermCondition from '../modules/TermsCondition';
import Setting from '../modules/cms/Setting';
import Faq from '../modules/Faq';
import ClassOver from "../modules/myaccount/ClassOver";
import BusinessModel from '../modules/BusinessModel';
import VerifyPayment from '../modules/VerifyPayment';
import HelpVideoList from '../modules/HelpVideo/HelpVideoList';
import TelegramLog from '../modules/telegram/TelegramLog';
import HelpVideoCreate from '../modules/HelpVideo/HelpVideoCreate';
import HelpVideoEdit from '../modules/HelpVideo/HelpVideoEdit';
import PDFViewer from '../modules/PDFViewer';
import Currency from '../modules/Currency';
import VerifyToken from '../modules/VerifyToken';
import CourseClasses from '../modules/course/CourseClasses';

export default function Routing () {
    const location = useLocation();
    const [context, setContext] = useState();
    const [getPermession, setGetPermession] = useState([]);
    const [token] = useState(localStorage.getItem('wt-token'));
    
    useEffect(() => {
        if(!location.pathname.match("/payments")){
            clearAllIntervals();
        }
    },[location.pathname]);

    useEffect(() => {
        fetchData('get-site-data', 'get', '', false, false, (res) => {
            if(res.status){
                setContext(prevState => ({
                    ...prevState,
                    site: res.data
                }))
            }
        }, (err) => {}, false, false)

        if(token){
            document.getElementById('loader').classList.remove('active')
            fetchData('get-auth-user-info', 'get', '', true, false, (res) => {
                if(res.status){
                    setGetPermession(res.data.permission);
                    document.getElementById('loader').classList.add('active')
                    setContext(prevState => ({
                        ...prevState,
                        auth: res.data
                    }))
                }
            }, (err) => {}, false, false)
        }
    }, [token] );

    return (
        <>  
            <div className="loader active" id="loader">
                <img className="loader-zoomer" src="/images/loader.png" alt="" />
            </div>
            
            <Context.Provider value={[context, setContext]} >
                <Routes>
                    <Route path="/" element={<FrontendLayout/>}>
                        <Route path="/" caseSensitive={false} element={<Home />} />
                        <Route path="/home" caseSensitive={false} element={<Home />} />
                        <Route path="/courses" caseSensitive={false} element={<Course/>} />
                        <Route path="/time-table" caseSensitive={false} element={<TimeTable/>} />
                        <Route path="/teachers" caseSensitive={false} element={<Teacher/>} />
                        <Route path="/about-us" caseSensitive={false} element={<AboutUs/>} />
                        <Route path="/contact-us" caseSensitive={false} element={<ContactUs/>} />
                        <Route path="/pdfview" caseSensitive={false} element={<PDFViewer/>} />
                        <Route path="login" caseSensitive={false} element={<Login />} />
                        <Route path="/sign-up" caseSensitive={false} element={<SignUp/>} />
                        <Route path="/course/:course_id/join" caseSensitive={false} element={<JoinCourse/>} />
                        <Route path="/teacher-info/:teacher_id" caseSensitive={false} element={<TeacherInfo/>} />
                        <Route path="/forgot-password" caseSensitive={false} element={<ForgotPassword/>} />
                        <Route path="/reset-password/:token/:email" caseSensitive={false} element={<ResetPassword/>} />
                        <Route path="/verify-email" caseSensitive={false} element={<VerifyEmail/>} />
                        <Route path="/verify-payment" caseSensitive={false} element={<VerifyPayment/>} />
                        <Route path="/verify-token" caseSensitive={false} element={<VerifyToken/>} />
                        <Route path="/terms-and-conditions" caseSensitive={false} element={<TermCondition/>} />
                        <Route path="/privacy-policy" caseSensitive={false} element={<Privacy/>} />
                        <Route path="/faq" caseSensitive={false} element={<Faq/>} />
                        <Route path="/class-over" caseSensitive={false} element={<ClassOver/>} />
                        <Route path="/XTLMS-Website-business-model-8fz475af94ht23w" caseSensitive={false} element={<BusinessModel/>} />
                        
                    </Route>
                    <Route path="/" element={<BackendLayout module={1} permission={getPermession}/>}>
                        <Route  path="/dashboard" caseSensitive={false} element={<Dashboard />} />
                        <Route  path="/currency" caseSensitive={false} element={<Currency />} />

                        {['admin', 'teacher', 'student', 'parent', 'assistant'].map((item, index) => {
                            return(
                                <>
                                 <React.Fragment key={index}>
                                    <Route module={2} exact path={`/accounts/${item}/list/:list_type?`} caseSensitive={false} element={<AccountList />} />
                                    <Route module={2} exact path={`/accounts/${item}/create`} caseSensitive={false} element={<AccountCreate/>} />
                                    <Route module={2} exact path={`/accounts/${item}/profile/:user_id`} caseSensitive={false} element={<AccountView/>} />
                                    <Route module={2} exact path={`/accounts/${item}/edit/:user_id`} caseSensitive={false} element={<AccountEdit/>} />
                                </React.Fragment>
                                </>
                            )
                        })} 
                        <Route module={2} exact path="/accounts/teacher/courses/:user_id" caseSensitive={false} element={<TeacherCourses/>} />   
                        <Route module={9} exact path="/educational-classification/:eduCls_type/list" caseSensitive={false} element={<EduClsList/>} />
                        <Route module={9} exact path="/educational-classification/:eduCls_type/create" caseSensitive={false} element={<EduClsCreate/>} />
                        <Route module={9} exact path="/educational-classification/:eduCls_type/edit/:id" caseSensitive={false} element={<EduClsEdit/>} />   
                        <Route module={8} exact path="/course/classes" caseSensitive={false} element={<CourseClasses/>} />
                        <Route module={8} exact path="/course/list" caseSensitive={false} element={<CourseList/>} />
                        <Route module={8} exact path="/course/create" caseSensitive={false} element={<CourseCreate/>} />
                        <Route module={8} exact path="/course/details/:course_id" caseSensitive={false} element={<CourseView/>} />
                        <Route module={8} exact path="/course/list/:list_type?" caseSensitive={false} element={<CourseList/>} />
                        <Route module={8} exact path="/course/draft/edit/:course_id" caseSensitive={false} element={<CourseEdit/>} />
                        <Route module={8} exact path="/course/edit/:course_id" caseSensitive={false} element={<CourseEdit/>} />
                        <Route module={15} exact path="/contact/list" caseSensitive={false} element={<ContactList/>} />
                        <Route module={10} exact path="/schedule/list" caseSensitive={false} element={<ScheduleList/>} />
                        <Route module={10} exact path="/schedule/create" caseSensitive={false} element={<ScheduleCreate/>} />
                        <Route module={10} exact path="/schedule/edit/:schedule_id" caseSensitive={false} element={<ScheduleEdit/>} />
                        <Route module={14} exact path="/feedback/list" caseSensitive={false} element={<FeedbackList/>} />
                        <Route module={14} exact path="/feedback/create" caseSensitive={false} element={<FeedbackCreate/>} />
                        <Route module={14} exact path="/feedback/edit/:id" caseSensitive={false} element={<FeedbackEdit/>} />
                        <Route module={13} exact path="/notification/:list_type?/list" caseSensitive={false} element={<NotificationList/>} />
                        <Route module={12} exact path="/activity/:list_type?/list" caseSensitive={false} element={<ActivityList/>} />
                        <Route module={11} exact path="/payments" caseSensitive={false} element={<Payment/>} />
                        <Route exact path="/get-videos/:video_type/list" caseSensitive={false} element={<HelpVideoList />} />
                        <Route exact path="/telegram-log" caseSensitive={false} element={<TelegramLog />} />
                        <Route exact path="/get-videos/:video_type/create" caseSensitive={false} element={<HelpVideoCreate />} />
                        <Route exact path="/get-videos/:video_type/edit" caseSensitive={false} element={<HelpVideoEdit />} />
                        <Route exact path="/settings" caseSensitive={false} element={<Setting/>} />

                    </Route>

                    {['my-profile', 'my-subjects', 'my-schedule', 'my-wallet', 'library', 'recordings', 'recording', 'assignments', 'assignment', 'quizzes', 'quizz', 'performance', 'timing', 'my-classes', 'my-students', 'my-student', 'notes', 'note'].map((item, index) => {
                        return(
                            <>
                                <React.Fragment key={index}>
                                <Route path={`${item}/:details?/:user_id?/:course_id?`} element={<FrontendProfileLayout />} />
                                </React.Fragment>
                            </>
                        )
                    })}

                    { context && context.auth && (context.auth.role_id === 1 || context.auth.role_id === 2) ?
                        <Route path="/change-password" element={<BackendLayout module={1} />}><Route module={1} path="/change-password" caseSensitive={false} element={<ProfileChangePassword />} /><Route /></Route>
                        :
                        <Route path="/change-password" element={<FrontendProfileLayout />}>
                            <Route module={10} path="/change-password" caseSensitive={false} element={<ProfileChangePassword />} />
                        </Route>
                    }

                    <Route path="*" element={<FrontendLayout/>} > <Route path="*" caseSensitive={false} element={<NotFound />} /></Route>

                </Routes>
            </Context.Provider>
        </>
    )
}
