import React from "react";
import { useLocation } from "react-router-dom";
import Context from "../../components/Context";
import { useEffect, useState, useContext } from "react";
import { fetchData, downloadFile, getFileExtension, showAlert } from "../../components/Helper";
import Pagination from "../../components/Pagination";
import _, { isEmpty } from "lodash";
import MaterilDetailsBoby from "./components/MaterilDetailsBoby";
import MaterilDetailsViewModal from "./components/MaterilDetailsViewModal";
import MaterilDetailsAddModal from "./components/MaterilDetailsAddModal";
import MaterilDetailsSubmissionModal from "./components/MaterilDetailsSubmissionModal";

function AssignmentQuizzDetails(props) {
    const location = useLocation();
    const userId = props.student_id;
    const courseId = props.course_id;
    const materialType = props.material;
    const [context] = useContext(Context);
    const roleId = context.auth.role_id;

    const [materialList, setMaterialList] = useState();
    const [totalGrade, setTotalGrade] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [statusType, setStatusType] = useState("active");
    const [reload, setReload] = useState(1);
    const [materialId, setMaterialId] = useState(0);
    const [action, setAction] = useState({});
    const [submissionData, setSubmissionData] = useState();
    const [tinyloader, setTinyloader] = useState(false);

    useEffect(() => {
        if (context && context.auth) {
            fetchData(`subject-type-list?id=${courseId}&type=${materialType}&user_id=${userId}&status=${statusType}&page=${currentPage}`, "GET", "", true, false, (res) => {
                setMaterialList(res.data);
                document.getElementById("group-title").innerHTML = res.data && res.data[1] ? res.data[1] : "";
            }, (err) => { }
            );
        }
    }, [context, currentPage, statusType, reload, props.student_id, courseId, materialType, userId]);

    const handlePageClick = (data) => {
        setCurrentPage(data.selected >= 0 ? data.selected + 1 : 0);
    };

    const deleteMaterial = (id) => {
        fetchData(`delete-material/${id}`, "POST", { pathname: location.pathname }, true, false, (res) => {
            if (res.status) {
                setReload(reload + 1);
            }
        }, (err) => { });
    };

    const getMaterial = (id) => {
        fetchData(`get-material/${id}`, "POST", { course_id: courseId, user_id: userId, pathname: location.pathname }, true, false, (res) => {
            if (res.status && res.message === "") {
                if (res.data.type === "link") {
                    window.open(res.data.notes, "_blank");
                } else {
                    fetchData(`download-task/${id}`, "GET", "", true, false, (file) => downloadFile(file, `${res.data.module_type}-${res.data.download_name}`), (err) => { }, true);
                }
            }
        },
            (err) => { }
        );
    };

    const viewSubmission = (id) => {
        fetchData(`view-submission/${id}`, "GET", "", true, false, (res) => {
            if (res.status) {
                setSubmissionData(res.data);
                setTotalGrade(res.data[0].total_grade);
            }
        }, (err) => { }
        );
    };

    const downloadReturnTask = (id) => {
        fetchData(`download-teacher-return-task/${id}`, "GET", "", true, false, (res) => {
            downloadFile(res, `return|${materialType}`);
        }, (err) => { }, true);
    };

    const downloadSubmitMaterial = (id) => {
        fetchData(`view-return-task/${id}`, "GET", "", true, false, (res) => {
            if (res.status) {
                fetchData(`download-return-task/${id}`, "GET", "", true, false, (file) => {
                    downloadFile(file, `${res.data.user_id}|${res.data.material_id}`);
                }, (err) => { }, true);
            }
        }, (err) => { });
    };

    const updatePDF = (id, action) => {
        const formData = new FormData();
        formData.append("req_id", id);
        formData.append("user_id", context.auth.id);
        formData.append("redirect_to", action);
        setTinyloader(true);
        fetchData("directlogin", "POST", formData, true, true, (res) => {
            setTinyloader(false);
            if (res.status && res.redirect_to) {
                window.location.replace(`${res.redirect_to}`);
            } else {
                let msg = { message: "OOps! Something went wrong.", status: false };
                showAlert(msg);
            }
        },
            (err) => { }
        );
    };

    return (
        <>
            <MaterilDetailsBoby roleId={roleId} materialType={materialType} statusType={statusType} setStatusType={setStatusType} setMaterialId={setMaterialId} setCurrentPage={setCurrentPage} pagination={<Pagination paginate_data={materialList ? materialList[0] : ""} side="end" onPageChange={handlePageClick} />}>
                {materialList && materialList[0].data && !isEmpty(materialList[0].data) ? (
                    _.toArray(materialList[0].data).map((record, key) => {
                        return (
                            <tr key={key}>
                                <>
                                    <td>{record.date}</td>
                                    <td>{record.name}</td>
                                    <td>{record.due_date}</td>
                                    <td>
                                        {(roleId === 5 || roleId === 6) && record.status === "Over" ? "Missed" : _.startCase(_.replace(record.status, "-", " "))}
                                    </td>
                                    <td className="text-center d-xl-flex">
                                        {(roleId === 3 || roleId === 4) && <>
                                            <button type="button" className="btn btn-dark btn-sm px-3 m-1" data-bs-toggle="modal" data-bs-target="#materialModal" onClick={() => setMaterialId(record.id)} title="Edit">
                                                Edit
                                            </button>
                                            {statusType === "draft" ? <>
                                                <button type="button" className="btn btn-danger btn-sm px-3 m-1" onClick={() => deleteMaterial(record.id)} title="Delete">
                                                    Delete
                                                </button>
                                            </> : <>
                                                <button type="button" className="m-1 btn btn-success btn-sm minw-120px" data-bs-toggle="modal" data-bs-target={`#viewModal`} onClick={() => viewSubmission(record.id)} title="Submission">
                                                    Submission
                                                </button>
                                            </>}
                                        </>}
                                        {(roleId === 5 || roleId === 6) && <>
                                            {" "}
                                            <button type="button" className="btn btn-success btn-sm px-3 m-1" disabled={record.disabled} onClick={() => getMaterial(record.id)} title="Get">
                                                Get
                                            </button>
                                            {roleId === 6 && <>
                                                <button type="button" className={`m-1 btn btn-${record.status === "Editted-submission" || record.status === "Submitted" ? "dark" : "danger"} btn-sm minw-120px g`}
                                                    disabled={record.disabled || record.status === "Marked" ? true : false} data-bs-toggle="modal" data-bs-target="#submissionModal" onClick={() => setAction({type: "return-task",id: record.id, restricted_pdf:record.restricted_pdf})}
                                                    title={record.status === "Editted-submission" || record.status === "Submitted" || record.status === "Marked" ? "Edit" : "Return"}>
                                                    {record.status === "Editted-submission" || record.status === "Submitted" || record.status === "Marked" ? "Edit" : "Return"}
                                                </button>
                                            </>}
                                            <button type="button" className="m-1 btn btn-blue btn-sm px-3" disabled={!record.return_task} onClick={() => downloadReturnTask(record.return_submisstion_id)} title="Corrected">
                                                Corrected
                                            </button>
                                        </>}
                                    </td>
                                </>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={materialType === "note" ? 4 : 5} className="text-danger text-center">
                            No data available
                        </td>
                    </tr>
                )}
            </MaterilDetailsBoby>

            <MaterilDetailsAddModal materialId={materialId} materialType={materialType} setMaterialId={setMaterialId} courseId={courseId} setReload={setReload} />
            <MaterilDetailsSubmissionModal action={action} totalGrade={totalGrade}  roleId={roleId} materialType={materialType} courseId={courseId} setReload={setReload} downloadReturnTask={downloadReturnTask} />
            
            <MaterilDetailsViewModal materialType={materialType}>
                {submissionData && !_.isEmpty(submissionData) ? (
                    submissionData.map((item, index) => {
                        var btnTitle = ""
                        var extension_submission = item.submission && getFileExtension(item.submission);
                        if (extension_submission && extension_submission !== "pdf") {
                            btnTitle = `Unsupported file format ${extension_submission}`;
                        } else {
                            btnTitle = "Update PDF";
                        }
                        return (
                            <tr key={index}>
                                <td>{item.id}</td>
                                <td>{_.capitalize(item.name)}</td>
                                <td>{item.email}</td>
                                <td>{_.startCase(_.replace(item.status, "-", " "))}</td>
                                <td>{item.date}</td>
                                <td>
                                    <div className="text-nowrap">
                                    <button type="button" className="btn btn-danger text-white px-4 mx-1" disabled={item.status === "Pending" ? true : false} onClick={() => updatePDF(item.id, "editpdf")} title={`${btnTitle}`} >
                                        <i className="fa fa-pencil-square-o text-white"></i>{" "}
                                        PDF
                                    </button>
                                    <button type="button" className="btn btn-blue px-4" disabled={item.status === "Pending" ? true : false} onClick={() => downloadSubmitMaterial(item.id)} title="Download" >
                                        Download
                                    </button>
                                    <button type="button" className="btn btn-orange px-4 mx-1" data-bs-toggle="modal" data-bs-target="#submissionModal" disabled={item.status === "Pending" ? true : false} onClick={() => setAction({type: "view-mark", id: item.id})} title="Mark">
                                        Mark
                                    </button>
                                    <button type="button" className="btn btn-success px-4" data-bs-toggle="modal" data-bs-target="#submissionModal" disabled={item.status === "Pending" ? true : false} onClick={() => setAction({type: "return-task-by-teacher", id: item.id})} title="Return">
                                        Return
                                    </button>
                                    </div>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan="5" className="text-danger text-center">
                            No data available
                        </td>
                    </tr>
                )}
            </MaterilDetailsViewModal>
        </>
    );
}

export default AssignmentQuizzDetails;
