import { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate } from 'react-router-dom'
import Context from '../components/Context'
import { fetchData,scrollbarSetToTop, validateForm } from '../components/Helper'
import InnerBanner from '../components/InnerBanner'

function Login(){
    const navigate = useNavigate()
    const [context, setContext] = useContext(Context)
    const [credentials, setCredentials] = useState({email: "", password: ""})
    const [token] = useState(localStorage.getItem('wt-token'))

    useEffect(() => {
        scrollbarSetToTop();
        if(token){
            if(context && context.auth){
                if(context.auth.role_id === 1 || context.auth.role_id === 2){
                    navigate('/dashboard')
                }else{
                    navigate('/my-profile')
                }
            }
        }
    }, [token, context,  navigate])

    const handleInputChange = (e) => {
        if(e.target.name === 'password'){
            document.getElementById("floatingPassword").classList.remove('is-invalid');
        }
        setCredentials(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const submitLoginForm = (e) => {
        e.preventDefault();
        if(validateForm(e)){
            let password_length = document.getElementById("floatingPassword")
            if(password_length.value.length < 8) {
                password_length.classList.add('is-invalid'); 
            }else{
                fetchData('login', 'POST', credentials, false, false, (res) => {
                    if(res.status && res.data){
                        localStorage.setItem("wt-token", res.data.token);
                        setContext(prevState => ({
                            ...prevState,
                            auth: res.data
                        }))
                        if(res.data.role_id === 1 || res.data.role_id === 2){
                            navigate('/dashboard')
                        }else{
                             navigate('/my-profile')
                        }
                    }
                }, (err) => {})
            }
        }
    }

    return(
        <>      
        <InnerBanner title="Login" />
        <section className="login-section">
            <Helmet>
                <meta charSet="utf-8" />
                <title>XTLMS | Login</title>
            </Helmet>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 offset-lg-3">
                        <form action="#" method="post" className="p-4 p-sm-5 rounded-4 w-100 needs-validation" noValidate style={{boxShadow:'0px 0px 60px 0px rgba(0, 0, 0, 0.05)'}}>
                            <div className='mb-4 text-center'>
                                <h3 className="heading-lines mb-1 mt-0">Welcome back!</h3>
                                <p className='mb-0 opacity-75'>Step-by-Step Guide to Accessing Your <br /> Learning Management System</p>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control rounded-5 px-4" id="floatingEmail" autoFill="false" name="email" onChange={handleInputChange} placeholder="name@example.com" required />
                                <label htmlFor="floatingEmail " className=' px-3'>Email address*</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="password" className="form-control rounded-5 px-4" id="floatingPassword" autoFill="false" name="password" onChange={handleInputChange} placeholder="*********" required />
                                <label htmlFor="floatingPassword" className=' px-3'>Password*</label>
                                <em className='small text-success'>Please enter minimum 8 characters.</em>
                            </div>
                            <div className="mb-4">
                                <button type="submit" className="btn btn-blue btn-lg rounded-5 w-100" onClick={submitLoginForm} title='LOGIN'>LOGIN</button>
                            </div>
                            <div className="d-flex justify-content-between">
                                <Link to="/forgot-password" className="anchor-blueorange fw-medium" title='Forgot Password?'>Forgot Password?</Link>
                                <Link to="/sign-up" className="anchor-blueorange fw-medium" title='Sign Up'>Sign Up</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Login
