import {createRoot} from "react-dom/client";
import {useEffect, useState} from "react";
import {useParams} from "react-router";
import {useLocation, useNavigate} from "react-router-dom";
import Datatables, {reloadDataTable} from "../../components/Datatables";
import InviteStudent from "./InviteStudent";
import {fetchData} from "../Helper";
import SendReminderButton from "./SendReminderButton";

function EnrollStudents(props) {
    const courseId = useParams().course_id;
    const navigate = useNavigate();
    const location = useLocation();

    const [enroll_student_dt] = useState({
        dt_url: `get-enroll-student-of-course/${courseId}`,
        dt_name: "enroll_student",
        dt_column: [
            {data: "id", name: "users.id", title: "#IDs"}, 
            {data: "name", name: `users.name`, title: " Student Name"},
            {data: "email", name: "users.email", title: "Student Email"},
            {data: "created_at", name: "student_courses.created_at", title: "Joined Date"},
            {data: "withdraw_date", name: "student_courses.withdraw_date", title: "Withdrawal Date"},
            {data: "join_type", name: "student_courses.join_type", title: "Join As"},
            {data: "source", name: "student_courses.source", title: "Source"},
            {data: "status", name: "student_courses.status", title: "Status"},
            { data: "student_course_id", title: "Action",  sortable: false, searchable: false, class: "text-nowrap text-center",  },
        ],

        dt_column_defs: [
            {
                targets: 3,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(
                        <>
                            {rowData.join_type === "External" && rowData.status === "Pending"
                                ? "NA"
                                : rowData.created_at}
                        </>
                    );
                },
            },
            {
                targets: 4,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(
                        <>
                            {rowData.join_type === "External" && rowData.status === "Pending"
                                ? "-"
                                : rowData.withdraw_date}
                        </>
                    );
                },
            },
            {
                targets: 7,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(
                        <>
                            {rowData.status === "active" ? (
                                <span className="badge bg-success">Active</span>
                            ) : rowData.status === "Pending" ? (
                                <span className="text-warning fw-medium">Pending</span>
                            ) : (
                                <span className="badge bg-danger">Withdraw </span>
                            )}
                        </>
                    );
                },
            },
            {
                targets: 8,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(
                        <>
                            {rowData.join_type === "External" && rowData.status === "Pending" ? (
                                <>
                                    <SendReminderButton studentid={rowData.id} course_id={courseId} />
                                </>
                            ) : (
                                <>
                                    <button
                                        type="button"
                                        className={`btn btn-${
                                            rowData.status === "active" ? "danger" : "success"
                                        } py-2px m-2px`}
                                        title={rowData.status === "active" ? "Withdraw" : "Active"}
                                        onClick={() => withdrawStudent(rowData.student_course_id)}
                                    >
                                        {rowData.status === "active" ? "Withdraw" : "Activate"}
                                    </button>
                                  </>
                            )}
                            {rowData.join_type==='External' ? '' : <button
                                type="button"
                                className={`btn btn-blue py-2px m-2px`}
                                title="Change Join Type"
                                onClick={() => changeJoinType(rowData.student_course_id)}
                            >
                                Change Join Type
                            </button> }
                        </>
                    );
                },
            },
        ],
    });

    const withdrawStudent = (id) => {
        fetchData(
            `withdraw-student/${id}`,
            "POST",
            {pathname: location.pathname},
            true,
            false,
            (res) => {
                reloadDataTable(enroll_student_dt);
            },
            (err) => {}
        );
    };

    const changeJoinType = (id) => {
        fetchData(
            `change-course-join-type/${id}`,
            "POST",
            {pathname: location.pathname},
            true,
            false,
            (res) => {
                reloadDataTable(enroll_student_dt);
            },
            (err) => {}
        );
    };

    useEffect(() => {
        if (location.key === "default") {
            navigate("/NotFound");
        }
    }, [location.key, navigate]);

    useEffect(() => {
        if (props.loadComponent === "enroll_student") {
            reloadDataTable(enroll_student_dt);
        }
    });

    return (
        <>
            <InviteStudent course_id={courseId} enroll_student_dt={enroll_student_dt} />
            <div className="table-responsive p-1">
                <Datatables dt_name="enroll_student" />
            </div>
        </>
    );
}

export default EnrollStudents;
