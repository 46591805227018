import { Link, useNavigate } from "react-router-dom";
import { useParams } from 'react-router';
import { useState } from "react";
import { fetchData, validateForm } from "../../components/Helper";
import _ from 'lodash';
import { Helmet } from "react-helmet";

function EduClsCreate(){
    const navigate = useNavigate();
    let eduCls_type = useParams().eduCls_type
    const [name, setName] = useState('')

    const handleSubmitForm = (e) => {
        e.preventDefault();
        if(validateForm(e)) { 
            fetchData('educational-classification-create', 'POST', {name: name, type: eduCls_type}, true, false, (res) => {
                if(res.status){
                    navigate(`/educational-classification/${eduCls_type}/list`)
                }
            }, (err) => {})
        }
    }
    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>XTLMS RR | Add { _.upperFirst(eduCls_type) }</title>
        </Helmet>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h5 className="m-0 heading-lines pb-2 fs-20px">Add { _.upperFirst(eduCls_type) }</h5>
                        </div>
                        <div className="col-auto mb-2">
                        <Link to={`/educational-classification/${eduCls_type}/list`} className="btn btn-blue px-3 mb-1 me-1" title={`${ _.upperFirst(eduCls_type) } List`}> { _.upperFirst(eduCls_type) } List</Link>
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body p-sm-3 p-xl-3">
                                    <form action="#" method="post" className=" needs-validation" noValidate>
                                        <div className="mb-2 mb-sm-4 row mx-0">
                                            <label htmlFor="name" className="col-sm-3 col-xxl-2 col-form-label">{ _.upperFirst(eduCls_type) } Name<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="text" className="form-control" id="name" name="name" defaultValue="" onChange={(e) => setName(e.target.value)} required/>
                                            </div>
                                        </div>
                                        <div className="row mx-0">
                                            <div className="col-sm-9 offset-sm-3 offset-xxl-2">
                                                <button type="submit" className="btn btn-blue px-4 me-2 w-100px" onClick={handleSubmitForm} title="Add">Add</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EduClsCreate