import React from "react";
import Context from "../../components/Context";
import { useEffect, useState, useContext } from "react";
import { fetchData } from "../../components/Helper";
import Pagination from "../../components/Pagination";
import _, { isEmpty } from "lodash";
import MaterilDetailsBoby from "./components/MaterilDetailsBoby";
import MaterilDetailsViewModal from "./components/MaterilDetailsViewModal";

function PerformanceDetails(props) {
    const materialType = props.material;
    const [context] = useContext(Context);
    const roleId = context.auth.role_id;

    const [materialList, setMaterialList] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [performanceData, setPerformanceData] = useState();

    useEffect(() => {
        if (context && context.auth) {
            let api_url = `subject-type-list?id=${props.course_id}&type=${materialType}&user_id=${props.student_id}&status=active&page=${currentPage}`;
            fetchData(api_url, "GET", "", true, false, (res) => {
                setMaterialList(res.data);
                document.getElementById("group-title").innerHTML = res.data && res.data[1] ? res.data[1] : "";
            }, (err) => { });
        }
    }, [context, currentPage, props.student_id, props.course_id, materialType]);

    const handlePageClick = (data) => setCurrentPage(data.selected >= 0 ? data.selected + 1 : 0);

    const viewSubmission = (id) => {
        fetchData(`view-submission/${id}`, "GET", "", true, false, (res) => {
            if (res.status) {
                setPerformanceData(res.data);
            }
        }, (err) => { });
    };

    return (
        <>
            <MaterilDetailsBoby roleId={roleId} materialType={materialType} pagination={<Pagination paginate_data={materialList ? materialList[0] : ""} side="end" onPageChange={handlePageClick} />}>
                {materialList && materialList[0].data && !isEmpty(materialList[0].data) ? _.toArray(materialList[0].data).map((record, key) => {
                    return (
                        <tr key={key}>
                            <td>{record.date}</td>
                            <td>{record.name}</td>
                            <td>{record.module_type}</td>
                            { (roleId === 5 || roleId === 6) &&
                            <td>{record.marks ? record.marks:0} /{record.total_grade}</td>
                             }
                            <td>
                                {(roleId === 3 || roleId === 4) ? <>
                                    <button type="button" className="btn btn-blue btn-sm minw-120px" data-bs-toggle="modal" data-bs-target={`#viewModal`} onClick={() => viewSubmission(record.id)} title="View">
                                        View
                                    </button>
                                </> : <>
                                    <div className="ratio ratio-1x1 mmw-50px text-blue d-inline-block">
                                        <svg viewBox="0 0 36 36">
                                            <path strokeWidth="3" fill="none" stroke={record.percent === 0 ? "#f66969" : "#eee"} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
                                            <path strokeLinecap="round" fill="none" stroke={record.color} strokeWidth="3" strokeDasharray={`${record.percent}, 100`} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
                                            <text x="18" y="21.5" className="percentage fw-medium" fill={record.color} fontSize={record.percent === 0 ? "1rem" : "0.55rem"} fontFamily="" text-align="center" textAnchor="middle">
                                                {record.percent === 0 ? "!" : _.isInteger(record.percent) ? record.percent + "%" : record.percent.toFixed(2) + "%"}
                                            </text>
                                        </svg>
                                    </div>
                                </>}
                            </td>
                        </tr>
                    );
                }) : <tr><td colSpan={5} className="text-danger text-center" >No data available</td></tr>}

            </MaterilDetailsBoby>

            <MaterilDetailsViewModal materialType={materialType}>
                {performanceData && !_.isEmpty(performanceData) ? performanceData.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{item.id}</td>
                            <td>{_.capitalize(item.name)}</td>
                            { (roleId === 3 || roleId === 4) &&
                            <td>{item.marks ? item.marks:0} /{item.total_grade}</td>
                             }
                            <td>{item.email}</td>
                            <td>
                                <div className="ratio ratio-1x1 mmw-50px text-blue d-inline-block">
                                    <svg viewBox="0 0 36 36">
                                        <path strokeWidth="3" fill="none" stroke={item.percent === 0 ? "#f66969" : "#eee"} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
                                        <path stroke-linecap="round" fill="none" stroke={item.color} strokeWidth="3" strokeDasharray={`${item.percent}, 100`} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
                                        <text x="18" y="21.5" className="percentage fw-medium" fill={item.color} fontSize={item.percent === 0 ? "1rem" : "0.55rem"} fontFamily="" text-align="center" textAnchor="middle">
                                            {item.percent === 0 ? "!" : _.isInteger(item.percent) ? item.percent + "%" : item.percent.toFixed(2) + "%"}
                                        </text>
                                    </svg>
                                </div>
                            </td>
                        </tr>
                    )
                }) : <tr><td colSpan="5" className="text-danger text-center">No data available</td></tr>}
            </MaterilDetailsViewModal>
        </>
    );
}

export default PerformanceDetails;
