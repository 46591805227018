import React from 'react'

const InnerBanner = ({title}) => {
  return (
    <section className="bg-grey py-5 position-relative overflow-hidden breadcrumb-bar">
        <img src="images/new/inner-bg.png" className="d-none d-lg-block position-absolute h-100 top-0 start-50 translate-middle-x opacity-10 zindex-0 pointer-none" alt="XTLMS" />
        <img src="images/new/inner-bg-mob.png" className="d-lg-none position-absolute h-100 top-0 start-50 translate-middle-x opacity-10 zindex-0 pointer-none" alt="XTLMS" />
        <div className="container position-relative zindex-2">
            <div className="row">
                <div className="col-lg-12">
                    <h2 className="heading-lines mb-0">{title}</h2>
                </div>
            </div>
        </div>
    </section>
  )
}

export default InnerBanner