import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom"

function CourseCard(props){ 
    const navigate = useNavigate();

    const handleJoinCourse = (id) => {
        navigate(`/course/${id}/join`) 
    }

    return(
        <>
        { props.course_details ? props.course_details.length === 0 ? 
            <h4 className="alert alert-danger fs-14px">
                There is no matching course.
            </h4> : props.course_details.map((course_details, i) =>{
            return( props.hide && course_details.appearance === 'no' ? '' :
                // <div className="item" key={course_details.id}> 
                //     <div className="course-item mb-0 h-100 d-flex flex-column">
                //         <div className="ratio ratio-4x3 bg-light">
                //             <img src={course_details.course_image} className="w-100 h-100 img-cover" alt="XTLMS"/>
                //         </div>
                //         <span className="course-tag">{course_details.subject_name}</span>
                //         <div className="course-avatar d-none justify-content-between align-items-center">
                //            {course_details.teacher_image ?  <img src={course_details.teacher_image} className="mmw-50px"  alt="XTLMS" /> : ''}
                //             <span>{(course_details.class_price ===  'EGP 0.00' || course_details.class_price ===  'EGP 0') ? course_details.course_price : course_details.class_price }</span>
                //         </div>
                //         <div className="p-4 d-flex flex-column flex-fill">
                //             <h6 className="course-heading">{course_details.name}</h6>
                //             <p className="course-description">
                //                 {course_details.description}
                //             </p>
                //             <div className="pt-3 mt-auto d-flex justify-content-between align-items-center">
                //                { course_details.teacher_name ? 
                //                     <Link to={`/teacher-info/${course_details.teacher_id}`} className="fs-14px mb-0 text-muted text-hoverorange text-truncate" title={`By ${course_details.teacher_name }`}>
                //                         By {course_details.teacher_name }
                //                     </Link> : ''
                //                }
                //                { props.joinDisabled ? '' : <button onClick={() => handleJoinCourse(course_details.id)} className="enroll-link" title="Join Course">Join Course <i className="fs-4 lh-1 bi-arrow-right-short"></i></button> }
                //             </div>
                //         </div>
                //     </div> 
                // </div>
                
                <div className="item" key ={i}> 
                    <div className="course-item">
                        <div className="ratio ratio-4x3 bg-light">
                            {/* <img src={course_details.course_image} className="w-100 h-100 img-cover" alt="XT LMS" /> */}
                            <img src="/images/course.jpg" className="w-100 h-100 img-cover" alt="XT LMS" />
                        </div>
                        <span className="course-tag">{course_details.subject_name}</span>
                        <div className="course-avatar d-none justify-content-between align-items-center " >
                        {course_details.teacher_image ?  <img  src={course_details.teacher_image} className="mmw-40px d-none" alt="XT LMS" /> : '' }
                        </div>
                        <div className="course-content pt-2   p-3">
                            <h6 className="course-heading line-clamp-2">{course_details.name}</h6>
                            <p className="course-description line-clamp-1 mb-2">
                                {course_details.description}
                            </p>
                            { course_details.teacher_name ? 
                                <Link to={`/teacher-info/${course_details.teacher_id}`} className="fs-14px mb-2 text-black bg-orangeLight px-3 py-1 rounded text-decoration-none text-truncate" title={`By ${course_details.teacher_name }`}>
                                    By&nbsp; 
                                    {course_details.teacher_name }
                                </Link>
                                : ''
                            }
                            <div className="pt-2 course-bottom-cta d-flex justify-content-between align-items-center">
                            <button onClick={() => handleJoinCourse(course_details.id)} className="enroll-link" title="Join Course">
                                Join Course <i class="fs-4 lh-1 bi-arrow-right-short"></i> 
                            </button>
                            
                            <span className="fw-bold">{(course_details.class_price ===  'EGP 0.00' || course_details.class_price ===  'EGP 0') ? course_details.course_price : course_details.class_price }</span>

                                {/* <button className="btn btn-outline-blue btn-sm fw-medium text-nowrap" title="Join Course">Join Course</button> */}
                            </div>
                        </div>
                    </div> 
                </div>
            )
        }) 
        :
        Array.from(Array(8)).map((c, index) => {
               return <>
                 <div className="placeholder-wave" key={index}>
                    <div className="course-item p-2 mb-0">
                        <div className="row w-100">
                            <div className="col-md-4 pe-0">
                                <div className="ratio h-100 w-100 rounded-2 ratio-4x3 bg-light">
                                    <div className="w-100 h-100 img-cover placeholder"></div>
                                </div>
                            </div>
                            <span className="course-tag placeholder w-25"></span>
                            <div className="p-3 col-md-8">
                                <h6 className="course-heading placeholder w-50">&nbsp;</h6>
                                <p className="course-description placeholder"></p>
                                <p className="course-description placeholder w-50"></p>
                                <div className="pt-2 d-flex justify-content-between align-items-center">
                                    <span className="placeholder w-25"></span>
                                    <span className="placeholder w-25"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               </>
        }  )}
        </>
    )
}

export default CourseCard
