import {scrollbarSetToTop} from  "../components/Helper"
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import PartnerLogoSlider from "../components/PartnerLogoSlider";
import InnerBanner from "../components/InnerBanner";
import { Link } from "react-router-dom";
import FeedbackCard from "../components/cards/Feedback";
import ErrorBoundary from "../components/ErrorBoundary";
function AboutUs(){
    useEffect(() => {
        scrollbarSetToTop(); 
   }, [])
    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>XTLMS | About Us</title>
        </Helmet>
        
        <section className="about-section top-padding py-5 bg-cover" style={{backgroundImage: 'url(/images/new/slider-bg.png)'}}>
            <div className="container pb-sm-5">
                <div className="row align-items-center">
                    <div className="col-lg-6 mx-auto mb-4 mb-lg-0">                        
                        <div className="row g-3 g-md-4">
                            <div className="col-6 col-md-6">
                                <div className="row g-3 g-md-4">
                                    <div className="col-12 pt-lg-5">
                                        <img className="w-100 rounded-4" src="images/new/about-2.jpg" alt="" />
                                    </div>
                                    <div className="col-12">
                                        <div className="row justify-content-end">                                    
                                            <div className="col-10 col-lg-8 text-end">
                                                <img className="w-100 rounded-4" src="images/new/about-1.jpg" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-6 position-relative">                                    
                                <img className="w-100 rounded-4" src="images/new/about-3.jpg" alt="" />
                            </div>                                
                        </div>                                               
                    </div>
                    <div className="col-lg-5">
                        <div className="heading">
                            <h2 className="heading-lines heading-lg py-3">Enhance your skills with best <span>Online courses</span></h2>                            
                            <p>Though prosperous and full of curiosity, the pursuit of happiness was still met with delays. Despite the conviction in every endeavor, I felt restrained by external forces. Now, even in the company of strangers, I find myself contained by extremes.
                            </p>
                        </div>
                        <ul class="list-group border-0 mt-4">
                            <li class="list-group-item px-0 border-0 d-flex align-items-center">
                                <i class="lh-1 bi-patch-check-fill text-orange me-2"></i>The setup and installation process is quick and efficient.
                            </li>
                            <li class="list-group-item px-0 border-0 d-flex align-items-center">
                                <i class="lh-1 bi-patch-check-fill text-orange me-2"></i>User-friendly and professional software
                            </li>
                            <li class="list-group-item px-0 border-0 d-flex align-items-center">
                                <i class="lh-1 bi-patch-check-fill text-orange me-2"></i>Optimized for all devices with flawless, pixel-perfect design.
                            </li>
                            <li class="list-group-item px-0 border-0 d-flex align-items-center">
                                <i class="lh-1 bi-patch-check-fill text-orange me-2"></i>The setup and installation process is too quick.
                            </li>
                        </ul>
                        <div className="mt-4">
                            <Link className="btn btn-outline-blue px-4 py-2" to={'/'}>Start Learning For Free</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>    
        
        


        <section className="py-5 position-relative bg-light about-section">            
            <div className="container py-sm-5">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="heading-lines text-center">
                            Why <span> Choose </span>  Us ?
                        </h2>
                        <div className='home-futures text-center d-grid pt-3'>
                            <div className='hf-item'>
                                <div className='hf-icon'>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="56px" width="56px" xmlns="http://www.w3.org/2000/svg"><path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M32 192 256 64l224 128-224 128L32 192z"></path><path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M112 240v128l144 80 144-80V240m80 128V192M256 320v128"></path></svg>
                                </div>
                                <h4>Teacher</h4>
                                <p>At XT LMS, skilled educators provide students with proven strategies to boost performance and manage stress, ensuring steady progress and strong academic results throughout the year in all courses and classes.
                                </p>
                            </div>
                            <div className='hf-item'>
                                <div className='hf-icon'>
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" role="img" viewBox="0 0 24 24" height="56px" width="56px" xmlns="http://www.w3.org/2000/svg"><path d="M8.322 3.677L0 12l8.322 8.323L16.645 12zm7.371.01l-1.849 1.85 6.49 6.456-6.49 6.49 1.85 1.817L24 11.993Z"></path></svg>
                                </div>
                                <h4>Education</h4>
                                <p>XT LMS provides a seamless, efficient, and cost-effective learning experience, offering 24/7 access to online teaching tools. It delivers impactful education in a dynamic digital learning environment, tailored for higher education.
                                </p>
                            </div>
                            <div className='hf-item'>
                                <div className='hf-icon-2'>
                                    <i class="bi bi-flower1 bi-icons"></i>
                                </div>
                                <h4>Resources</h4>
                                <p>Our top educational resources, including study guides, lecture notes, practice exams, and supplementary instructions, are available to students for access at any time, depending on their availability.</p>
                            </div>
                            <div className='hf-item'>
                                <div className='hf-icon'>
                                    <i class="bi bi-joystick bi-icons"></i>
                                </div>
                                <h4>Trust</h4>
                                <p>XT LMS, a UAE-registered company, provides top-notch global educational services through its innovative online platform, ensuring compliance with the latest standards to align with contemporary education systems.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="py-5 position-relative z-1 about-section">
            <div className="container py-sm-4">
                <div className="row align-items-center">
                    <div className="col-md-8 col-lg-4 mx-auto">
                        <img className="w-100 rounded-4" src="/images/about/section-1.png" alt="" />
                    </div>
                    <div className="col-lg-7 mt-5 mt-lg-0">
                        <div className="heading">
                            <h2 className="heading-lines py-3">Everything <span>You Need,</span> All Under a Single  <br className="d-none d-md-block" /> Platform!</h2> 
                        </div>
                        <ul class="list-group border-0 mt-0">
                            <li class="list-group-item px-0 border-0 d-flex gap-3 align-items-md-center">                                
                                <div className='hf-icon-new flex-shrink-0'>
                                    <i class="bi bi-search bi-icons-24"></i>
                                </div>
                                <div>
                                    <p className="mb-0 fw-bold fs-20">Create a powerful brand identity</p>
                                    <p className="mb-0">featuring a completely white-label website, custom course landing pages, and mobile applications.</p>
                                </div>
                            </li>
                            <li class="list-group-item px-0 border-0 d-flex gap-3 align-items-md-center">                                
                                <div className='hf-icon-new flex-shrink-0'>
                                    <i class="bi bi-gear bi-icons-24"></i>
                                </div>
                                <div>
                                    <p className="mb-0 fw-bold fs-20">Automate enrolment
                                    </p>
                                    <p className="mb-0">Manage both online and offline payments seamlessly with invoices and receipts.
                                    </p>
                                </div>
                            </li>
                            <li class="list-group-item px-0 border-0 d-flex gap-3 align-items-md-center">                                
                                <div className='hf-icon-new flex-shrink-0'>
                                    <i class="bi bi-diagram-3 bi-icons-24"></i>
                                </div>
                                <div>
                                    <p className="mb-0 fw-bold fs-20">Manage multiple batches
                                    </p>
                                    <p className="mb-0">branches, live and self-paced courses, webinars, and additional offerings.</p>
                                </div>
                            </li>
                            <li class="list-group-item px-0 border-0 d-flex gap-3 align-items-md-center">                                
                                <div className='hf-icon-new flex-shrink-0'>
                                    <i class="bi bi-question bi-icons-24"></i>
                                </div>
                                <div>
                                    <p className="mb-0 fw-bold fs-20">Host question bank
                                    </p>
                                    <p className="mb-0">mathematical and programming assessments that are data-driven.</p>
                                </div>
                            </li>
                        </ul>
                        <div className="mt-4">
                            <Link className="btn btn-outline-blue px-4 py-2" to={'/'}>Get Start</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="py-5 bg-light position-relative z-1 about-section inner-bg-3" style={{backgroundImage: 'url(/images/about/section-bg2.png)'}}>
            <div className="container py-sm-4">
                <div className="row align-items-center flex-column-reverse flex-lg-row">                   
                    <div className="col-lg-6 col-xl-6 pe-lg-5 mt-5 mt-lg-0">
                        <div className="heading">

                            <h2 className="heading-lines py-3">Empowering Education through  <span>Open Source </span> InnovationPlatform!</h2>  
                        </div>
                        <p>At XT LMS, we are committed to ensuring that free and open access to educational technology is essential for fostering a more equitable world, enabling everyone to pursue quality education.
                        </p>
                        <p>As an open-source learning management system, XT LMS is freely available for download, modification, and sharing. It embodies the core values that bring together our community of developers, system administrators, educators, and learners.
                        </p>
                        <div className="mt-4">
                            <Link className="btn btn-outline-blue px-4 py-2" to={'/'}>Get Start</Link>
                        </div>
                    </div>

                    <div className="col-md-8 col-lg-4 mx-auto">
                        <img className="w-100 rounded-4" src="/images/about/section-2.png" alt="" />
                    </div>
                </div>
            </div>
        </section>


        <section className="py-5 position-relative z-1" hidden>
            <div className="container py-sm-4">
                <div className="row align-items-center">
                    <div className="col-md-8 col-lg-4 mb-4 mb-lg-0 mx-auto">
                        <img className="w-100 rounded-4" src="/images/about/section-3.png" alt="" />
                    </div>
                    <div className="col-lg-7">
                        <div className="heading">
                            <h2 className="heading-lines py-3">Everything <span>You Need, dddd</span> All Under a Single Platform!</h2>
                        </div>
                        <ul class="list-group border-0">
                            <li class="list-group-item px-0 border-0 d-flex gap-3 align-items-md-center">                                
                                <div className='hf-icon-new flex-shrink-0'>
                                    <i class="bi bi-headset bi-icons-24"></i>
                                </div>
                                <div>
                                    <p className="mb-0 fw-bold fs-20">24/7 Multi-Channel Customer Support</p>
                                    <p className="mb-0">Best-in industry</p>
                                </div>
                            </li>
                            <li class="list-group-item px-0 border-0 d-flex gap-3 align-items-md-center">                                
                                <div className='hf-icon-new flex-shrink-0'>
                                    <i class="bi bi-file-earmark bi-icons-24"></i>
                                </div>
                                <div>
                                    <p className="mb-0 fw-bold fs-20">Drip content feature</p>
                                    <p className="mb-0">to keep your learners engaged & hooked for more.</p>
                                </div>
                            </li>
                            <li class="list-group-item px-0 border-0 d-flex gap-3 align-items-md-center">                                
                                <div className='hf-icon-new flex-shrink-0'>
                                    <i class="bi bi-book bi-icons-24"></i>
                                </div>
                                <div>
                                    <p className="mb-0 fw-bold fs-20">In-built DRM & Watermarking</p>
                                    <p className="mb-0">features to safeguard your course content.</p>
                                </div>
                            </li>
                            <li class="list-group-item px-0 border-0 d-flex gap-3 align-items-md-center">                                
                                <div className='hf-icon-new flex-shrink-0'>
                                    <i class="bi bi-bar-chart bi-icons-24"></i>
                                </div>
                                <div>
                                    <p className="mb-0 fw-bold fs-20">0% revenue sharing</p>
                                    <p className="mb-0">enabling you to profit from your course & program sales.</p>
                                </div>
                            </li>
                        </ul>
                        <div className="mt-4">
                            <Link className="btn btn-outline-blue px-4 py-2" to={'/'}>Get Start</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="py-5 position-relative z-1">
            <div className="container py-sm-4">
                <div className="row align-items-center">
                    <div className="col-md-8 col-lg-4 mb-4 mb-lg-0 mx-auto"><img className="w-100 rounded-4" src="/images/about/section-3.png" alt="" /></div>
                    <div className="col-lg-7">
                        <div className="heading">
                        <h2 className="heading-lines py-3">Effortless <span>customer support</span> with User-Friendly</h2>
                        </div>
                        <ul className="list-group border-0">
                        <li className="list-group-item px-0 border-0 d-flex gap-3 align-items-md-center">
                            <div className="hf-icon-new flex-shrink-0"><i className="bi bi-headset bi-icons-24"></i></div>
                            <div>
                                <p className="mb-0 fw-bold fs-20">24/7 Multi-Channel Customer Support</p>
                                <p className="mb-0">Best-in industry</p>
                            </div>
                        </li>
                        <li className="list-group-item px-0 border-0 d-flex gap-3 align-items-md-center">
                            <div className="hf-icon-new flex-shrink-0"><i className="bi bi-file-earmark bi-icons-24"></i></div>
                            <div>
                                <p className="mb-0 fw-bold fs-20">Drip content feature</p>
                                <p className="mb-0">to keep your learners engaged &amp; hooked for more.</p>
                            </div>
                        </li>
                        <li className="list-group-item px-0 border-0 d-flex gap-3 align-items-md-center">
                            <div className="hf-icon-new flex-shrink-0"><i className="bi bi-book bi-icons-24"></i></div>
                            <div>
                                <p className="mb-0 fw-bold fs-20">In-built DRM &amp; Watermarking</p>
                                <p className="mb-0">features to safeguard your course content.</p>
                            </div>
                        </li>
                        <li className="list-group-item px-0 border-0 d-flex gap-3 align-items-md-center">
                            <div className="hf-icon-new flex-shrink-0"><i className="bi bi-bar-chart bi-icons-24"></i></div>
                            <div>
                                <p className="mb-0 fw-bold fs-20">0% revenue sharing</p>
                                <p className="mb-0">enabling you to profit from your course &amp; program sales.</p>
                            </div>
                        </li>
                        </ul>
                        <div className="mt-4"><a className="btn btn-outline-blue px-4 py-2" href="/">Get Start</a></div>
                    </div>
                </div>
            </div>
            </section>

        <section className="py-5 bg-light position-relative z-1">
            <span className="pattern-square" style={{background:'url(./images/pattern-square.svg)'}}></span>
            <div className="container py-sm-4">
                <div className="row">
                    <div className="col-md-12 pb-3">
                        <h2 className="heading-lines text-center">Testimonials</h2>
                        <p className='text-center testmonial-item'>Our Students And Parents  <span>Feedback</span></p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 mx-auto">
                      <ErrorBoundary>
                            <FeedbackCard /> 
                      </ErrorBoundary>
                    </div>
                </div>
            </div>
        </section>
        
        </>
    )
}

export default AboutUs