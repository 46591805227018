import { useContext, useEffect, useState } from 'react';
import ChartComponant from '../components/ChartComponant';
import { fetchData } from "../components/Helper"
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import DateRangeFilter from '../components/Filter';
import Context from '../components/Context';

function Dashboard(){
    const [context] = useContext(Context)
    const color = ['success', 'warning', 'primary', 'danger']
    const classStatus = ['Active Classes', 'Pending Classes', 'Draft Classes', 'Over Classes']
    const [dashboardData, setDashboardData] = useState()
    const [bgColor] = useState([
        'rgba(255, 99, 132, 0.7)',
        'rgba(255, 159, 64, 0.7)',
        'rgba(255, 205, 86, 0.7)',
        'rgba(75, 192, 192, 0.7)',
        'rgba(54, 162, 235, 0.7)',
        'rgba(153, 102, 255, 0.7)',
        'rgba(201, 203, 207, 0.7)'
    ])

    const [dateRange, setDateRange] = useState(['', ''])
    const [startDate, endDate] = dateRange

    const studentRecord = {
        labels: dashboardData ? Object.keys(dashboardData.studentRecord) : '',
        datasets: [{
            label: 'Student',
            data: dashboardData ? Object.values(dashboardData.studentRecord) : '',
            backgroundColor: _.shuffle(bgColor),
            hoverOffset: 4
        }]
    };

    const parentRecord = {
        labels: dashboardData ? Object.keys(dashboardData.parentRecord) : '',
        datasets: [{
            label: 'Parent',
            data: dashboardData ? Object.values(dashboardData.parentRecord) : '',
            backgroundColor: _.shuffle(bgColor),
            hoverOffset: 4
        }]
    };
    
    const teacherRecord = {
        labels: dashboardData ? Object.keys(dashboardData.teacherRecord) : '',
        datasets: [{
            label: 'Teacher',
            data: dashboardData ? Object.values(dashboardData.teacherRecord) : '',
            backgroundColor: _.shuffle(bgColor),
            hoverOffset: 4
        }]
    };

    const assistantRecord = {
        labels: dashboardData ? Object.keys(dashboardData.assistantRecord) : '',
        datasets: [{
            label: 'Teacher',
            data: dashboardData ? Object.values(dashboardData.assistantRecord) : '',
            backgroundColor: _.shuffle(bgColor),
            hoverOffset: 4
        }]
    };

    const adminRecord = {
        labels: dashboardData ? Object.keys(dashboardData.adminRecord) : '',
        datasets: [{
            label: 'Teacher',
            data: dashboardData ? Object.values(dashboardData.adminRecord) : '',
            backgroundColor: _.shuffle(bgColor),
            hoverOffset: 4
        }]
    };

    const courseRecord = {
        labels: dashboardData ? Object.keys(dashboardData.courseRecord) : '',
        datasets: [{
            label: 'Course',
            data: dashboardData ? Object.values(dashboardData.courseRecord) : '',
            backgroundColor:  _.shuffle(bgColor),
        }]
    }

    const paymentRecord = {
        labels: dashboardData ? Object.keys(dashboardData.paymentRecord.success) : '',
        datasets: [
            {
                label: dashboardData ? Object.values(dashboardData.paymentRecord.success_amount) : '',
                label2: 'Success',
                data: dashboardData ? Object.values(dashboardData.paymentRecord.success)  : '',
                backgroundColor: 'rgba(75, 192, 192, 0.7)',
            },
            {
                label: dashboardData ? Object.values(dashboardData.paymentRecord.pending_amount) : '',
                label2: 'Pending',
                data: dashboardData ? Object.values(dashboardData.paymentRecord.pending) : '',
                backgroundColor: 'rgba(255, 159, 64, 0.7)',
            },
            {
                label: dashboardData ? Object.values(dashboardData.paymentRecord.failed_amount): '',
                label2: 'Failed',
                data: dashboardData ? Object.values(dashboardData.paymentRecord.failed) : '',
                backgroundColor: 'rgba(255, 99, 132, 0.7)',
            }
        ]
    };

    const scales = {
                
        x: {
            grid: {
                color: 'transparent',
                borderColor: 'transparent'
            },
            ticks: {
                display: false,
            }
        },
        y: {
            beginAtZero: true,
            grid: {
                color: 'transparent',
                borderColor: 'transparent'
            },
            ticks: {
                display: false,
                crossAlign: 'far',
            }
        }
    }

    const plugins = {
        legend: {
            display: false,
            position: 'top',
        },
        label: {
            display: false
        },
    }

    const payment_plugins = {
        legend: {
            display: false,
            position: 'top',
        },
        label: {
            display: false
        },
        tooltip: {
            callbacks: {
                label: function(labelContext) {                    
                    let label = labelContext.dataset.label[labelContext.dataIndex]
                    let type = labelContext.dataset.label2
                    let data = labelContext.dataset.data[labelContext.dataIndex]
                    return type +': '+ label +' '+(context && context.site && context.site.currency ? context.site.currency : 'EGP')+' ('+ data +')';
                }
            }
        }
    }

    const getDateRange = (startDate, endDate) => {
        setDashboardData('')
        setDateRange([startDate, endDate])
    }

    useEffect(() => {
            fetchData(`statistics?startDate=${startDate}&endDate=${endDate}`, 'GET', '', true, false, (res) => {
                if(res.status){
                    setDashboardData(res.data)
                }
            }, (err) => {})
    }, [startDate, endDate]);
    
    const refreshData = () => {
        document.querySelector('input[name="d_daterange"]').value = 'All'
        fetchData(`statistics`, 'GET', '', true, false, (res) => {
            if(res.status){
                setDashboardData(res.data)
            }
        }, (err) => {})
    }

    return(
            <section className="admin-wrapper">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>XTLMS | Dashboard</title>
                </Helmet>
            <div className="container-fluid">
            <div className="row align-items-center justify-content-between">
                    <div className="col mb-2">
                        <h5 className="m-0 heading-lines pb-2 fs-20px">Hello {context.auth.name}</h5>
                        <p>{context.auth.role.name}</p>
                    </div>
                
                    <div className="col col-auto mb-2">
                        <div className="d-flex mb-3 manage-select-box">
                        <DateRangeFilter 
                            daterange="All"
                            className="form-control"
                            maxDate={new Date()}
                            opens="left"
                            name='d_daterange'
                            reload={refreshData}
                            disableUpdateInput={false}
                            getDateRange={getDateRange}
                        />
                        </div>
                    </div>
                </div>

            {
                dashboardData ? 
                    <>
                    <div className="row g-3 g-xl-4 row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5">
                        <div className="col">
                            <div className="card rounded-10 h-100 bg-primary-subtle-one border-color-grey">
                                <div className="card-body">           
                                    <div className="ratio ratio-1x2 mmw-180px text-start margin-50">
                                        <ChartComponant key="1" id="student_chart" plugins={plugins} label={studentRecord.labels} scale ={scales} data={studentRecord} type="pie" />
                                    </div>                                    
                                    <div className='graph-card-top-head card-bg-01'>
                                        <h6 className="fs-20">Students : </h6> <span>{dashboardData ? dashboardData.totalStudent : ''}</span>                                  
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card rounded-10 h-100 bg-primary-subtle-two border-color-grey">
                                <div className="card-body">    
                                    <div className="ratio ratio-1x2 mmw-180px text-start margin-50">
                                        <ChartComponant key="2" id="parent_chart" plugins={plugins} label={parentRecord.labels} scale ={scales} data={parentRecord} type="pie" />
                                    </div>     
                                    <div className='graph-card-top-head card-bg-02'>
                                        <h6 className="fs-20">Parents :</h6>  
                                        <span>{dashboardData ? dashboardData.totalParent : ''}</span>                               
                                    </div>                   
                                </div>                                
                            </div>
                        </div>
                        <div className="col">
                            <div className="card rounded-10 h-100 bg-primary-subtle-three border-color-grey">
                                <div className="card-body">          
                                    <div className="ratio ratio-1x2 mmw-180px text-start margin-50">
                                        <ChartComponant key="3" id="teacher_chart" plugins={plugins} label={teacherRecord.labels} scale ={scales} data={teacherRecord} type="pie" />
                                    </div>   
                                    <div className='graph-card-top-head card-bg-03'>
                                        <h6 className="fs-20">Teachers : </h6>        
                                        <span>{dashboardData ? dashboardData.totalTeacher : ''}</span>                        
                                    </div>                          
                                </div>   
                            </div>
                        </div>
                        <div className="col">
                            <div className="card rounded-10 h-100 bg-primary-subtle-four border-color-grey">
                                <div className="card-body">             
                                    <div className="ratio ratio-1x2 mmw-180px text-start margin-50">
                                        <ChartComponant key="4" id="assistant_chart" plugins={plugins} label={assistantRecord.labels} scale ={scales} data={assistantRecord} type="pie" />
                                    </div>     
                                    <div className='graph-card-top-head card-bg-01'>
                                        <h6 className="fs-20">Assistant :</h6>       
                                        <span>{dashboardData ? dashboardData.totalAssistant : ''}</span>                         
                                    </div>                      
                                </div>  
                            </div>
                        </div>
                        <div className="col">
                            <div className="card rounded-10 h-100 bg-success-subtle border-color-grey">
                                <div className="card-body">             
                                    <div className="ratio ratio-1x2 mmw-180px text-start margin-50">
                                        <ChartComponant key="5" id="admin_chart" plugins={plugins} label={adminRecord.labels} scale ={scales} data={adminRecord} type="pie" />
                                    </div>      
                                    <div className='graph-card-top-head card-bg-01'>
                                        <h6 className="fs-20">Admin : </h6>         
                                        <span>{dashboardData ? dashboardData.totalAdmin : ''}</span>                       
                                    </div>                  
                                </div>  
                            </div>
                        </div>         
                    </div>
                    <div className="row g-3 g-xl-4 mt-1">
                        <div className="col-md-6 col-xl-3">
                            <div className="card rounded-10 bg-white-light border-color-grey">
                                <div className="card-body">             
                                    <div className="ratio ratio-1x1 text-start">
                                        <ChartComponant key="7" id="chart_doughnut" plugins="" data={courseRecord} scale ={scales} type="doughnut" />
                                    </div>      
                                    <div className='graph-card-top-head card-bg-01'>
                                        <h6 className="fs-20">Courses : </h6>         
                                        <span>{dashboardData ? dashboardData.totalCourse : ''}</span>                       
                                    </div>                  
                                </div>  
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-9">
                            <div className="card rounded-10 bg-white-light border-color-grey">
                                <div className="card-body">                                   
                                    <div className="ratio ratio-1in3">
                                        <ChartComponant key="5" id="chart_bar2" plugins={payment_plugins} data={paymentRecord} scale ={{y:{grid:{color: 'transparent',borderColor:'#f2f2f2'}}}} type="bar" />
                                    </div>
                                    <div className='d-flex flex-wrap justify-content-between'>                                       
                                        <div className='graph-card-head card-bg-01'>
                                            <h6 className="fs-20">Total Transactions</h6>         
                                            <span><b>{dashboardData ? dashboardData.all_transaction.count : ''}</b>
                                            <small> ({dashboardData && dashboardData.all_transaction.amount ? dashboardData.all_transaction.amount : 0 } {context && context.site && context.site.currency ? context.site.currency : 'EGP'})</small></span>                       
                                        </div> 

                                        <div className='graph-card-head card-bg-01'>
                                            <h6 className="fs-20">Success Transactions</h6>         
                                            <span>
                                                <b>{dashboardData ? dashboardData.success_transaction.count : ''}</b>
                                                <small> ({dashboardData && dashboardData.success_transaction.amount ? dashboardData.success_transaction.amount : 0 } {context && context.site && context.site.currency ? context.site.currency : 'EGP'})</small>
                                            </span>
                                        </div> 
                                        <div className='graph-card-head card-bg-01'>
                                            <h6 className="fs-20">Pending Transactions</h6>         
                                            <span>
                                                <b>{dashboardData ? dashboardData.pending_transaction.count : ''}</b>
                                                <small> ({dashboardData && dashboardData.pending_transaction.amount ? dashboardData.pending_transaction.amount : 0 } {context && context.site && context.site.currency ? context.site.currency : 'EGP'})</small>
                                            </span>
                                        </div> 
                                        <div className='graph-card-head card-bg-01'>
                                            <h6 className="fs-20">Failed Transactions</h6>         
                                            <span>
                                                <b>{dashboardData ? dashboardData.failed_transaction.count : ''}</b>
                                                <small> ({dashboardData && dashboardData.failed_transaction.amount ? dashboardData.failed_transaction.amount : 0 } {context && context.site && context.site.currency ? context.site.currency : 'EGP'})</small>
                                            </span>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row g-3 g-xl-4 row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5">
                                <div className="col">
                                    <div className="card rounded-10 h-100 bg-white-light p-3 border-color-grey">             
                                        <div className="text-start position-relative">
                                            <i className='bi-alarm fs-54 opacity-10'></i>
                                        </div>      
                                        <div className='graph-card-top-head card-bg-01'>
                                            <h6 className="fs-20">Total Timing : </h6>         
                                            <span>{dashboardData ? dashboardData.totalTiming : ''}</span>                       
                                        </div>                  
                                    </div>  
                                </div>
                                
                                <div className="col">
                                    <div className="card rounded-10 h-100 bg-white-light p-3 border-color-grey">             
                                        <div className="text-start position-relative">
                                            <i className='bi-basket3-fill fs-54 opacity-10'></i>
                                        </div>      
                                        <div className='graph-card-top-head card-bg-01'>
                                            <h6 className="fs-20">Total Classes : </h6>         
                                            <span>{dashboardData ? dashboardData.totalClass : ''}</span>                       
                                        </div>                  
                                    </div>  
                                </div>

                                <div className="col">
                                    <div className="card rounded-10 h-100 bg-white-light p-3 border-color-grey">             
                                        <div className="text-start position-relative">
                                            <i className='bi-radioactive fs-54 opacity-10'></i>
                                        </div>      
                                        <div className='graph-card-top-head card-bg-01'>
                                            <h6 className="fs-20">Active Recording : </h6>         
                                            <span>{dashboardData ? dashboardData.recordingRecord.Active : ''}</span>                       
                                        </div>                  
                                    </div>  
                                </div>

                                <div className="col">
                                    <div className="card rounded-10 h-100 bg-white-light p-3 border-color-grey">             
                                        <div className="text-start position-relative">
                                            <i className='bi-vinyl fs-54 opacity-10'></i>
                                        </div>      
                                        <div className='graph-card-top-head card-bg-01'>
                                            <h6 className="fs-20">Draft Recording : </h6>         
                                            <span>{dashboardData ? dashboardData.recordingRecord.Draft : ''}</span>                       
                                        </div>                  
                                    </div>  
                                </div>
                                <div className="col">
                                    <div className="card rounded-10 text-decoration-none bg-white-light border-color-grey">
                                        <div className="p-3">
                                            <i className="bi-person-circle fs-54 opacity-10" role="img" aria-label="People"></i>
                                            <div className='graph-card-top-head card-bg-01'>
                                                <h6 className="fs-20">All Accounts : </h6>         
                                                <span>{dashboardData ? dashboardData.totalAccount : ''}</span>                       
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                            <div className="progress my-3 opacity-75" style={{'height': '10px'}}>
                                {
                                    dashboardData && dashboardData.classRecord ? Object.values(dashboardData.classRecord).map((value, key) => {
                                        return (
                                            <div key={key} className={`progress-bar bg-${color[key]}`} role="progressbar" style={{'width': value === 0 ? 0 : ((value *100) / dashboardData.totalClass)+'%'}} aria-valuenow={value} aria-valuemin="0" aria-valuemax="100"></div>
                                        )
                                    }) : ''

                                }
                            </div>
                            <div className="list-inline fs-13 fw-bold">
                                {
                                    dashboardData && dashboardData.classRecord ? Object.values(dashboardData.classRecord).map((value, key) => {
                                        return (
                                            <div key={key} className={`list-inline-item d-inline-flex align-items-center text-${color[key]}`}>
                                                <span className={`d-inline-block bg-${color[key]} rounded-circle ratio ratio-1x1 mmw-7px me-1`}></span>
                                                {classStatus[key]} ( {value} )
                                            </div>
                                        )
                                    }) : ''

                                }
                            </div>                              
                        </div>                    
                    </>
                : ''
            }
            
        </div>
    </section>
  )
}

export default Dashboard