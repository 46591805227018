import {useEffect, useState} from "react";
import {Link, useSearchParams,useNavigate} from "react-router-dom";
import $ from "jquery";
import {fetchData, intlTel_phone, validateForm, validatePhone, scrollbarSetToTop} from "../components/Helper";
import {Helmet} from "react-helmet";
import InnerBanner from "../components/InnerBanner";

function SignUp() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [studentData, setStudentData] = useState({country: "ae", type: "student"});
    const [parentData, setParentData] = useState({country: "ae", type: "parent"});
    const [reload, setReload] = useState(0);
    const [phoneInput, setPhoneInput] = useState();

    useEffect(() => {
        if (searchParams.size>0) {
            setStudentData((prevState) => ({
                ...prevState,
                'invite_student_email': searchParams.get("email"),
                'student_email': searchParams.get("email"),
                student_name: searchParams.get("name")
            }));
            setParentData({...parentData, student_email: searchParams.get("email"),invite_student_email: searchParams.get("email"),student_name:searchParams.get("name")});
        }
        scrollbarSetToTop();
        setPhoneInput({
            student: intlTel_phone("#student_phone", "#country", "us"),
            parent: intlTel_phone("#parent_phone", "#parent_country", "us"),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);
    const handleInputChange = (e) => {
        let id = document.getElementById(e.target.name);
        if (id) {
            id.classList.remove("is-invalid");
            $(e.target.name).siblings(".invalid-feedback").css("display", "none");
        }
        $("li.third-item").next().css("background-color", "red");
        setStudentData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
        setParentData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };
    const handleSaveStudent = (e) => {
        e.preventDefault();
        if (validateForm(e) && validatePhone(phoneInput.student, "#student_phone")) {
            let student_data = {...studentData, student_phone: phoneInput.student.getNumber()};
            fetchData(
                "student-parent-register",
                "POST",
                student_data,
                false,
                false,
                (res) => {
                    if (res.status) {
                        //document.getElementById('parentForm').classList.add('needs-validation');
                        $(".nav-pills .active")
                        .parent()
                        .parent()
                        .find(".nav-item .nav-link:not(.active)")
                        .trigger("click");
                    } else {
                        $(".nav-pills .active")
                        .parent()
                        .parent()
                        .find(".nav-item .nav-link:not(.active)")
                        .dispatch("click");
                    }
                },
                (err) => {}
            );
        }
    };

    const handleSaveParent = (e) => {
        e.preventDefault();
        var reset_std = document.getElementById("studentForm");
        var reset_par = document.getElementById("parentForm");
        if (validateForm(e) && validatePhone(phoneInput.parent, "#parent_phone")) {
            let parent_data = {
                ...parentData,
                student_phone: phoneInput.student.getNumber(),
                parent_phone: phoneInput.parent.getNumber(),
            };
            if (!parent_data.parent_country) {
                parent_data = {...parent_data, parent_country: "ae"};
            }
            fetchData(
                "student-parent-register",
                "POST",
                parent_data,
                false,
                false,
                (res) => {
                    if (res.status) {
                        setParentData({parent_country: "ae", type: "parent"});
                        setStudentData({country: "ae", type: "student"});
                        reset_std.reset();
                        reset_par.reset();
                        reset_par.classList.remove("needs-validation", "was-validated");
                        reset_std.classList.remove("was-validated");
                        phoneInput.student.destroy();
                        phoneInput.parent.destroy();
                        $("#pillsparent").removeClass("show active");
                        $("#pillsstudent").addClass("show active");
                        $("#parentTab").removeClass("active");
                        $("#studentTab").addClass("active");
                        setReload(reload + 1);
                        navigate('/login', { replace: true });
                    }
                },
                (err) => {}
            );
        }
    };

    const showPassword = (e, getid) => {
        let ps = document.getElementById(getid);
        if (ps.getAttribute("type") === "password") {
            ps.setAttribute("type", "text");
        } else {
            ps.setAttribute("type", "password");
        }
    };

    const gotoParentForm = (e, tabid) => {
        e.preventDefault();
        if (validateForm(e, "studentForm") && validatePhone(phoneInput.student, "#student_phone")) {
            let student_data = {...studentData, student_phone: phoneInput.student.getNumber()};
            fetchData(
                "student-parent-register",
                "POST",
                student_data,
                false,
                false,
                (res) => {
                    if (res.status) {
                        $("#pillstab .nav-link").removeClass("active");
                        $(e.target).addClass("active");

                        $("#pillstabContent .tab-pane").removeClass("show active");
                        $(tabid).addClass("show active");
                    } else {
                    }
                },
                (err) => {}
            );
        }
    };

    return (
        <>
        
            <Helmet>
                <meta charSet="utf-8" />
                <title>XTLMS | Sign Up</title>
            </Helmet>
            <InnerBanner title="Registration" />
            <section className="login-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 col-lg-8 offset-md-1 offset-lg-2">
                            <div className="p-4 p-sm-5 rounded-4 w-100 needs-validation" style={{boxShadow:'0px 0px 60px 0px rgba(0, 0, 0, 0.05)'}}>
                                <div className='mb-4 text-center'>
                                    <h3 className="heading-lines mb-1 mt-0">Create Account</h3>
                                    <p className='mb-0 opacity-75'>Fill in the details below to create your  <br /> account and begin your journey with us.</p>
                                </div>
                                <ul className="nav nav-pills justify-content-center nav-common-pills mb-4" id="pillstab" role="tablist">
                                    <li className="nav-item mb-3" role="presentation">
                                        <button
                                            className="nav-link active"
                                            type="button"
                                            id="studentTab"
                                            onClick={(e) => gotoParentForm(e, "#pillsstudent")}
                                            title="Student's Details"
                                        >
                                            Student's Details
                                        </button>
                                    </li>
                                    <li className="nav-item mb" role="presentation">
                                        <button
                                            className="nav-link"
                                            type="button"
                                            id="parentTab"
                                            title="Parent's Details"
                                            onClick={(e) => gotoParentForm(e, "#pillsparent")}
                                        >
                                            Parent's Details
                                        </button>
                                    </li>
                                </ul>
                                <div className="tab-content" id="pillstabContent">
                                    <div className="tab-pane fade show active" id="pillsstudent">
                                        <form className="needs-validation" id="studentForm" noValidate>
                                            <div className="row g-4 justify-content-center" id="step1">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="form-floating">
                                                        <input
                                                            type="text"
                                                            className="form-control rounded-5 px-4"
                                                            defaultValue={
                                                                searchParams.get("name")
                                                                    ? searchParams.get("name")
                                                                    : ""
                                                            }
                                                            // pattern="[a-zA-Z]+ [a-zA-Z]+$"
                                                            pattern="([a-zA-Z]+ [a-zA-Z]+( [a-zA-Z]+)*)+"
                                                            name="student_name"
                                                            id="student_name"
                                                            onChange={handleInputChange}
                                                            placeholder="Student Name*"
                                                            required
                                                        />
                                                        <small className="text-danger validation-font">
                                                            <b>Note: </b> First Name & Last Name is required
                                                        </small>
                                                        <label htmlFor="studentname" className="px-3">Student Name*</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="form-floating">
                                                        <input
                                                            type="email"
                                                            className="form-control rounded-5 px-4"
                                                            readOnly={searchParams.get("email") ? true : false}
                                                            defaultValue={
                                                                searchParams.get("email")
                                                                    ? searchParams.get("email")
                                                                    : ""
                                                            }
                                                            name="student_email"
                                                            id="student_email"
                                                            placeholder="Student Email"
                                                            onChange={handleInputChange}
                                                            required={searchParams.get("email") ? false : true}
                                                        /> 
                                                        <label htmlFor="studentemail" className="px-3">Student Email*</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="form-floating">
                                                        <select
                                                            className="form-select rounded-5"
                                                            name="country"
                                                            id="country"
                                                            onChange={handleInputChange}
                                                            required
                                                        >
                                                            {" "}
                                                        </select>
                                                        <label htmlFor="countrytext" className="px-3">Country</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="form-floating tel_input">
                                                        <input
                                                            type="phone"
                                                            className="form-control rounded-5"
                                                            name="student_phone"
                                                            id="student_phone"
                                                            autoComplete="off"
                                                            data-intl-tel-input-id="0"
                                                            style={{height: "58px"}}
                                                            title="WhatsApp Number"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="form-floating mb-31 position-relative">
                                                        <input
                                                            type="password"
                                                            className="form-control rounded-5 px-4"
                                                            name="student_password"
                                                            id="student_password"
                                                            placeholder="Password"
                                                            onChange={handleInputChange}
                                                            required
                                                        />
                                                        <label htmlFor="studentpassword" className="px-3">Password*</label>
                                                        <button
                                                            type="button"
                                                            onClick={(e) => showPassword(e, "student_password")}
                                                            className="btn border-none position-absolute px-1 me-3 top-0 end-0 translate-middle-y mt-4 pt-3 shadow-none"
                                                        >
                                                            <i class="bi bi-eye lh-1 fs-5"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="form-floating mb-31 position-relative">
                                                        <input
                                                            type="password"
                                                            className="form-control rounded-5 px-4"
                                                            name="student_confirm_password"
                                                            id="student_confirm_password"
                                                            placeholder="Confirm Password"
                                                            onChange={handleInputChange}
                                                            required
                                                        />
                                                        <label htmlFor="studentcpassword" className="px-3">Confirm Password*</label>
                                                        <button
                                                            type="button"
                                                            onClick={(e) => showPassword(e, "student_confirm_password")}
                                                            className="btn border-none position-absolute px-1 me-3 top-0 end-0 translate-middle-y mt-4 pt-3 shadow-none"
                                                        >
                                                            <i class="bi bi-eye lh-1 fs-5"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <button
                                                        type="button"
                                                        className="btn btn-blue rounded-5 btn-lg w-100 btn-next"
                                                        onClick={handleSaveStudent}
                                                        title="NEXT STEP"
                                                    >
                                                        NEXT STEP <i class="bi fs-3 lh-1 bi-arrow-right-short"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="tab-pane fade" id="pillsparent">
                                        <form id="parentForm" noValidate>
                                            <div className="row g-4 justify-content-center">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="form-floating">
                                                        <input
                                                            type="text"
                                                            className="form-control rounded-5 px-4"
                                                            name="parent_name"
                                                            id="parent_name"
                                                            onChange={handleInputChange}
                                                            placeholder="Parent Name*"
                                                            required
                                                        />
                                                        <label htmlFor="parentname" className="px-3" >Parent Name*</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="form-floating">
                                                        <input
                                                            type="email"
                                                            className="form-control rounded-5 px-4"
                                                            name="parent_email"
                                                            id="parent_email"
                                                            onChange={handleInputChange}
                                                            placeholder="Parent Email"
                                                            required
                                                        />
                                                        <label htmlFor="parentemail" className="px-3">Parent Email*</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="form-floating">
                                                        <select
                                                            className="form-select"
                                                            name="parent_country"
                                                            id="parent_country"
                                                            onChange={handleInputChange}
                                                            required
                                                        ></select>
                                                        <label htmlFor="countrytext" className="px-3">Country</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="form-floating tel_input">
                                                        <input
                                                            type="tel"
                                                            className="form-control rounded-5 px-4"
                                                            id="parent_phone"
                                                            autoComplete="off"
                                                            name="parent_phone"
                                                            data-intl-tel-input-id="0"
                                                            style={{height: "58px"}}
                                                            data-bs-toggle="tooltip"
                                                            title="WhatsApp Number"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="form-floating mb-31 position-relative">
                                                        <input
                                                            type="password"
                                                            className="form-control rounded-5 px-4"
                                                            name="parent_password"
                                                            id="parent_password"
                                                            placeholder="Parent Password"
                                                            onChange={handleInputChange}
                                                            required
                                                        />
                                                        <label htmlFor="parentpassword" className="px-3">Password*</label>
                                                        <button
                                                            type="button"
                                                            onClick={(e) => showPassword(e, "parent_password")}
                                                            className="btn border-none position-absolute px-1 me-4 top-0 end-0 translate-middle-y mt-4 pt-3 shadow-none"
                                                        >
                                                            <i class="bi bi-eye lh-1 fs-5"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="form-floating mb-31 position-relative">
                                                        <input
                                                            type="password"
                                                            className="form-control rounded-5 px-4"
                                                            name="parent_confirm_password"
                                                            id="parent_confirm_password"
                                                            placeholder="Parent Confirm Password"
                                                            onChange={handleInputChange}
                                                            required
                                                        />
                                                        <label htmlFor="parentcpassword" className="px-3">Confirm Password*</label>
                                                        <button
                                                            type="button"
                                                            onClick={(e) => showPassword(e, "parent_confirm_password")}
                                                            className="btn border-none position-absolute px-1 me-4 top-0 end-0 translate-middle-y mt-4 pt-3 shadow-none"
                                                        >
                                                            <i class="bi bi-eye lh-1 fs-5"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <button
                                                        type="button"
                                                        className="btn rounded-5 btn-blue btn-lg w-100"
                                                        onClick={handleSaveParent}
                                                        title="SIGN UP"
                                                    >
                                                        SIGN UP
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mt-4">
                                    <Link
                                        to="/login"
                                        className="anchor-blueorange fw-medium"
                                        title="Already have an account?"
                                    >
                                        Already have an account?
                                    </Link>
                                </div>
                        
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </>
    );
}

export default SignUp;
