import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { useLocation } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { fetchData, validateForm, initialFormState } from "../../components/Helper"
import { reloadUrlDataTable, reloadDataTable } from '../../components/Datatables';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from 'jquery';
import DateRangeFilter from '../Filter';
import { times } from 'lodash';
import DataTables from "../../components/Datatables";

function ClassLinks() {
    const location = useLocation();
    const courseId = useParams().course_id
    const [listName, setListName] = useState('Draft')
    const [opPerform, setOpPerform] = useState('add')
    const [disableField, setDisableField] = useState(false)
    const [class_date, setClass_date] = useState(new Date());
    const [meetingPlatform, setMeetingPlatform] = useState('')
    const [meetingType, setMeetingType] = useState('')
    const [tinyloader, setTinyloader] = useState(false);

    const [classData, setClassData] = useState({
        reference_class: '',
        meeting_id: '',
        meeting_password: '',
        zoom_link: '',
    })

    const [timingData, setTimingData] = useState()
    const [dayCode, setDayCode] = useState();

    const initialState = (type) => {
        initialFormState("classForm", classData)
        setOpPerform('add')
        setDisableField(false)
        setClass_date(new Date())
    }

    const [dt] = useState({
        dt_url: `course-details/${courseId}`,
        dt_name: 'course_details',
        dt_column: [
            { data: "id", name: 'id', title: "#ID" },
            { data: "class_id", name: `class_id`, title: "Class ID" },
            { data: "reference_class", name: 'reference_class', title: "Timing" },
            { data: "class_date", name: 'class_date', title: "Date" },
            { data: "start_class_time", name: 'start_class_time', title: "Start Time", class: "text-nowrap" },
            { data: "end_class_time", name: 'end_class_time', title: "End Time", class: "text-nowrap" },
            { data: "status", name: 'status', title: "Status" },
            { data: "meeting_type", name: 'type', title: "Meeting Type", class: "text-nowrap" },
            { data: "type", name: 'type', title: "Platform", class: "text-nowrap" },
            { data: "created_at", name: 'created_at', title: "Class Created Date" },
            { data: "id", title: 'Action', sortable: false, searchable: false, class: "text-center text-nowrap", width: 160 }
        ],
        dt_column_defs: [
            {
                targets: 6,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(<>
                        {rowData.status === "over" ? <span className="text-primary fw-medium">Over</span> : ''}
                        {rowData.status === "active" ? <span className="badge bg-success">Active</span> : ''}
                        {rowData.status === "inactive" ? <span className="badge bg-danger">Inactive</span> : ''}
                        {rowData.status === "draft" ? <span className="badge bg-warning">Draft</span> : ''}
                    </>)
                },
            },
            {
                targets: 10,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(<>

                        {rowData.status === 'inactive' || rowData.status === 'over' ?
                            <button type="button" className="badge bg-success" disabled={rowData.status === 'over' ? true : false} title="Activate" onClick={() => handleChangeStatus('active', rowData.id)}>
                                Activate
                            </button>
                            : ''}

                        {rowData.status === 'active' ?
                            <button type="button" className="badge bg-danger" title="Deactivate" onClick={() => handleChangeStatus('inactive', rowData.id)}>
                                Deactivate
                            </button>
                            : ''}

                        {rowData.status !== 'draft' ?
                            <button type="button" className="table-icons-text" title="View" data-bs-toggle="modal" data-bs-target="#addclassModal" onClick={() => openEditClassModal(rowData.id, true)}>
                                View
                            </button>
                            : ''}

                        <button type="button" disabled={rowData.status === 'over' ? true : false} className="table-icons-text" title="Edit" data-bs-toggle="modal" data-bs-target="#addclassModal" onClick={() => openEditClassModal(rowData.id, false)}>
                            <i class="bi bi-pencil"></i>
                        </button>

                        {rowData.status === 'draft' ?
                            <button type="button" className="badge bg-danger" title="Delete" onClick={() => handleChangeStatus('delete', rowData.id)}>
                                <i class="bi bi-trash"></i>
                            </button>
                            : ''}
                    </>)
                },
            },

        ],

        dt_paging: true,
        dt_createdRow: '',
        dt_order: [[9, 'desc']],
        dt_filter: () => {
            createRoot(document.querySelector('#wt_datatable_course_details_wrapper .dt-custom-filter')).render(
                <>
                    <div className="d-inline-block align-top me-2">
                        <DateRangeFilter daterange="All" name="c_daterange" maxDate={new Date()} className="form-control form-control-sm" disableUpdateInput={false} getDateRange={getDateRange} key={times()} />
                    </div>
                </>
            )
        }
    })
    const getDateRange = (startDate, endDate) => {
        reloadUrlDataTable(dt, `course-details/${courseId}?startDate=${startDate}&endDate=${endDate}`)
    }

    const getClassData = (status) => {
        if (listName === 'Draft') {
            setListName('List')
            let status_q = `status_q=${status}`
            reloadUrlDataTable(dt, `course-details/${courseId}?${status_q}`)

        } else {
            setListName('Draft')
            reloadUrlDataTable(dt, `course-details/${courseId}`)

        }
    }

    const handleChangeStatus = (status, id, type = false) => {

        fetchData(`course-class/change-status`, 'POST', { 'id': id, 'status': status, 'course_id': courseId, 'pathname': location.pathname }, true, false, (res) => {
            reloadDataTable(dt)
        }, (err) => { })

    }
    const handleInputChange = (e) => {
        document.getElementById(e.target.name).classList.remove('is-invalid');
        setClassData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
        if (e.target.name === 'reference_class') {
            generateClassId(new Date(), e.target.value)
        }
    }
    const handelSubmitForm = (e) => {
        if (validateForm(e, 'classForm')) {
            if (opPerform !== 'add') {
                updateData(' ', 'class')
            } else {
                saveData('inactive', 'class')
            }
        }
    }
    const handelDraftSubmitForm = (e) => {
        if (validateForm(e, `classForm`)) {
            if (opPerform !== 'add') {
                updateData('draft', 'class')
            } else {
                saveData('draft', 'class')
            }
        }
    }
    const handelPublishSubmitForm = (e) => {
        if (validateForm(e, 'classForm')) {
            updateData('inactive', 'class')
        }
    }
    const saveData = (status, modal_type) => {
        let slug = $("#reference_class :selected").attr('class')
        let class_date = document.getElementById("class_date").value;
        let class_data = {
            ...classData,
            'class_date': class_date,
            'course_id': courseId,
            'slug': slug,
            'pathname': location.pathname,
            'status': status
        }
        fetchData(`class-create/${meetingType}`, 'POST', class_data, true, false, (res) => {
            if (res.status) {
                reloadDataTable(dt)
                initialState('class')
                document.getElementById('classModal').click()
            }
            setMeetingPlatform('')
            setMeetingType('')
        }, (err) => { })

    }

    const updateData = (status, modal_type) => {
        let slug = $("#reference_class :selected").attr('class')
        let class_date = document.getElementById("class_date").value;
        let class_data = {
            ...classData,
            'class_date': class_date,
            'slug': slug,
            'pathname': location.pathname,
            'status': status
        }
        fetchData(`class-update/${classData.id}`, 'POST', class_data, true, false, (res) => {
            if (res.status) {
                document.getElementById('classModal').click()
                reloadDataTable(dt)
            }
        }, (err) => { })


    }

    const openEditClassModal = (id, set, type = '') => {
        initialState('class')
        openFormModal('class')
        setDisableField(set)
        fetchData(`class-update/${id}`, 'GET', '', true, false, (res) => {
            if (res.status) {
                setClassData({
                    id: res.data.id,
                    reference_class: res.data.timing_id,
                    class_date: res.data.class_date,
                    meeting_id: res.data.meeting_id,
                    meeting_password: res.data.meeting_password,
                    start_time: res.data.start_class_time,
                    end_time: res.data.end_class_time,
                    class_id: res.data.class_id,
                    zoom_link: res.data.zoom_link,
                    meeting_platform: res.data.type,
                    meeting_type: res.data.meeting_type,

                })
                setOpPerform(res.data.status)
                setClass_date(new Date(res.data.class_date))
            }
            setTimeout(() => {
                if(res && res.data && res.data.meeting_type && res.data.meeting_type === 'manual') {
                    document.querySelector('#meeting_id').removeAttribute('disabled')
                    document.querySelector('#meeting_password').removeAttribute('disabled')
                    document.querySelector('#zoom_link').removeAttribute('disabled')
                } else {
                    document.querySelector('#meeting_id').setAttribute('disabled', true)
                    document.querySelector('#meeting_password').setAttribute('disabled', true)
                    document.querySelector('#zoom_link').setAttribute('disabled', true)
                }
            },500)
        }, (err) => { })

    }

    const generateClassId = (date, slug = false) => {
        if (!slug) {
            slug = $("#reference_class :selected").val()
        } else {
            setClass_date(new Date())
        }
        if (slug) {
            fetchData(`get-timing-by-slug?course_id=${courseId}&slug=${slug}`, 'GET', '', true, false, (res) => {
                if (res.status) {
                    setDayCode(res.data.day)
                    let day = res.data.day - date.getDay()
                    if (day < 0) {
                        day += 7
                    }
                    date.setHours(24 * day);
                    setClass_date(date)
                    setClassData(prevState => ({
                        ...prevState,
                        start_time: res.data.start_time,
                        end_time: res.data.end_time,
                        class_id: `${res.data.name} - Class@ ${date.toDateString()} ${res.data.start_time}`,
                    }))

                    if(!classData.id) {
                        setClassData(prevState => ({
                            ...prevState,
                            meeting_type: '',
                            meeting_platform: ''
                        }))
                        setMeetingType('');
                        setMeetingPlatform('');
                    }
                    initialFormState('classForm')
                }
            }, (err) => { })
        }
    }

    const isWeekday = (date) => {
        let day = date.getDay();
        return day === dayCode;
    }

    const openFormModal = (modal_name) => {
        initialState('class')
        fetchData(`get-timing/${courseId}`, 'GET', '', true, false, (res) => {
            if (res.status) {
                setTimingData(res.data)
            }
        }, (err) => { })
    }
    const generateMeeting = () => {
        var meeting_platform = document.querySelector('#meeting_platform')
        var meeting_type = document.querySelector('#meeting_type')
        if (meetingPlatform === 'zoom' && meetingType === 'automatic') {
            meeting_platform.setAttribute('disabled', 'true')
            meeting_type.setAttribute('disabled', 'true')
            let class_date = document.getElementById("class_date").value;
            let class_data = { ...classData, 'class_date': class_date }
            setTinyloader(true)
            fetchData(`generate-meeting/${meetingPlatform}`, 'POST', class_data, true, false, (res) => {
                if (res.status) {
                    setClassData({
                        ...classData,
                        meeting_id: res.data.id,
                        meeting_password: res.data.password,
                        zoom_link: res.data.join_url,
                    })
                    initialFormState('classForm')
                }
                meeting_platform.removeAttribute('disabled')
                meeting_type.removeAttribute('disabled')
                setTinyloader(false)
            }, (err) => { })
        }
        if (meetingPlatform === 'webex' && meetingType === 'automatic') {
            meeting_platform.setAttribute('disabled', 'true')
            meeting_type.setAttribute('disabled', 'true')
            let class_date = document.getElementById("class_date").value;
            let class_data = { ...classData, 'class_date': class_date }
            setTinyloader(true)
            fetchData(`generate-meeting/${meetingPlatform}`, 'POST', class_data, true, false, (res) => {
                if (res.status) {
                    setClassData({
                        ...classData,
                        meeting_id: res.data.meetingNumber,
                        meeting_password: res.data.password,
                        zoom_link: res.data.webLink,
                    })
                    initialFormState('classForm')
                }
                meeting_platform.removeAttribute('disabled')
                meeting_type.removeAttribute('disabled')
                setTinyloader(false)
            }, (err) => { })
        }
    }

    const deleteMeeting = (e) => {
            setTinyloader(false)
        fetchData('delete-meeting', 'DELETE', '', true, false, (res) => {
            if (res.status) { }
        }, (err) => { })
    }

    const copyText = (id, message) =>  {
        let copyText = document.querySelector(`#${id}`);
        copyText.select();
        navigator.clipboard.writeText(copyText.value);
        let messageShow = document.querySelector(`#${message}`);
        messageShow.classList.remove('d-none');
        setTimeout(() => messageShow.classList.add('d-none'),1000)
    }

    useEffect(() => {
        reloadUrlDataTable(dt, 'course-details/' + courseId / 66);
    }, [dt, courseId])

    useEffect(() => {
        generateMeeting()
    }, [meetingPlatform, meetingType])


    return (
        <>
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-1">
                <div className="text-orange mb-1"></div>
                <div className="">
                    <button type="button" className="btn btn-success px-3 mb-1 me-2" data-bs-toggle="modal" data-bs-target="#addclassModal" onClick={() => openFormModal('class')}>Add Class</button>
                    <button className="btn btn-warning px-3 mb-1" onClick={() => getClassData('draft')}>Class {listName}</button>
                </div>
            </div>
            <div className="table-responsive p-1">
                <DataTables dt_name="course_details"></DataTables>
            </div>

            <div className="modal fade" id="addclassModal" tabIndex="-1" data-bs-backdrop="static" aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                    <div className="modal-content border-0 shadow">
                        <div className="modal-header bg-light">
                            <h5 className="modal-title">{opPerform === 'add' ? "Add" : disableField ? "Show" : "Update"} Class Link</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" id='classModal' onClick={(e) => {initialFormState('classForm'); deleteMeeting(e)}} aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-sm-4">
                            <form action="#" method="post" className="needs-validation" id="classForm" noValidate>
                                <div className="row mx-0 mb-2 mb-sm-4">
                                    <label htmlFor="reference_class" className="col-sm-4 col-form-label">Timing<sup className='text-danger fw-bold fs-15px'>{disableField ? '' : '*'}</sup></label>
                                    <div className="col-sm-8 col-xxl-7">
                                        <select className="form-select" name="reference_class" value={classData.reference_class} id="reference_class" onChange={handleInputChange} disabled={disableField} required >
                                            <option value=''>--select--</option>
                                            {
                                                timingData ? timingData.map((item, key) => {
                                                    return (
                                                        <option key={key} className={item.slug} value={item.id} >{item.name}</option>
                                                    )
                                                }) : ''
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="row mx-0 mb-2 mb-sm-4">
                                    <label htmlFor="class_id" className="col-sm-4 col-form-label">Class ID<sup className='text-danger fw-bold fs-15px'>{disableField ? '' : '*'}</sup></label>
                                    <div className="col-sm-8 col-xxl-7">
                                        <input type="text" className="form-control" id="class_id" defaultValue={classData.class_id ? classData.class_id : ''} readOnly="" disabled />
                                    </div>
                                </div>
                                <div className="row mx-0 mb-2 mb-sm-4">
                                    <label htmlFor="class_date" className="col-sm-4 col-form-label">Date<sup className='text-danger fw-bold fs-15px'>{disableField ? '' : '*'}</sup></label>
                                    <div className="col-sm-8 col-xxl-7">
                                        <DatePicker
                                            selected={class_date}
                                            onChange={(date) => {
                                                setClass_date(date)
                                                generateClassId(date)
                                            }}
                                            className="form-control"
                                            minDate={new Date()}
                                            filterDate={(date) => isWeekday(date)}
                                            timeInputLabel="Time:"
                                            dateFormat="MMM dd, yyyy"
                                            onChangeRaw={(e) => e.preventDefault()}
                                            disabled={disableField}
                                            id="class_date"
                                            name="class_date"
                                        />
                                    </div>
                                </div>

                                <div className="row mx-0 mb-2 mb-sm-4">
                                    <label htmlFor="start_time" className="col-sm-4 col-form-label">Start Time<sup className='text-danger fw-bold fs-15px'>{disableField ? '' : '*'}</sup></label>
                                    <div className="col-sm-8 col-xxl-7">
                                        <input type="text" className="form-control" id="start_time" defaultValue={classData.start_time ? classData.start_time : ''} readOnly="" disabled />
                                    </div>
                                </div>
                                <div className="row mx-0 mb-2 mb-sm-4">
                                    <label htmlFor="end_time" className="col-sm-4 col-form-label">End Time<sup className='text-danger fw-bold fs-15px'>{disableField ? '' : '*'}</sup></label>
                                    <div className="col-sm-8 col-xxl-7">
                                        <input type="text" className="form-control" id="end_time" defaultValue={classData.end_time ? classData.end_time : ''} readOnly="" disabled />
                                    </div>
                                </div>
                                <div className="row mx-0 mb-2 mb-sm-4">
                                    <label htmlFor="meeting_platform" className="col-sm-4 col-form-label">Meeting Platform<sup className='text-danger fw-bold fs-15px'>{disableField ? '' : '*'}</sup></label>
                                    <div className="col-sm-8 col-xxl-7">
                                        <select className="form-select" name="meeting_platform" id="meeting_platform" value={classData.meeting_platform} onChange={(e) => { setMeetingPlatform(e.target.value); handleInputChange(e) }} disabled={classData.id ? true : false} required >
                                            <option value=''>--select--</option>
                                            <option value='zoom'>Zoom</option>
                                            <option value='webex'>Webex</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row mx-0 mb-2 mb-sm-4">
                                    <label htmlFor="meeting_type" className="col-sm-4 col-form-label">Meeting Type<sup className='text-danger fw-bold fs-15px'>{disableField ? '' : '*'}</sup></label>
                                    <div className="col-sm-8 col-xxl-7">
                                        <select className="form-select" name="meeting_type" id="meeting_type" value={classData.meeting_type} onChange={(e) => { setMeetingType(e.target.value); handleInputChange(e) }} disabled={classData.id ? true : false} required >
                                            <option value=''>--select--</option>
                                            <option value='manual'>Manual</option>
                                            <option value='automatic'>Automatic</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row mx-0 mb-2 mb-sm-4">
                                    <label htmlFor="meeting_id" className="col-sm-4 col-form-label">Meeting Id & Password<sup className='text-danger fw-bold fs-15px'>{disableField ? '' : '*'}</sup></label>
                                    <div className="col-sm-4 col-xxl-4 manage-copy-icon">
                                        <input type="number" className="form-control" placeholder='Meeting Id' id="meeting_id" name="meeting_id" autoComplete="off" defaultValue={classData.meeting_id} onChange={handleInputChange} readOnly={disableField} disabled={classData.id || meetingType === 'automatic' ? true : false} required />
                                        <span className="d-none copy-text" id="meeting_copied">Copied</span><i className="bi-clipboard copy-icon" onClick={(e) => copyText('meeting_id', 'meeting_copied')} title='Copy' ></i>
                                    </div>
                                    <div className="col-sm-4 mt-4 mt-sm-0 col-xxl-3 manage-copy-icon">
                                        <input type="password" className="form-control" placeholder='Password' id="meeting_password" name="meeting_password" autoComplete="off" defaultValue={classData.meeting_password} onChange={handleInputChange} readOnly={disableField} disabled={classData.id || meetingType === 'automatic' ? true : false} required />
                                        <span className="d-none copy-text" id="password_copied">Copied</span><i className="bi-clipboard copy-icon" onClick={(e) => copyText('meeting_password', 'password_copied')} title='Copy'></i>
                                    </div>
                                </div>
                                <div className="row mx-0 mb-2 mb-sm-4">
                                    <label htmlFor="zoom_link" className="col-sm-4 col-form-label">Meeting Link<sup className='text-danger fw-bold fs-15px'>{disableField ? '' : '*'}</sup></label>
                                    <div className="col-sm-8 col-xxl-7 manage-copy-icon">
                                        <input type="url" className="form-control pe-4" autoComplete="off" id="zoom_link" name="zoom_link" defaultValue={classData.zoom_link} onChange={handleInputChange} readOnly={disableField} disabled={classData.id || meetingType === 'automatic' ? true : false} required />
                                        <span className="d-none copy-text" id="link_copied">Copied</span><i className="bi-clipboard copy-icon" onClick={(e) => copyText('zoom_link', 'link_copied')} title='Copy'></i>
                                    </div>
                                </div>
                                <div className="row mx-0">
                                    <div className="col-sm-8 offset-sm-4">
                                        {disableField ? '' : (
                                            opPerform === 'draft' ? <>
                                                <button type="button" className="btn btn-blue w-120px px-4 me-2" onClick={handelPublishSubmitForm}>Publish</button>
                                            </> : <>
                                                <button type="button" className="btn btn-blue w-120px px-4 me-2" disabled={tinyloader} onClick={handelSubmitForm}>
                                                    <span className="spinner-border spinner-border-sm mr-1 hidden"></span>{opPerform === 'add' ? "Add" : "Update"}
                                                </button>
                                            </>
                                        )
                                        }
                                        {disableField ? '' : (
                                            opPerform === 'draft' || opPerform === 'add' ? <>
                                                <button type="button" className="btn btn-outline-success" disabled={tinyloader} onClick={handelDraftSubmitForm}>
                                                <span className="spinner-border spinner-border-sm mr-1 hidden"></span>Save Draft
                                                </button>
                                            </> : ''
                                        )
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClassLinks