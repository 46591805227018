import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { fetchData, scrollbarSetToTop } from "../components/Helper"
import CourseCard from "../components/cards/Course"
import { Helmet } from "react-helmet"

function TeacherInfo() {
    const [teacher, setTeacher] = useState()
    const teacherId = useParams().teacher_id

    useEffect(() => {
        scrollbarSetToTop();
        fetchData(`get-profile/${teacherId}`, 'GET', '', false, false, (res) => {
            if (res.status && res.data) {
                setTeacher(res.data)
            }
        }, (err) => {})
    }, [teacherId])

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>XTLMS | Teacher's Details</title>
        </Helmet>
            
            {teacher && 
            <section className="pt-0">
                <div className="container-fluid px-0">
                    <div className="bg-blueLight h-160px"></div>
                </div>
                <div className="container mt-n4">
                    <div className="row">
                        <div className="col-12">
                             <div className="inst-profile-wrapper">                                
                                <div className="inst-profile-img">
                                    {/* <img src={teacher.user_details.image} alt={teacher.name}  /> */}
                                    <img src="https://learnsphere.se/images/landing4.jpg" alt="" />
                                </div>
                                <div className="inst-profile-content">
                                    <h4 className="text-white">{teacher.name}</h4>
                                    <div className="inst-profile-meta d-flex flex-wrap align-items-center gap-3 gap-md-4">
                                         <div className="inst-profile-info">
                                            <span>{teacher.user_details.experience} Years</span>
                                            <span>Teaching Experience</span>
                                         </div>
                                         <div className="inst-profile-info">
                                            <span>{teacher.user_details.experience} Rating</span>
                                            <span>
                                                <div className="star-rating">
                                                    <span style={{width:Number(teacher.user_details.rating * 20)+'%'}}></span>
                                                </div>
                                                <span>{teacher.user_details.rating} out of 5</span>
                                            </span>
                                         </div>
                                    </div>
                                </div>
                             </div>
                        </div>
                    </div>
                </div>
            </section>
            }

            {/* {teacher && 
            <div className="left-profile-new">
                <div className="profile-box text-center mb-3">
                    <div className="position-relative">
                        <img src={teacher.user_details.image} alt={teacher.name} className="img-fluid mt-2 rounded-circle h-110 border-4" />
                    </div>
                    <div className="mt-4">
                        <div className="mb-3">
                            <strong className="d-block fs-14px fw-medium text-muted">Teacher Name</strong>
                            <span className="fs-17px d-block">{teacher.name}</span>
                        </div>
                        <div className="mb-3">
                            <strong className="d-block fs-14px fw-medium text-muted">Teaching Experience</strong>
                            <span className="fs-17px">{teacher.user_details.experience} Years</span>
                        </div>
                        <div className="mb-4">
                            <strong className="d-block fs-14px fw-medium text-muted">Rating</strong>
                            <div className="star-rating">
                                <span style={{width:Number(teacher.user_details.rating * 20)+'%'}}></span>
                            </div>
                            <div className="fs-17px">{teacher.user_details.rating} out of 5</div>
                        </div>
                    </div>
                </div>
            </div> } */}

            <section className="cources-section py-4 py-sm-5">
                <div className="container pb-sm-4">
                    <div className="row">
                        <div className="col-sm-12">
                            {teacher ?
                                <div className="d-flex flex-column mt-2">
                                    <div className="mb-3">
                                        <strong className="d-block fs-20px fw-bold">Brief Experience </strong>
                                        <span className="fs-17px">{teacher.user_details.brief ? teacher.user_details.brief : ''}</span>
                                    </div>
                                    { teacher.feedbacks && teacher.feedbacks.length > 0 ?
                                        <div className="mb-3">
                                            <strong className="d-block fs-14px fw-bold text-muted">Feedback </strong>
                                            {teacher.feedbacks.map((feedback_details, i) => {
                                                return (
                                                    <blockquote className="border-lightblue rounded p-3 mt-1" key={i}>
                                                        <p className="opacity-90 m-0">
                                                            {feedback_details.description}
                                                        </p>
                                                        <small className="text-muted fs-12px">&mdash; {feedback_details.name}</small>
                                                    </blockquote>
                                                )
                                            })}
                                        </div>
                                        : ''
                                    }
                                    <div>
                                        <strong className="d-block fs-20px fw-bold">Taught Courses </strong>
                                        <div className="cources-section pt-3" >
                                            <div className="item_list_two" >
                                                { teacher.course ? <CourseCard course_details={teacher.course} hide='yes' /> : '' }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TeacherInfo
