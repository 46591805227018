import { useEffect, useState,useContext } from "react"
import { Helmet } from "react-helmet"
import { fetchData, validateForm } from "../../components/Helper";
import TimeZone from '../../components/TimeZone';
import Context from '../../components/Context';

function Setting() {
    const [setting, setSetting] = useState()
    const [context] = useContext(Context)
    // const [settingDate, setSettingData] = useState()

    useEffect(() => {
        fetchData('get-settings', 'get', '', true, false, (res) => {
            if(res.status && res.data){
                setSetting(res.data)
            }
        }, (err) => {})
    }, [])

    const handleInputChange = (e) => {
        // setSettingData(prevState => ({
        //     ...prevState,
        //     [e.target.name]: e.target.value
        // }))
    }

    const saveData = (e) => {
        if(validateForm(e)){
            let formData = new FormData(document.getElementById('settingsForm'))
            fetchData('update-settings', 'POST', formData, true, true, (res) => {
                if(res.status){
                    //setAccountData({country:'us', user_type: accountType})
                }
            }, (err) => {})
        }
    }
    return (
        <>
        <section className="admin-wrapper">
            <Helmet>
                <meta charSet="utf-8" />
                <title>XTLMS | Settings</title>
            </Helmet>
            { setting && (
            <div className="container-fluid">
                <div className="row align-items-center justify-content-between">
                    <div className="col mb-2">
                        <h5 className="m-0 heading-lines pb-2 fs-20px">Settings</h5>
                    </div>
                </div>
                <div className="row g-2 g-sm-3 g-lg-4">
                    <div className="col-sm-12">
                        <div className="card border-0 shadow-sm rounded-10">
                            <div className="card-body p-sm-4">
                            <fieldset className="rounded-10 p-3 p-sm-4 bg-light">
                                        <legend className="fs-6 fw-medium text-blue">
                                            TimeZone
                                        </legend>
                                        <div className="col mb-2">
                                            <TimeZone time_zone= {context.auth.time_zone} />
                                        </div>
                                        {/* <div className="mb-3 row mx-0">
                                            <label htmlFor="stripe_api_key" className="col-sm-3 col-xxl-2 col-form-label text-xxl-end">
                                                API Key:
                                            </label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="text" className="form-control" id="stripe_api_key" name="stripe_api_key" onChange={handleInputChange} defaultValue={setting.stripe_api_key} required/>
                                            </div>
                                        </div>
                                        <div className="row mx-0">
                                            <label htmlFor="stripe_secret_key" className="col-sm-3 col-xxl-2 col-form-label text-xxl-end">
                                                Secret Key:
                                            </label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="text" className="form-control" id="stripe_secret_key" name="stripe_secret_key" onChange={handleInputChange} defaultValue={setting.stripe_secret_key} required/>
                                            </div>
                                        </div> */}
                                    </fieldset>
                                <form id="settingsForm" action="#" method="post" className="needs-validation" noValidate>
                                    <fieldset className="border1 rounded-10 p-3 p-sm-4 mb-4 bg-light">
                                        <legend className="fs-6 fw-medium text-blue">
                                            Site
                                        </legend>
                                        <div className="mb-3 row mx-0">
                                            <label htmlFor="site_name" className="col-sm-3 col-xxl-2 col-form-label text-xxl-end">
                                                Site Name:
                                            </label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="text" className="form-control" id="site_name" name="site_name" onChange={handleInputChange} defaultValue={setting.site_name} required/>
                                            </div>
                                        </div>
                                        <div className="mb-3 row mx-0">
                                            <label htmlFor="site_url" className="col-sm-3 col-xxl-2 col-form-label text-xxl-end">
                                                Site URL:
                                            </label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="text" className="form-control" id="site_url" name="site_url" onChange={handleInputChange} defaultValue={setting.site_url} required/>
                                            </div>
                                        </div>
                                        <div className="mb-3 row mx-0">
                                            <label htmlFor="api_url" className="col-sm-3 col-xxl-2 col-form-label text-xxl-end">
                                                Asset URL:
                                            </label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="text" className="form-control" id="api_url" name="api_url" onChange={handleInputChange} defaultValue={setting.api_url} required/>
                                            </div>
                                        </div>
                                        <div className="mb-3 row mx-0">
                                            <label htmlFor="copyright" className="col-sm-3 col-xxl-2 col-form-label text-xxl-end">
                                                Copyright:
                                            </label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="text" className="form-control" id="copyright" name="copyright" onChange={handleInputChange} defaultValue={setting.copyright} required/>
                                            </div>
                                        </div>
                                        <div className="mb-3 row mx-0">
                                            <label htmlFor="currency" className="col-sm-3 col-xxl-2 col-form-label text-xxl-end">
                                                Currency:
                                            </label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <select className="form-select" id="currency" name="currency" onChange={handleInputChange} defaultValue={setting.currency} required>
                                                    <option value="EGP">EGP</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row mx-0">
                                            <label htmlFor="logo" className="col-sm-3 col-xxl-2 col-form-label text-xxl-end">
                                                Logo:
                                            </label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="text" className="form-control" id="logo" name="logo" onChange={handleInputChange} defaultValue={setting.logo} required/>
                                            </div>
                                        </div>
                                    </fieldset>

                                    <fieldset className="border1 rounded-10 p-3 p-sm-4 mb-4 bg-light">
                                        <legend className="fs-6 fw-medium text-blue">
                                            Emails
                                        </legend>
                                        <div className="mb-3 row mx-0">
                                            <label htmlFor="admin_mail" className="col-sm-3 col-xxl-2 col-form-label text-xxl-end">
                                                Admin Email:
                                            </label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="email" className="form-control" id="admin_mail" name="admin_mail" onChange={handleInputChange} defaultValue={setting.admin_mail} required/>
                                            </div>
                                        </div>
                                        <div className="row mx-0">
                                            <label htmlFor="support_mail" className="col-sm-3 col-xxl-2 col-form-label text-xxl-end">
                                                Support Email:
                                            </label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="email" className="form-control" id="support_mail" name="support_mail" onChange={handleInputChange} defaultValue={setting.support_mail} required/>
                                            </div>
                                        </div>
                                    </fieldset>

                                    <fieldset className="border1 rounded-10 p-3 p-sm-4 mb-4 bg-light">
                                        <legend className="fs-6 fw-medium text-blue">
                                            Payment Charges
                                        </legend>
                                        <div className="mb-3 row mx-0">
                                            <label htmlFor="fawry_charge" className="col-sm-3 col-xxl-2 col-form-label text-xxl-end">
                                                {'Fawry Charge (< 5000 EGP):'}
                                            </label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text">EGP</span>
                                                    <input type="text" className="form-control" id="fawry_charge" name="fawry_charge" onChange={handleInputChange} defaultValue={setting.fawry_charge} required/>
                                                    <span className="input-group-text">+</span>
                                                    <input type="text" className="form-control" id="fawry_charge_2" name="fawry_charge_2" onChange={handleInputChange} defaultValue={setting.fawry_charge_2} required/>
                                                    <span className="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3 row mx-0">
                                            <label htmlFor="fawry_charge" className="col-sm-3 col-xxl-2 col-form-label text-xxl-end">
                                            {'Fawry Charge (> 5000 EGP):'}
                                            </label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text">EGP</span>
                                                    <input type="text" className="form-control" id="fawry_charge_3" name="fawry_charge_3" onChange={handleInputChange} defaultValue={setting.fawry_charge_3} required/>
                                                    <span className="input-group-text">+</span>
                                                    <input type="text" className="form-control" id="fawry_charge_4" name="fawry_charge_4" onChange={handleInputChange} defaultValue={setting.fawry_charge_4} required/>
                                                    <span className="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mx-0">
                                            <label htmlFor="stripe_charge" className="col-sm-3 col-xxl-2 col-form-label text-xxl-end">
                                                Stripe Charge:
                                            </label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text">EGP</span>
                                                    <input type="text" className="form-control" id="stripe_charge_2" name="stripe_charge_2" onChange={handleInputChange} defaultValue={setting.stripe_charge_2} required/>
                                                    <span className="input-group-text">+</span>
                                                    <input type="text" className="form-control" id="stripe_charge" name="stripe_charge" onChange={handleInputChange} defaultValue={setting.stripe_charge} required/>
                                                    <span className="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>

                                    <fieldset className="border1 rounded-10 p-3 p-sm-4 mb-4 bg-light">
                                        <legend className="fs-6 fw-medium text-blue">
                                            Zoom
                                        </legend>
                                        <div className="mb-3 row mx-0">
                                            <label htmlFor="api_key" className="col-sm-3 col-xxl-2 col-form-label text-xxl-end">
                                                API Key:
                                            </label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="text" className="form-control" id="api_key" name="api_key" onChange={handleInputChange} defaultValue={setting.api_key} required/>
                                            </div>
                                        </div>
                                        <div className="row mx-0">
                                            <label htmlFor="api_secret" className="col-sm-3 col-xxl-2 col-form-label text-xxl-end">
                                                Secret Key:
                                            </label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="text" className="form-control" id="api_secret" name="api_secret" onChange={handleInputChange} defaultValue={setting.api_secret} required/>
                                            </div>
                                        </div>
                                    </fieldset>
                                    
                                    <fieldset className="border1 rounded-10 p-3 p-sm-4 mb-4 bg-light">
                                        <legend className="fs-6 fw-medium text-blue">
                                            Fawry
                                        </legend>
                                        <div className="mb-3 row mx-0">
                                            <label htmlFor="fawry_host" className="col-sm-3 col-xxl-2 col-form-label text-xxl-end">
                                                Fawry Host:
                                            </label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="text" className="form-control" id="fawry_host" name="fawry_host" onChange={handleInputChange} defaultValue={setting.fawry_host} required/>
                                            </div>
                                        </div>
                                        <div className="mb-3 row mx-0">
                                            <label htmlFor="merchant_code" className="col-sm-3 col-xxl-2 col-form-label text-xxl-end">
                                                Merchant Code:
                                            </label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="text" className="form-control" id="merchant_code" name="merchant_code" onChange={handleInputChange} defaultValue={setting.merchant_code} required/>
                                            </div>
                                        </div>
                                        <div className="mb-3 row mx-0">
                                            <label htmlFor="security_key" className="col-sm-3 col-xxl-2 col-form-label text-xxl-end">
                                                Security Hash:
                                            </label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="text" className="form-control" id="security_key" name="security_key" onChange={handleInputChange} defaultValue={setting.security_key} required/>
                                            </div>
                                        </div>
                                    </fieldset>

                                    <fieldset className="rounded-10 p-3 p-sm-4 bg-light">
                                        <legend className="fs-6 fw-medium text-blue">
                                            Stripe
                                        </legend>
                                        <div className="mb-3 row mx-0">
                                            <label htmlFor="stripe_api_key" className="col-sm-3 col-xxl-2 col-form-label text-xxl-end">
                                                API Key:
                                            </label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="text" className="form-control" id="stripe_api_key" name="stripe_api_key" onChange={handleInputChange} defaultValue={setting.stripe_api_key} required/>
                                            </div>
                                        </div>
                                        <div className="row mx-0">
                                            <label htmlFor="stripe_secret_key" className="col-sm-3 col-xxl-2 col-form-label text-xxl-end">
                                                Secret Key:
                                            </label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="text" className="form-control" id="stripe_secret_key" name="stripe_secret_key" onChange={handleInputChange} defaultValue={setting.stripe_secret_key} required/>
                                            </div>
                                        </div>
                                    </fieldset>

                                    <div className="row mx-0 p-3 p-sm-4">
                                        <div className="col-sm-9 offset-sm-3 offset-xxl-2">
                                            <button type="button" className="btn btn-success px-5" onClick={saveData} title="Save">Save</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div> )}
        </section>
        </>
    )
}

export default Setting