import {useState, useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { fetchData, validateForm, intlTel_phone, validatePhone, scrollbarSetToTop } from "../components/Helper";

const ContactUs = () => {
    const [data, setData] = useState({ name: "", email: "", message: "", phone: "" })
    const [intlTel, setIntlTel] = useState()

    useEffect(() => {
        scrollbarSetToTop();
        setIntlTel(intlTel_phone('#phone', '', 'us', true)) 
     }, []);
     
    const handeleInputChange = (e) => {
        setData(prevState => ({
            ...prevState,
            [e.target.name]:e.target.value,
        }))
    }

    const handleSubmitForm = (e) => {
		e.preventDefault();
        if(validateForm(e) && validatePhone(intlTel, '#phone')){
            var contactForm = document.getElementById('contactForm')
            
            let contact_data = {...data, 'phone': intlTel.getNumber()}

            fetchData('contact-add', 'POST', contact_data, false, false, (res) => {
                if(res.status) {
                    setData({
                        name: "",
                        email: "",
                        message: "",
                        phone: ""
                    })
                    contactForm.reset()
                    contactForm.classList.remove('was-validated')
                }
            }, (err) => {})
        }
	}
    return(
        <section className="login-section">
            <Helmet>
                <meta charSet="utf-8" />
                <title>XTLMS | Contact Us</title>
            </Helmet>
            <div className="container">
                <div className="row align-items-md-center g-0">
                    <div className="col-md-6 bg-white">
                        <form id="contactForm" className="p-4 p-md-5 rounded-4 shadow needs-validation" noValidate>
                            <div className='mb-4'>
                                <h2 className="heading-lines mb-1 mt-0">Let's talk</h2>
                                <p className='mb-0 opacity-75'>To request a quote or want to meet up for coffee, contact us directly or fill out the form and we will get back to you promptly</p>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control rounded-5 px-4" name="name" id="name" placeholder="Name" onChange={ handeleInputChange } required />
                                <label htmlFor="studentname" className='px-3'>Name*</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control rounded-5 px-4" name="email" id="email" placeholder="name@example.com" onChange={ handeleInputChange } required />
                                <label htmlFor="floatingInput" className='px-3'>Email address*</label>
                            </div>
                            <div className="form-floating tel_input mb-3">
                                <input type="tel" className="form-control rounded-5" id="phone" autoComplete="off" name="phone" data-intl-tel-input-id="0" style={{'height': '58px'}} required />
                            </div>
                            <div className="form-floating mb-3">
                                <textarea className="form-control rounded-5 px-4" name="message" id="message" rows="6" placeholder="Message" style={{'height': '110px'}} onChange={ handeleInputChange } required></textarea>
                                <label htmlFor="message" className='px-3'>Message*</label>
                            </div>
                            <div className="mb-0">
                                <button type="submit" className="btn btn-blue rounded-5 btn-lg w-100" onClick={handleSubmitForm} title='SUBMIT'>SUBMIT</button>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6  ps-md-5">
                        <img src="images/contact-us.svg" className="w-100 p-3 p-md-5" alt="XTLMS"/>
                    </div>
                </div>
                <div class="row pt-sm-4 g-4">
                    <div class="col-lg-4 mt-lg-0">
                        <div class="card card-body border-0 shadow p-4 text-center h-100">
                            <div class="how-it-work-icon d-grid mx-auto mb-4">                                
                                <i class="lh-1 bi-geo-alt-fill text-blue"></i>  
                            </div>
                            <ul class="list-inline mb-0">
                                <li class="list-item fw-medium ">
                                    1st Floor, 529C/486, Kamla Nehru Nagar, Jageshwarpuri, Shankar Purva-Ⅲ, Zone 7, Lucknow - 226022 (UP) India
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-4 mt-lg-0">
                        <div class="card card-body  border-0 shadow p-4 text-center h-100">
                            <div class="how-it-work-icon d-grid mx-auto mb-4">                                
                                <i class="lh-1 bi-envelope-at-fill text-blue"></i>  
                            </div>
                            <ul class="list-inline mb-0">
                                <li class="list-item ">
                                    sales@xipetech.com
                                </li>                                
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-4 mt-lg-0">
                        <div class="card card-body border-0 shadow p-4 text-center h-100">
                            <div class="how-it-work-icon d-grid mx-auto mb-4">                                
                                <i class="lh-1 bi-telephone-outbound-fill text-blue"></i>  
                            </div>
                            <ul class="list-inline fw-medium mb-0">
                                <li class="list-item">
                                    +91-8090-920216
                                </li>                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default ContactUs