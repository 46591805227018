import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { fetchData } from "../components/Helper"
import { useNavigate, useLocation } from 'react-router-dom'
import Context from "../components/Context";

function VerifyToken(){
    const [, setContext] = useContext(Context)
    const VerifyPaymentParams = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if(location.search && location.search.includes('token')){
            fetchData(`verify-token${location.search}`, 'GET', '', true, false, (res) => {

                if (res.status && res.data) {
                    localStorage.setItem('wt-token', res.data.token)

                    fetchData('get-auth-user-info', 'get', '', true, false, (res) => {
                        if(res.status){
                            document.getElementById('loader').classList.add('active')
                            setContext(prevState => ({
                                ...prevState,
                                auth: res.data
                            }))
                            VerifyPaymentParams('/my-subjects')
                        }
                    }, (err) => {}, false, false)
                }
            }, (err) => {});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [VerifyPaymentParams])
    
    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>XT LMS | Verify Token</title>
        </Helmet>
        <section className="about-section py-5">
            <div className="container py-sm-4 py-5">
                <div className="row justify-content-center py-5">
                    <div className="col-sm-8 text-center">
                        <h2 className="fw-bold">Please wait...</h2>
                        <p className="opacity-75">We are authenticating your login details.</p>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default VerifyToken