import Timing from './Timing';

function  Settings(props) {
    return(
        <>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link text-blue active fw-medium" data-bs-toggle="tab" data-bs-target="#timingBox" type="button" role="tab">Timing</button>
                </li>
            </ul>
            <div className="tab-content border p-3 border-top-0">
                { props.loadComponent ? <Timing loadComponent={props.loadComponent}/> : '' }
            </div>
        </>
    )
}

export default Settings