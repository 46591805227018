import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom"
import { useEffect, useState} from "react";
import { fetchData} from "../../components/Helper";
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';

function CourseCarousel() {
    const [course, setCourse] = useState(''); 
    const navigate = useNavigate();

  useEffect(() => {
    fetchData('courses/yes', 'GET', '', false, false, (res) => {
        if(res.status && res.data){
            setCourse(res.data)
        }
    }, (err) => {})
}, []);

const handleJoinCourse = (id) => {
    navigate(`/course/${id}/join`) 
}


  const responsiveOptions = {
    0: {
      nav: true,         
      dots: false,
      items: 1, // Number of items to show at 0px screen width
    },
    600: {
      nav: true,         
      dots: false,
      items: 2, // Number of items to show at 600px screen width
    },
    1000: {
      nav: true,         
      dots: false,
      items: 3, 
    },
  };


  return (  
    <>             
      {course.length > 0 && (
        <div className="item_list_two">
          {course.slice(0,4).map((course_details, i) => (
              <div className="item" key ={i}> 
                  <div className="course-item">
                      <div className="ratio ratio-4x3 bg-light">
                          {/* <img src={course_details.course_image} className="w-100 h-100 img-cover" alt="XT LMS" /> */}
                          <img src="/images/course.jpg" className="w-100 h-100 img-cover" alt="XT LMS" />
                      </div>
                      <span className="course-tag">{course_details.subject_name}</span>
                      <div className="course-avatar d-none justify-content-between align-items-center " >
                      {course_details.teacher_image ?  <img  src={course_details.teacher_image} className="mmw-40px d-none" alt="XT LMS" /> : '' }
                      </div>
                      <div className="course-content pt-4 p-2 p-lg-3">
                          <h6 className="course-heading">{course_details.name}</h6>
                          <p className="course-description mb-2">
                              {course_details.description}
                          </p>
                          { course_details.teacher_name ? 
                              <Link to={`/teacher-info/${course_details.teacher_id}`} className="fs-14px mb-2 text-black bg-orangeLight px-3 py-1 rounded text-decoration-none text-truncate" title={`By ${course_details.teacher_name }`}>
                                  By&nbsp; 
                                  {course_details.teacher_name }
                              </Link>
                              : ''
                          }
                          <div className="pt-2 course-bottom-cta d-flex justify-content-between align-items-center">
                          <button onClick={() => handleJoinCourse(course_details.id)} className="enroll-link" title="Join Course">
                            Join Course <i class="fs-4 lh-1 bi-arrow-right-short"></i> 
                          </button>
                          
                          <span className="fw-bold">{(course_details.class_price ===  'EGP 0.00' || course_details.class_price ===  'EGP 0') ? course_details.course_price : course_details.class_price }</span>

                              {/* <button className="btn btn-outline-blue btn-sm fw-medium text-nowrap" title="Join Course">Join Course</button> */}
                          </div>
                      </div>
                  </div> 
              </div>
          ))}
        </div>      
        )}
    </>  
  );
}

export default CourseCarousel;
