import { Link, useNavigate } from "react-router-dom";
import { useParams } from 'react-router';
import _ from 'lodash';
import { useEffect, useState } from "react";
import { fetchData } from "../../components/Helper";
import Datatables, { initDataTable, reloadDataTable, reloadUrlDataTable, destroyDataTable } from '../../components/Datatables';
import { createRoot } from "react-dom/client";
import { Helmet } from "react-helmet";

function EduClsList(){
    const navigate = useNavigate()
    const eduCls_type = useParams().eduCls_type

    const [dt] = useState({
        dt_url: `educational-classification-list/${eduCls_type}`,
        dt_name: 'educational-classification-list',
        dt_column: [
            { data:"id", name:'id', title: "#ID"},
            { data:"name", name:'name', title:"Classification Name"},
            { data:"id", title:"Action", sortable:false, searchable:false, class:"text-nowrap text-center", width:160}
        ],
        
        dt_column_defs: [
            {
                targets: 2,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(<>

                        <button type="button" className="table-icons-text" title="Edit" onClick={() => handleEditForm(rowData.id)}>
                            <i class="bi bi-pencil"></i>
                        </button>
                        <button type="button" className="badge bg-danger" title="Delete" onClick={() => handleChangeStatus('delete', rowData.id)}>
                            <i class="bi bi-trash"></i>
                        </button>
                                                    
                    </>)
                },
            },
        ]
    })

    const handleChangeStatus = (status, id) => {
        fetchData(`educational-classification/change-status`, 'POST', {'id': id, 'status': status}, true, false, (res) => {
            reloadDataTable(dt)
        }, (err) => {})
    }

    const handleEditForm = (id) => {
        navigate(`/educational-classification/${eduCls_type}/edit/${id}`)
    }

    useEffect(() => {
        initDataTable(dt)
        document.querySelectorAll('button[data-bs-toggle="tab"]').forEach((e)=> {
            e.addEventListener('show.bs.tab', function (event) {
                navigate(`/educational-classification/${event.target.dataset.tablename}/list`)
                destroyDataTable(dt)
                reloadDataTable(dt)
                reloadUrlDataTable(dt, `educational-classification-list/${event.target.dataset.tablename}`)
                
            })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dt])

    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>XTLMS | Educational Classifications</title>
        </Helmet>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h5 className="m-0 heading-lines pb-2 fs-20px">Educational Classifications</h5>
                        </div>
                        <div className="col-auto mb-2">
                            <Link to={`/educational-classification/${eduCls_type}/create`} className="btn btn-blue px-3 mb-1 me-1" title={`Add ${ _.upperFirst(eduCls_type) }`}>Add { _.upperFirst(eduCls_type) }</Link>
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className={eduCls_type === 'system' ? 'nav-link text-blue active fw-medium' : 'nav-link text-dark fw-medium'} data-bs-toggle="tab" data-bs-target={`#${eduCls_type}box`} type="button" role="tab" data-tablename="system" title="Systems">Systems</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className={eduCls_type === 'level' ? 'nav-link text-blue active fw-medium' : 'nav-link text-dark fw-medium'} data-bs-toggle="tab" data-bs-target={`#${eduCls_type}box`} type="button" role="tab" data-tablename="level" title="Levels">Levels</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className={eduCls_type === 'board' ? 'nav-link text-blue active fw-medium' : 'nav-link text-dark fw-medium'} data-bs-toggle="tab" data-bs-target={`#${eduCls_type}box`} type="button" role="tab" data-tablename="board" title="Boards">Boards</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className={eduCls_type === 'subject' ? 'nav-link text-blue active fw-medium' : 'nav-link text-dark fw-medium'} data-bs-toggle="tab" data-bs-target={`#${eduCls_type}box`} type="button" role="tab" data-tablename="subject" title="Subjects">Subjects</button>
                                        </li>
                                    </ul>
                                    <div className="tab-content border border-top-0">
                                        <div className="tab-pane fade p-3 show active" id={`#${eduCls_type}box`} role="tabpanel">
                                            <div className="table-responsive p-1">
                                                <Datatables dt_name="educational-classification-list"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EduClsList