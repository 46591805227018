import React from 'react'
import { useState,useEffect } from "react"; 
import { Link, useNavigate } from "react-router-dom";
import _ from 'lodash';
import { fetchData, validateForm, intlTel_phone, validatePhone } from "../../components/Helper";
import { Helmet } from 'react-helmet';

function FeedbackCreate() {
    const navigate = useNavigate()
    const [course, setCourse] = useState([]); 
    const [teacher, setTeacher] = useState([]);
    const [courseId, setCourseId] = useState();
    const [teacherId, setTeacherId] = useState();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [image, setImage] = useState(''); 
    const [comment, setComment] = useState(''); 
    const [intlTel, setIntlTel] = useState()
    const [features, setFeatures] = useState('no')
    
    
    useEffect(() => {
        fetchData('teachers', 'GET', '', true, false, (res) => { 
            setTeacher(res.data)   
        }, (err) => {}) 

        setIntlTel(intlTel_phone('#phone', '', 'us', true)) 
    }, []); 
 
    const saveFeedback = (e) => {
        e.preventDefault(); 
        if(validateForm(e) && validatePhone(intlTel, '#phone')){ 
            const formData = new FormData(); 
            formData.append('name', name) 
            formData.append('email', email) 
            formData.append('phone',  intlTel.getNumber()) 
            formData.append('comment', comment) 
            formData.append('teacher_id', teacherId) 
            formData.append('teacher_id', teacherId)  
            formData.append('course_id', courseId) 
            formData.append('picture', image) 
            formData.append('features', features) 
            
            fetchData('feedback-create', 'POST', formData, true, true, (res) => {
                if(res.status){
                    navigate(`/feedback/list`)
                }
            }, (err) => {})
    
        }
    }

    const getTeacherId = (e) => { 
        let id = e.target.value; 
        if(id){
            fetchData(`get-course/${id}`, 'GET', '', true, false, (res) => {  
                setCourse(res.data)
            }, (err) => {
                
            }) 
        }else{
            setCourse('')
        }
        setTeacherId(e.target.value)
    }

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>XTLMS | Add Feedback</title>
        </Helmet>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h5 className="m-0 heading-lines pb-2 fs-20px">Add Feedback</h5>
                        </div>
                        <div className="col-auto mb-2">
                            <Link to="/feedback/list" className="btn btn-blue mb-1" title='Feedback List'>Feedback List</Link>
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body p-sm-3 p-xl-3">
                                   <form action="#"  className="p-3 p-sm-3 needs-validation" method="post" noValidate>
                                        <div className="mb-2 mb-sm-4 row mx-0">                                            
                                            <div className="col-sm-12 col-lg-6 col-xxl-6  mb-3 mb-sm-4">
                                                <label htmlFor="adminname" className="mb-2">Name<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                                <input type="text" className="form-control" id="name" value={name} onChange={e => setName(e.target.value)} required />
                                            </div>
                                       
                                            
                                            <div className="col-sm-12 col-lg-6 col-xxl-6  mb-3 mb-sm-4">
                                                <label htmlFor="email" className="mb-2">Email<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                                <input type="text" className="form-control" id="email" value={email} onChange={e => setEmail(e.target.value)} required />
                                            </div>
                                       
                                            
                                            <div className="col-sm-12 col-lg-6 col-xxl-6  mb-3 mb-sm-4 form-flag-height38">
                                                <label htmlFor="phone" className="mb-2">Phone<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                                <input type="phone" className="form-control" id="phone" autoComplete="off" name="phone" data-intl-tel-input-id="0" required />
                                            </div>
                                        
                                            
                                            <div className="col-sm-12 col-lg-6 col-xxl-6  mb-3 mb-sm-4">
                                                <label htmlFor="adminemail" className="mb-2">Upload Pic/Avatar<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                                <input type="file" className="form-control" id="picture"  onChange={e => setImage(e.target.files[0])} defaultValue="" required accept="image/png, image/jpeg, image/jpg" />
                                            </div>
                                       
                                            
                                            <div className="col-sm-12 col-lg-6 col-xxl-6  mb-3 mb-sm-4">
                                                <label htmlFor="adminrating" className="mb-2">Teacher<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                                <select className="form-select" id="rating" name="account_type" onChange={getTeacherId} required >
                                                    <option value="">---</option>
                                                        { 
                                                            _.isEmpty(teacher ) ? '' : 
                                                            teacher.map((teacher_type) => {
                                                                return (<option key={teacher_type.teacher_id} value={teacher_type.teacher_id}>{teacher_type.teacher_name}</option>)
                                                            })
                                                        }

                                                </select>
                                            </div>
                                        
                                            
                                            <div className="col-sm-12 col-lg-6 col-xxl-6  mb-3 mb-sm-4">
                                                <label htmlFor="adminpassword" className="mb-2">Course<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                                <select className="form-select" name="course_id" id="courselevel" onChange={e => setCourseId(e.target.value)} required>
                                                    <option value="">---</option>
                                                        { 
                                                            _.isEmpty(course) ? '' : 
                                                            course.map((course_details) => {
                                                                return (<option key={course_details.id} value={course_details.id}>{course_details.name}</option>)
                                                            })
                                                        }
                                                       
                                                </select>
                                            </div>
                                        
                                            
                                            <div className="col-sm-12 col-lg-12 col-xxl-12">
                                                <label htmlFor="adminemail" className="mb-2">Comment<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                                <textarea className="form-control" rows="3" placeholder="Write here..." id="comment" onChange={e => setComment(e.target.value)} value={comment} required></textarea>
                                            </div>
                                                                            
                                            <div className="col-sm-12 col-lg-6 col-xxl-6  mb-3 mb-sm-4">
                                                <label htmlFor="features" className="mb-2">Features<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                                <div className="form-check form-check-inline mt-2">
                                                    <input className="form-check-input" type="radio" name="features" value="yes" id="yes" onChange={e => setFeatures(e.target.value)}/>
                                                    <label className="form-check-label" htmlFor="yes">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="features" value="no" id="no" defaultChecked onChange={e => setFeatures(e.target.value)}/>
                                                    <label className="form-check-label" htmlFor="no">
                                                        No
                                                    </label>
                                                </div>  
                                            </div>                                          
                                        </div>
                                        
                                        <div className="row mx-0">
                                            <div className="col-sm-12">
                                                <button type="button" className="btn btn-blue px-4 me-2" onClick={saveFeedback} title='Submit'>Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FeedbackCreate
