import { Link, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { fetchData,initialFormState,validateForm } from "../../components/Helper"
import Datatables, { destroyDataTable, reloadDataTable, reloadUrlDataTable } from '../../components/Datatables'
import CourseCard from "../../components/cards/Course"
import MySubjectDetails from "../../components/cards/MySubjectDetails";
import _ from "lodash";
import { Helmet } from "react-helmet";
import { createRoot } from "react-dom/client";

function AccountView() {
    const navigate = useNavigate()
    const location = useLocation();
    const userId = useParams().user_id
    const [profile, setProfile] = useState()
    const [topUpData, setTopUpData] = useState('')
    const [wallet, setWallet] = useState('')
    const [courses, setCourses] = useState({
        'wallet_amount': '',
        'description': '',
        'course_id': '',
    })
    const [transaction_details_dt] = useState({
        dt_url: `get-transactions/${userId}`,
        dt_name: 'transaction_details',

        dt_column: [
            { data: "transaction_id", name: 'transaction_id', title: "Trans ID", class:"mmw-100px"},
            { data: "name", name: 'courses.name', title: "Course" },
            { data: "amount", name: 'amount', title: "Amount (EGP)" },
            { data: "payment_type", name: 'payment_type', title: "Trans type" },
            { data: "payment_method", name: 'payment_method', title: "Trans Method" },
            { data: "description", name: 'description', title: "Description", class:"mmw-200px"},
            { data: "payment_date", name: 'payment_date', title: "Date & Time", class:"mmw-80px"}
        ],
        dt_order: [[ 6, 'desc' ]],
    })


    const [enroll_courses_dt] = useState({
        dt_url: `student-subject-list/${userId}`,
        dt_name: 'enroll_courses',

        dt_column: [
            { data: "id", name: 'id', title: "#ID"},
            { data: "name", name: 'name', title: "Course"},
            { data: "join_type", name: 'student_courses.join_type', title: "Join As"},
            { data: "created_at", name: 'student_courses.created_at', title: "Joined Date"},
            { data:"withdraw_date", name:'student_courses.withdraw_date', title:"Withdrawal Date"},
            { data:"id", title: 'Action', sortable:false, searchable:false, class:"text-center text-nowrap"}
        ],
        dt_column_defs : [
            {
                targets: 5,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(
                        <button type="button" className="table-icons-text" title="View Attendance" data-bs-toggle="modal"  onClick={() => attendanceView(userId, rowData.id)} data-bs-target="#attendanceviewModal" >View Attendance</button>                         
                    )
                },
            }
        ]
    })

    const attendanceView = (userId, courseId) => {
        let dt_attendance_view = {
        dt_url: `student-attendance-list/${userId}/${courseId}`,
        dt_name: 'student_attendance',
        dt_export_name: 'Student Attendance',
        dt_export: 'true',
        dt_column: [
            { data: "id", name: 'id', title: "#ID"},
            { data: "student_name", name: 'users.name', title: "Name"},
            { data: "classId", name: 'course_classes.class_id', width:'600px', title: "Class Id"},
            { data: "live_class", name: 'live_class', title: "Attendance"},
            { data: "recording", name: 'recording', title: "Recording"},
            { data: "status", name:'course_classes.status', title:"Status"},
            { data:"attendance_date", name:`attendances.updated_at`, title:"Date" }, 
        ],
        dt_column_defs: [
            {
                targets: 1,
                createdCell: (td, cellData, rowData, row, col) =>{
                    createRoot(td).render(<> 
                    { _.startCase(rowData.student_name)}
                    </>)
                },
            },
            {
                targets: 2,
                className:'w-50',
                createdCell: (td, cellData, rowData, row, col) =>{
                    createRoot(td).render(<> 
                    { rowData.classId ? rowData.classId : <strong className="text-dark">&mdash;</strong> }
                    </>)
                },
            },
            {
                targets: 3,
                createdCell: (td, cellData, rowData, row, col) =>{
                    createRoot(td).render(<> 
                    { rowData.live_class === "yes" ? <strong className="text-green">&#10003;</strong> : <strong className="text-danger">&#x274C;</strong> }
                    </>)
                },
            },
            {
                targets: 4,
                createdCell: (td, cellData, rowData, row, col) =>{
                    createRoot(td).render(<> 
                    { rowData.recording === "yes" ? <strong className="text-green">&#10003;</strong> : <strong className="text-danger">&#x274C;</strong> }
                    </>)
                },
            },
            {
                targets: 5,
                createdCell: (td, cellData, rowData, row, col) =>{
                    createRoot(td).render(<> 
                    { rowData.status === "over" ? <strong className="text-blue">Over</strong> : rowData.status === "active" ? <strong className="text-green">Active</strong> : <strong className="text-danger">Inactive</strong> }
                    </>)
                },
            },
            {
                targets: 6,
                className:'w-20',
                createdCell: (td, cellData, rowData, row, col) =>{
                    createRoot(td).render(<> 
                    { rowData.attendance_date === "-" ? <strong className="text-danger">&mdash;</strong> : rowData.attendance_date  }
                    </>)
                },
            },
        ],
        }
        reloadUrlDataTable(dt_attendance_view, `student-attendance-list/${userId}/${courseId}`)
    }

    useEffect(() => {
        fetchData(`get-profile/${userId}`, 'GET', '', true, false, (res) => {
            setProfile(res.data)
        }, (err) => {});

        document.getElementById("topUpModal").addEventListener('hide.bs.modal', function (e) { 
            initialFormState('payForm', topUpData)
        })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId])

    const showEnrollStudent = (user_id) =>{
        destroyDataTable(enroll_courses_dt)
        reloadDataTable(enroll_courses_dt)
        reloadUrlDataTable(enroll_courses_dt,  `student-subject-list/${user_id}`) 
    }

    const showProfile = (user_id) => {
        navigate(`/accounts/${profile.role_id === 3 ? 'teacher' : 'student'}/profile/${user_id}`)
        setProfile('')
    }

    const addWallet = (e, add = true) => {
        e.preventDefault();
        if (validateForm(e)) {
            let formData = { ...topUpData, user_id:  userId, type: add ? 'add' : 'deduce'}
            fetchData('add-topUp', 'POST', formData, true, false, (res) => {
                if(res.status) { 
                    document.querySelector("#topUpModal [data-bs-dismiss=modal]").click()
                    // $("#topUpModal").modal('hide');
                    setWallet(res.data)
                    reloadDataTable(transaction_details_dt)
                }
            }, (err) => {})
        }
    }

    const getWallet = (user_id) => {
        destroyDataTable(transaction_details_dt)
        reloadDataTable(transaction_details_dt)
        reloadUrlDataTable(transaction_details_dt,  `get-transactions/${userId}`) 

        fetchData(`get-wallet/${userId}`, 'GET', '', true, false, (res) => {
            setWallet(res.data) 
        }, (err) => {}); 
    }

    const handleInputChange = (e) => {
        document.getElementById(e.target.name).classList.remove('is-invalid'); 
        setTopUpData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const openTopUpModal = () => {
        fetchData(`student-course/${userId}`, 'GET', '', true, false, (res) => {
            if(res.status && res.data){
                setCourses(res.data)
            }
        }, (err) => {}); 
    }

    return (
        <>
            {profile ?
                <section className="admin-wrapper">
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>XTLMS | {profile.role.name} Profile</title>
                    </Helmet>
                    <div className="container-fluid">
                        <div className="row align-items-center justify-content-between">
                            <div className="col mb-2">
                                <h5 className="m-0 heading-lines pb-2 fs-20px">{profile.role.name} Profile</h5>
                            </div>
                            <div className="col-auto mb-2">
                                <Link to={`/accounts/${profile.role.slug}/list`} className="btn btn-blue mb-1 me-1" title={`${profile.role.name} List`}>{profile.role.name} List</Link>
                                {profile.role_id === 4 ? <Link to={`/accounts/teacher/courses/${profile.id}`} className="btn btn-blue mb-1 me-1" title="Courses">Courses</Link> : ''}
                                <Link to={`/accounts/${profile.role.slug}/edit/${profile.id}`} className="btn btn-dark px-3 mb-1" title="Edit">Edit</Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="d-flex flex-wrap">
                                    <div className="left-profile-new">
                                        <div class="profile-box text-center mb-3">
                                            <div class="py-4 px-3">
                                                <span class="position-relative">
                                                    <img id="file-preview" src={profile.user_details.image} alt={profile.role.name} class="img-fluid mt-2 rounded-circle h-110 border-4"  />
                                                </span>
                                                <div className="mt-4">
                                                    <div className="mb-3">
                                                        <strong className="d-block fs-14px fw-medium text-muted">{profile.role.name} Name:</strong>
                                                        <span className="fs-17px d-block">{profile.name}</span>
                                                    </div>
                                                    <div className="mb-3">
                                                        <strong className="d-block fs-14px fw-medium text-muted">Teaching Experience</strong>
                                                        <span className="fs-17px">{profile.user_details.experience} Years</span>
                                                    </div>
                                                    <div className="mb-4">
                                                        <strong className="d-block fs-14px fw-medium text-muted">Rating</strong>
                                                        <div className="star-rating">
                                                            <span style={{width:Number(profile.user_details.rating * 20)+'%'}}></span>
                                                        </div>
                                                        <div className="fs-17px">{profile.user_details.rating} out of 5</div>
                                                    </div>
                                                </div>
                                            </div>                                            
                                        </div>
                                    </div>
                                   
                                    {profile.role_id === 4 ?
                                        <div className="right-profile">                                                                                    
                                            <div className="d-flex flex-column">
                                            {
                                                (profile.user_details.brief === 'Lorem Ipsum is simply dummy text...') ? '' 
                                                    : 
                                                    <div className="mb-3">
                                                        <strong className="d-block fs-20px fw-medium">Brief Experience </strong>
                                                        <span className="fs-14px">{profile.user_details.brief}</span>
                                                    </div>
                                                    
                                            }
                                                {profile.feedbacks && profile.feedbacks.length > 0 ?
                                                    <div className="mb-3 pb-0">
                                                        <strong className="d-block fs-20px fw-medium">Feedback </strong>
                                                        {profile.feedbacks.map((feedback_details, i) => {
                                                            return (
                                                                <blockquote key={i} className="mt-1">
                                                                    <div className="row">
                                                                        <div className="col-sm-11">
                                                                            <p className="opacity-90 m-0">
                                                                                {feedback_details.description}
                                                                            </p>
                                                                            <small className="text-muted fs-12px">&mdash; {feedback_details.name}</small>
                                                                        </div>
                                                                        <div className="col-sm-1 text-end"> <Link to={{ pathname: `/feedback/edit/${feedback_details.id}`, state: { prevPath: location.pathname } }} className="btn btn-dark" title="Edit">Edit</Link></div>
                                                                    </div>
                                                                </blockquote>
                                                            )
                                                        })}
                                                    </div>
                                                    : ''
                                                }
                                                <div>
                                                    <strong className="d-block fs-20px fw-medium">Taught Courses </strong>
                                                    <div className="cources-section pt-3" >
                                                        <div className="item_list_two" >
                                                            { profile.role_id === 4 ? <CourseCard course_details={profile.course} joinDisabled={true} /> : '' }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                          
                                        </div>
                                        :
                                        <div className="right-profile">
                                            <div className="card-body card border-0 shadow-sm rounded-10">
                                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link text-blue active fw-medium" title={`${profile.role.name} Profile`} data-bs-toggle="tab" data-bs-target="#profileTab" type="button" role="tab">
                                                            {profile.role.name} Profile
                                                        </button>
                                                    </li>
                                                    {profile.role_id === 3 || profile.role_id === 5 ?
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link text-dark fw-medium" title={`Linked ${profile.role_id === 3 ? 'Teachers' : 'Students'}`} data-bs-toggle="tab" data-bs-target="#linkToTab" type="button" role="tab">
                                                                Linked {profile.role_id === 3 ? 'Teachers' : 'Students'}
                                                            </button>
                                                        </li>
                                                        : ''}
                                                    {profile.role_id === 6 ? <>
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link text-dark fw-medium" data-bs-toggle="tab" title="Performance" data-bs-target="#performanceBox" type="button" role="tab">
                                                                Performance
                                                            </button>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link text-dark fw-medium" title="Enrolled Courses" data-bs-toggle="tab" data-bs-target="#coursesBox" type="button" role="tab"  onClick={() => showEnrollStudent(profile.id)}>
                                                                Enrolled Courses
                                                            </button>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link text-dark fw-medium" title="Wallet" data-bs-toggle="tab" data-bs-target="#walletBox" type="button" role="tab" onClick={() => getWallet(profile.id) }>
                                                                Wallet
                                                            </button>
                                                        </li>
                                                    </> : ''}
                                                </ul>
                                                <div className="tab-content border border-top-0">
                                                    <div className="tab-pane fade show active" id="profileTab" role="tabpanel">
                                                        <div className="d-sm-flex align-items-start py-3">
                                                            {/* <div className="ratio ratio-1x1 rounded-10 overflow-hidden mb-3 mmw-180px">
                                                                <img src={profile.user_details.image} alt={profile.role.name} className="img-cover" />
                                                            </div> */}
                                                            <div className="ps-sm-4">
                                                                <h4 className="pb-2 m-0 fw-medium">{profile.name}</h4>
                                                                {
                                                                    (profile.user_details.brief === 'Lorem Ipsum is simply dummy text...') ? ''
                                                                    :
                                                                    <p className="opacity-90 mb-3">
                                                                        {profile.user_details.brief}
                                                                    </p>
                                                                }
                                                                <div className="mb-3">
                                                                    <strong className="text-dark d-block fs-15px fw-bold">Email</strong>
                                                                    <span className="opacity-90">{profile.email}</span>
                                                                </div>

                                                                <div className="mb-3">
                                                                    <strong className="text-dark d-block fs-15px fw-bold">Mobile Number</strong>
                                                                    <span className="opacity-90">{profile.user_details.phone}</span>
                                                                </div>
                                                                {profile.role_id === 3 || profile.role_id === 5 ?
                                                                    <div className="mb-4">
                                                                        <strong className="text-dark d-block fs-15px fw-bold">{profile.role_id === 3 ? 'Number of link to teacher' : 'Number of link to student'}</strong>
                                                                        <span className="btn btn-secondary btn-sm pe-none px-3 rounded-pill mt-1">{profile.link_count}</span>
                                                                    </div>
                                                                    : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="performanceBox" role="tabpanel">
                                                        <MySubjectDetails student_id={userId} type="performance" />
                                                    </div>

                                                    <div className="tab-pane fade" id="coursesBox" role="tabpanel">
                                                        <div className="table-responsive p-1 pt-4">
                                                            <Datatables dt_name="enroll_courses" id={userId}/>
                                                        </div>
                                                    </div>

                                                    <div className="tab-pane fade p-3" id="walletBox" role="tabpanel">
                                                        <div className="row mx-0 bg-dark py-3 rounded align-items-center fw-light position-relative overflow-hidden text-white fs-4">
                                                            <img src="/images/courses-bg.png" className="position-absolute top-0 opacity-25 zindex-0 pointer-none" alt="Topup" />
                                                            <div className="col-sm-12 col-lg-4 zindex-1 position-relative">
                                                                Wallet Balance
                                                            </div>
                                                            <div className="col-6 col-lg-4 position-relative">
                                                                EGP { wallet && wallet.wallet_amount ? wallet.wallet_amount : '0'}
                                                            </div>
                                                            <div className="col-6 col-lg-4 text-end zindex-1 position-relative">
                                                            <button type="button" className="btn btn-blue px-4" data-bs-target="#topUpModal" data-bs-toggle="modal" title="Top-up" onClick={openTopUpModal}>Top-up</button>
                                                            </div>
                                                        </div>
                                                        <div className="table-responsive p-1 pt-4">
                                                            <Datatables dt_name="transaction_details" />
                                                        </div>
                                                    </div>

                                                    {profile.role_id === 3 || profile.role_id === 5 ?
                                                        <div className="tab-pane fade p-3" id="linkToTab" role="tabpanel">
                                                            <div className="table-responsive">
                                                                <table className="table align-middle mb-0 table-lasttd-end">
                                                                    <thead className="bg-light">
                                                                        <tr>
                                                                            <th>Name</th>
                                                                            <th>Join Date</th>
                                                                            <th>Status</th>
                                                                            <th>Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {profile.user_list ? profile.user_list.map((user, i) => (
                                                                            <tr key={i}>
                                                                                <td className="opacity-90">{user.name}</td>
                                                                                <td className="opacity-90">{user.join_date}</td>
                                                                                <td className="opacity-90">
                                                                                    {user.status === "active" ? <span className="badge bg-success">Active</span> : ''}
                                                                                    {user.status === "inactive" ? <span className="badge bg-danger">Inactive</span> : ''}
                                                                                </td>
                                                                                <td>
                                                                                    <button type="button" className="btn btn-primary btn-sm px-3" title="View Profile" onClick={() => showProfile(user.id)}>View</button>
                                                                                </td>
                                                                            </tr>
                                                                        )) : ''}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </section>
                : ''}
            {/* Top Up Modal */}
            <div className="modal fade" id="topUpModal" tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Top Up/Deduce Your Wallet</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-sm-4">
                            <form className="needs-validation" id="payForm" noValidate>

                                <div className="mb-3">
                                    <label htmlFor="course_id" className="form-label text-muted mb-1">Course<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                    <div className="input-group">
                                        <select className="form-select" name="course_id" id="course_id" required  onChange={handleInputChange}>
                                            <option value="">- select -</option>
                                            {
                                                courses && courses.length > 0 && courses.map((course, key) => {
                                                    return (<option key={key} value={course.id}>{course.name}</option>)
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="wallet_amount" className="form-label text-muted mb-1">Amount<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                    <div className="input-group">
                                        <span className="input-group-text justify-content-center">EGP</span>
                                        <input type="number" className="form-control" id="wallet_amount" min="1" name="wallet_amount" placeholder="0" onChange={handleInputChange} required />
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="description" className="form-label text-muted mb-1">Description</label>
                                    <div className="input-group">
                                        <textarea rows="3" className="form-control" id="description" name="description" onChange={handleInputChange}></textarea>
                                    </div>
                                </div>
                                <div className="text-end">
                                    <button type="button" className="btn btn-blue px-4 me-2 mb-2"  onClick={addWallet}>Add Amount</button>
                                    <button type="button" className="btn btn-warning px-4 mb-2"  onClick={(e) => addWallet(e, false)}>Deduce Amount</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="attendanceviewModal" tabIndex="-1">
                <div className="modal-dialog modal-dialog-scrollable modal-xl">
                    <div className="modal-content border-0 shadow">
                        <div className="modal-header bg-light">
                            <h5 className="modal-title">Attendance View</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-sm-4">
                            <div className="table-responsive p-1">
                            <Datatables dt_name="student_attendance"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AccountView
