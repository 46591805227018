export default function Icon() {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" className="d-none">
            <symbol id="bi_egp" viewBox="0 0 512 512">
                <path d="M124.6,281.78H51.24v47.33h81.94v37H6V166.71H129v37H51.24V245.1H124.6Z" />
                <path d="M330.49,356.91c-13.9,4.74-40.23,11.24-66.56,11.24-36.38,0-62.71-9.17-81.05-26.91-18.34-17.16-28.4-43.19-28.1-72.48.29-66.26,48.51-104.13,113.89-104.13,25.73,0,45.55,5,55.31,9.77l-9.46,36.09c-10.95-4.74-24.55-8.58-46.44-8.58-37.57,0-66,21.3-66,64.48,0,41.12,25.73,65.38,62.71,65.38,10.36,0,18.64-1.18,22.19-3V287.1H256.24V251.9h74.25Z" />
                <path d="M365.7,169.37c13.9-2.37,33.42-4.14,60.93-4.14,27.81,0,47.63,5.32,60.94,16,12.72,10.06,21.3,26.62,21.3,46.15s-6.51,36.09-18.34,47.33c-15.38,14.49-38.16,21-64.78,21a113.92,113.92,0,0,1-15.39-.89v71.29H365.7Zm44.66,90.52c3.85.88,8.58,1.18,15.09,1.18,24,0,38.75-12.13,38.75-32.54,0-18.34-12.72-29.29-35.2-29.29-9.17,0-15.38.89-18.64,1.78Z" />
            </symbol> 
            </svg>
        </>
    )
}