import React, { useState, useEffect } from 'react'
import { fetchData } from '../Helper';
import Pagination from "../Pagination";
import _, { isEmpty } from 'lodash';

function MySubjectDetails(props) {
    const [subject, setSubject] = useState('');
    const [listData, setListData] = useState('');
    const [couserId, setCouserId] = useState();
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        if(props.student_id)
        fetchData(`subject-list?student_id=${props.student_id}&page=${currentPage}`, 'GET', '', true, false, (res) => {
            setSubject(res.data)
        }, (err) => {});
    }, [currentPage, props.student_id]) 
    
    const handlePageClick = (data) => {
        setCurrentPage(data.selected >= 0 ? data.selected + 1 : 0)
	}
    const openModal = (course_id, page = 1) => {
        setCouserId(course_id)
        fetchData(`subject-type-list?id=${course_id}&type=${props.type}&user_id=${props.student_id}&page=${page}`, 'GET', '', true, false, (res) => {
            setListData(res.data)
        }, (err) => {});
    }
    const handleModalPageClick = (data) => {
        openModal(couserId, data.selected >= 0 ? data.selected + 1 : 0)
	}

    return ( 
        <>
        <section className="cources-section">
            <div className=" col-sm-12">
                <div className="card-body pb-0">
                    {
                        subject ? isEmpty(subject.data) ? <div className="alert alert-danger text-center">No data available in {props.type ? props.type : ''} section.</div> : subject.data.map((item, index) => {
                            return (
                                <button  key={index} className="text-decoration-none border rounded px-3 py-2 w-100 link-effect fw-medium d-flex align-items-center bg-light mb-2"  data-bs-toggle="modal" data-bs-target={`#${props.type}Modal`} onClick={() => openModal(item.id)}>
                                    {`${item.display_name}`}
                                    <i className="bi-arrow-right-circle-fill bi-3x ms-auto"></i>
                                </button>
                            )
                        }) : ''
                    }
                    <Pagination paginate_data={subject} side='end' onPageChange={handlePageClick}/>
                </div>
            </div>
        </section>


        <div className="modal fade" id={`${props.type}Modal`} data-bs-backdrop="static" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{listData[1] ? listData[1] : ''}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss='modal' aria-label="Close" title='Close'></button>
                        </div>
                        <div className="modal-body">
                            <div className="table-responsive">
                                <table className="table align-middle table-lasttd-end mb-0">
                                    <thead className="bg-light">
                                        <tr>
                                            <th className="border-bottom">Date</th>
                                            <th className="border-bottom">Name</th>
                                            <th className="border-bottom">Type</th>
                                            <th className="border-bottom">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { listData && listData[0].data && !_.isEmpty(listData[0].data) ? listData[0].data.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.date}</td>
                                                    <td>{_.capitalize(item.name)}</td>
                                                    <td>{item.module_type}</td>
                                                    <td>
                                                        <div className="ratio ratio-1x1 mmw-50px text-blue d-inline-block">
                                                            <svg viewBox="0 0 36 36">
                                                                <path stroke-width="3" fill="none" stroke={item.percent === 0 ? '#f66969' : '#eee'} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                                <path stroke-linecap="round" fill="none" stroke={item.color} stroke-width="3" stroke-dasharray={`${item.percent}, 100`} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                                <text x="18" y="21.5" className="percentage fw-medium" fill={item.color} font-size={item.percent === 0 ? '1rem' : '0.55rem'} font-family="" text-align="center" text-anchor="middle">{ item.percent === 0 ? '!' : _.isInteger(item.percent) ? item.percent : item.percent.toFixed(2)+ '%' }</text>
                                                            </svg>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }) : <tr><td colSpan="5" className="text-danger text-center">No data available</td></tr>}
                                    </tbody>
                                </table>
                                <Pagination paginate_data={listData ? listData[0] : ''} side='end' onPageChange={handleModalPageClick}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        </>
    )
}

export default MySubjectDetails
