import { useState } from 'react';
import { fetchData } from "../components/Helper"

function TimeZone(props){

    const [selectedTimezone, setSelectedTimezone] = useState(props.time_zone);
    const timezoneOptions = [
      { country: 'Cairo (GMT +3) (Summer time set timezone)', timezone: 'GMT+3' },
      { country: 'Cairo (GMT +2)', timezone: 'GMT+2' },
    ];
      const handleTimezoneChange = (event) => {
        setSelectedTimezone(event.target.value);
          const newTimeZone = event.target.value;
          fetchData(`set-timezone`, 'POST', {timezone: newTimeZone,id:1}, true, false, (res) => {
          if(res && res.data){
            setSelectedTimezone(res.data.timezone);
          }
          }, (err) => {})
      };

    return (
        <div className="mb-3 row mx-0">
            <label className="col-sm-3 col-xxl-2 col-form-label text-xxl-end">Select Timezone:</label>
              <div className='className="col-sm-9 col-lg-6 col-xxl-5'>
                <select value={selectedTimezone} onChange={handleTimezoneChange} className='form-control'>
                <option value="">Select a timezone</option>
                    {timezoneOptions.map((option, index) => (
                        <option key={index} value={option.timezone}>{option.country}</option>
                    ))}
                </select>
              </div>
        </div>
    );
}

export default TimeZone
