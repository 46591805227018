import $ from 'jquery';
import 'daterangepicker/daterangepicker'
import 'daterangepicker/daterangepicker.css'
import { useEffect } from 'react';

function DateRangeFilter(props){
    useEffect(() => {
        $('input[name="'+props.name+'"]').daterangepicker({
            autoApply: true,
            minYear: 2021,
            autoUpdateInput: props.disableUpdateInput === false ? false : true,
            maxDate: props.maxDate ? props.maxDate : '',
            opens: props.opens ? props.opens : 'left',
            startDate: props.startDate ? props.startDate : new Date(),
            endDate: props.endDate ? props.endDate : new Date(),
            locale: {
                format: 'MMM DD, YYYY'
            }
        })

        $('input[name="'+props.name+'"]').on('apply.daterangepicker', function(ev, picker) {
            $('input[name="'+props.name+'"]').val(picker.startDate.format('MMM DD, YYYY')+' - '+picker.endDate.format('MMM DD, YYYY'))
            props.getDateRange(picker.startDate.format('YYYY-MM-DD'), picker.endDate.format('YYYY-MM-DD'), localStorage.getItem('filter_id'))
        });
        $('input[name="'+props.name+'"]').on('show.daterangepicker', function(ev, picker) {
            $('td').removeClass('in-range');
            $('td').removeClass('active');
            $('.daterangepicker').addClass('shadow-lg');
        })
    }, [props])

    return (<>
            <div className='d-flex'>
                <button title='Refresh' className='btn btn-sm me-2' onClick={props.reload ? props.reload : ''}>
                    <i className='bi-arrow-clockwise bi-7x d-inline-block'></i>
                </button>
                <input type="text" autoComplete='off' name={props.name} defaultValue={props.daterange} className={props.className}/>
            </div>
        </>
    )
}

export default DateRangeFilter