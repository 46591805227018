import { createRoot } from "react-dom/client";
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import Datatables, { reloadUrlDataTable } from '../../components/Datatables';
import { ModalSection } from '../../components/Helper';
import $ from 'jquery';

function  Autofi(props) {
    const listType = useParams().list_type
    const [previousModalId, setPreviousModalId] = useState(0)

    const [autofi_dt] = useState({
        dt_url: `notification-list/autofi`,
        dt_name: 'autofi_table',
        dt_column: [
            { data:"id", name:'id', title: "#ID"},
            { data:"name", name:"courses.name", title:"Course Name"},
            { data:"subject", name:"subject", title:"Last Notification"},
            { data:"time", name:'time', title:"Time", searchable:false,},
            { data:"id", title: 'Action', sortable:false, searchable:false, class:"text-center text-nowrap", width:160}
        ],
        dt_column_defs: [
            {
                targets: 4,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(<>
                        <button type="button" className="table-icons-text" title="View" data-bs-target="#autofiDetails" data-bs-toggle="modal" onClick={() => autofiDetails(rowData.id, 'autofi')}>
                            View
                        </button>
                    </>)
                }
            },
        ]
    })

    useEffect(() => {
        if(listType === 'autofi'){
            reloadUrlDataTable(autofi_dt, `notification-list/autofi`)
        }
    }, [listType, autofi_dt])
    
    const autofiDetails = (id, type) => {
        setPreviousModalId(id)
        let dt_notification_details = {
            dt_url: `notification-details-list/${id}/${type}`,
            dt_name: id ? 'autofi_details' : 'manufi_table',
            dt_column: [
                { data:"id", name:'id', title: "#No."},
                { data:"time", name:'time', title:"Time", searchable:false,},
                { data:"subject", name:'subject', title: "Title", visible:id ? true : false},
                { data:"name", name:'users.name', title: "Recipients", searchable:false,},
                { data:"message", name:'message', title: "English Message"},
                { data:"arabic_message", name:'arabic_message', title: "Arabic Message"},
                { data:"id", title: 'Action', sortable:false, searchable:false, class:"text-center text-nowrap", width:160}
            ],
            dt_column_defs: [
                {
                    targets: 3,
                    createdCell: (td, cellData, rowData, row, col) => {
                        createRoot(td).render(<>
                        {rowData.total_recipients === 1 && <span>{rowData.first_recipients}</span>}
                        {rowData.total_recipients === 2 && <span>{rowData.first_recipients}, {rowData.last_recipients}</span>}
                        {rowData.total_recipients > 2 && <span>{rowData.first_recipients}, {rowData.last_recipients}, ...</span>}
                        </>)
                    }
                },
                {
                    targets: 5,
                    createdCell: (td, cellData, rowData, row, col) => {
                        createRoot(td).render(<>
                            <span dir='rtl'>{rowData.arabic_message}</span>
                        </>)
                    }
                },
                {
                    targets: 6,
                    createdCell: (td, cellData, rowData, row, col) => {
                        createRoot(td).render(<>
                            <button type="button" className="table-icons-text" title="View" data-bs-target="#autofiRecipientsList" data-bs-toggle="modal" onClick={() => autofiRecipientsList(rowData.notification_identifier)}>
                                View
                            </button>
                        </>)
                    }
                },
            ]
        };
        reloadUrlDataTable(dt_notification_details, `notification-details-list/${id}/${type}`)
    }

    const autofiRecipientsList = (id) => {
        let dt_recipients_list_details = {
            dt_url: `recipient-list/${id}`,
            dt_name: 'autofi_recipients_list',
            dt_column: [
                { data:"id", name:'id', title: "#ID"},
                { data:"name", name:'users.name', title: "Name"},
                { data:"email", name:'users.email', title: "Email"},
                { data:"phone", name:'user_details.phone', title: "Phone Number"},
                { data:"user_type", name:'roles.name', title: "User Type"},
                { data:"status", name:'status', title: "Delivery Status"},
            ],
        };
        reloadUrlDataTable(dt_recipients_list_details, `recipient-list/${id}`)
    }
    
    const showPreviousModal = (id, type) => {
        if(previousModalId){
            $(id).modal('show'); 
            autofiDetails(previousModalId, type)
        }
    }

    return(
        <>
            <div className="table-responsive p-1">
                <Datatables dt_name="autofi_table"/>
            </div>

            <ModalSection title="Notification Details" modal_id="autofiDetails" size="xl">
                <div className="table-responsive p-1">
                    <Datatables dt_name="autofi_details"/>
                </div>
            </ModalSection>

            <ModalSection title="Recipients List" modal_id="autofiRecipientsList" size="xl" func={() => showPreviousModal('#autofiDetails', 'autofi')}>
                <div className="table-responsive p-1">
                    <Datatables dt_name="autofi_recipients_list"/>
                </div>
            </ModalSection>

        </>
    )
}

export default Autofi
