import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './assets/css/style.css';
import './assets/css/validation.css';
import Routing from './components/Routing';
import reportWebVitals from './reportWebVitals';
import Icons from './components/Icons'


// ReactDOM.render(
//   <React.StrictMode>
//         <BrowserRouter>
//             <Switch>
//                 <Routing/>
//             </Switch>
//         </BrowserRouter>
//         <Icons/>
//         <div className="position-fixed top-0 end-0 p-2 toast-end pointer-none" style={{'zIndex': '1111'}} id="toastContainer"></div>
//   </React.StrictMode>,
//   document.getElementById('root')
// );


const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
    <>
      <Router>
        <Routing />
      </Router>
      <Icons/>
      <div className="position-fixed top-0 end-0 p-2 toast-end pointer-none" style={{ 'zIndex': '1111' }} id="toastContainer"></div>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
