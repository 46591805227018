import {scrollbarSetToTop} from  "../components/Helper"
import { useEffect } from "react";
import { Helmet } from "react-helmet";

function BusinessModel(){
    useEffect(() => {
        scrollbarSetToTop(); 
   }, [])
    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>XTLMS | (Online Teaching Platform)</title>
        </Helmet>
        <section className="bg-blue py-5 position-relative overflow-hidden breadcrumb-bar">
            <img src="images/courses-bg.png" className="position-absolute top-0 opacity-25 zindex-0 pointer-none" alt="XTLMS"/>
            <div className="container position-relative zindex-2">
                <div className="row">
                    <div className="col-lg-12">
                        <h2 className="m-0 heading-lines pb-2 fs-20px pt-3">XT LMS (Online Teaching Platform)</h2>
                    </div>
                </div>
            </div>
        </section>

        <section className="about-section py-5">
            <div className="container py-sm-4">
                <div className="row">
                    <div className="col-sm-12 align-justify">
                            <h2 className="fw-bold">Accounts</h2>

                            <h5 className="fw-bold pt-3 ms-3">Super Admin:</h5>
                            <p className="ms-5">This is a single type of account know as the Super Admin by means it’s controlled by head trusted admin to be able to view all data being used on website and to control the Admins and Teachers Accounts.</p>

                            <h5 className="fw-bold ms-3">Admin:</h5>
                            <p className="ms-5">This is type of account created by Super Admin for admins working in educational department of “XT LMS Limited”, where this admin is given limited access from Super Admin to due regular tasks such as creating courses, adding classes, and adding schedules.</p>

                            <h5 className="fw-bold ms-3">Teacher:</h5>
                            <p className="ms-5">This is type of account created by Super Admin or Admin given permission by Super Admin, this account is created for teachers who are already verified by our team and works for teaching courses we provide, the teacher has access to the LMS system to create assignments, quizzes, upload notes and joining live classes.</p>

                            <h5 className="fw-bold ms-3">Assistant:</h5>
                            <p className="ms-5">This is type of account created by Super Admin or Admin given permission by Super Admin, this account is created for teacher’s assistants having access to mark assignments, quizzes, upload notes and joining live classes.</p>

                            <h5 className="fw-bold ms-3">Parent:</h5>
                            <p className="ms-5">This is type of account signed up by student’s parent, the purpose of this account is to give access to parents on their student’s performance, class schedule, quizzes and assignments, also to top up the student wallet as well.</p>

                            <h5 className="fw-bold ms-3">Student:</h5>
                            <p className="ms-5">This is a type of account is signed up by student, the purpose of this account is to use to join live classes, get assignments and quizzes and resubmitting them for correction, watching recording of the live classes if missed, to top up wallet as well.</p>

                            <h2 className="fw-bold pt-3">Courses</h2>
                            <p className="ms-5">Courses provided on our website are all monitored and controlled by us, there are live courses where the teacher and student agree the course timings and meet 2-3 times a week, for these classes we use the Zoom API integration, where students join the Zoom classes in our website without been directed to external link.</p>

                            <h2 className="fw-bold pt-3">Payments</h2>

                            <p className="ms-5">For payments we relay on wallet model, where the student needs to top up balance and then when the student gets to attend any class, the system shows a confirmation message that X amount will be deduced from the wallet if you attend this class, and if the student agrees, gets allowed to join class and the amount gets deduced from his/her wallet. For top up we rely on 2 big payments solution 1- Stripe which is known worldwide, 2- Fawry which is the largest payments solution company in Egypt and in the Middle East as well.</p>

                            <h2 className="fw-bold pt-3">Notifications</h2>

                            <h5 className="fw-bold ms-3 pt-3">How it works?</h5>
                            <p className="ms-5">We have built up a notification system that have the purpose of sending only important notifications to students & parents, this are notifications to remind the student of the class, to let the student know that the teacher has uploaded an assignment, quiz or even notes, also this should keep the parents on track as they can always know how their student’s are performing and when they have quizzes, when are the deadline of the assignments and the grade they have been awarded</p>

                            <h5 className="fw-bold ms-3">Why have we chosen WhatsApp API Integration?</h5>
                            <p className="ms-5">So, we have though that it’s much better to use the WhatsApp Business API Integration for this purpose because if we relay on emails most probably the parents won’t look into their email, or they might have a look on it from time to other. On the other hand, all our student’s parents are using WhatsApp as well as more often and at least they may check their WhatsApp messages twice an hour, this means that using WhatsApp Business API for sending course related notifications will meet the needs of our system as well the needs of parents to help them keeping eyes on their students always. All this with full respect to the WhatsApp Business API privacy and policy, usage & commercial laws set by WhatsApp Business and Meta.</p>

                            <h2 className="fw-bold pt-3">Example</h2>
                            <p className="ms-5">First for the student to be able to join our online course, the student needs first to sign up for an account as well as a parent account, afterwards both the student and the parent needs to verify their email addresses to be able to login to their accounts. After successfully verifying their account the student can browse all courses through Courses web page, where for each course the information are provided such as Course name, course level, course description, course timings, course teacher & the course price, if the student is interested in any of the courses then he/she needs to click on join course and choose join option (per class or per course), then to click join now, by doing this the student is now successfully enrolled to a course, now to start attending the first class the student needs to top up balance, to do this the student have the choice to top up through either Stripe or Fawry, after the student has done the top up the student will go to My Subjects >> Selected Course >> and launch class, the amount of the class will get deduced from his/her wallet and the class will get launched. Afterwards, the student and the parent will receive important automated notification whenever there’s a class, assignment uploaded, quiz corrected , and so.</p>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default BusinessModel