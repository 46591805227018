import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { createRoot } from 'react-dom/client';
import { useState } from "react";
import Datatables, { initDataTable, reloadDataTable } from '../../components/Datatables';
import { fetchData } from "../../components/Helper";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function TelegramLog() {
    const navigate = useNavigate()
    const [dt] = useState({
        dt_url: `get-telegram-log`,
        dt_name: 'get-telegram-log',
        dt_column: [
            { data:"id", name:'id', title: "#ID"},
            { data:"error_message", name:'error_message', title:"Message", class:"text-nowrap"},
            { data:"user.name", name:'user.name', title:"Name", class:"text-nowrap"},
            { data:"user.role.name", name:'user.role.name', title:"Role Type", class:"text-nowrap"},
            { data:"telegram_chat_id", name:'telegram_chat_id', title:"Telegram Id", class:"text-nowrap"},
            { data:"notification_type", name:'notification_type', title:"Type"}, 
        ],
    })
    useEffect(() => {
        initDataTable(dt)
    }, [dt])
   
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>XTLMS | telegram-log</title>
        </Helmet>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h5 className="m-0 heading-lines pb-2 fs-20px">Telegram Log</h5>
                        </div>
                        {/* <div className="col-auto mb-2">
                            <Link to="/feedback/create" className="btn btn-blue mb-1" title='Add Feedback'>Add Feedback</Link>
                        </div> */}
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body">
                                    <div className="table-responsive p-1"> 
                                      <Datatables dt_name="get-telegram-log"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TelegramLog
