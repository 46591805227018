import { createRoot } from 'react-dom/client';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from "react";
import DataTables, { reloadDataTable, reloadUrlDataTable } from '../../components/Datatables';
import { Helmet } from 'react-helmet';

function ActivityList(){
    const navigate = useNavigate()
    const activity_type = useParams().list_type
   
    const [dt_account] = useState({
        dt_url: `activity-list/account`,
        dt_name: 'account_activity',
        dt_column: [
            { data:"id", name:'users.id', title: "#ID"},
            { data:"name", name:'users.name', title:"Name"},
            { data:"email", name:'users.email', title:"Email"},
            { data:"role", name:'roles.name', title:"Account Type"},
            { data:"activity_created_at", name:'activity_logs.created_at', title:"Last Activity"},
            { data:"id", title:"Action", sortable:false, searchable:false, class:"text-center", width:160}
        ],
        dt_column_defs: [
            {
                targets: 5,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(<>
                        <button type="button" className="table-icons-text" title="View" data-bs-target="#viewAccountModal" data-bs-toggle="modal" onClick={() => showActivityDetails(rowData.id, rowData.role)} >
                            View
                        </button>                    
                    </>)
                },
            },
        ]
    })

    const [dt_course] = useState({
        dt_url: `activity-list/course`,
        dt_name: 'course_activity',
        dt_column: [
            { data:"id", name:'courses.id', title: "#ID"},
            { data:"name", name:'courses.name', title:"Course Name"},
            { data:"teacher_name", name:'users.name', title:"Teacher"},
            { data:"activity_created_at", name:'activity_logs.created_at', title:"Last Activity"},
            { data:"id", title:"Action", sortable:false, searchable:false, class:"text-nowrap text-center", width:160}
        ],
        dt_column_defs: [
            {
                targets: 4,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(<>
                        <button type="button" className="table-icons-text" title="View" data-bs-target="#viewCourseModal" data-bs-toggle="modal" onClick={() => showActivityDetails(rowData.id, 'course')} >
                            View
                        </button>                    
                    </>)
                },
            },
        ]
    })

    useEffect(() => {
        if(activity_type === 'course'){
            reloadDataTable(dt_course)
        }else{
            reloadDataTable(dt_account)
        }
    }, [activity_type, dt_course, dt_account])

    const handleActivityList = (type) => {
        navigate(`/activity/${type}/list`)
    }

    const showActivityDetails = (id, type) => {
        if(type === 'course') {
            let dt_course_details = {
                dt_url: `activity-detail-list/${id}?type=${type}`,
                dt_name: 'course_detail_activity',
                dt_column: [
                    { data:"id", name:'id', title: "#ID"},
                    { data:"name", name:'users.name', title: "Name"},
                    { data:"email", name:'users.email', title: "Email"},
                    { data:"path_name", name:'path_name', title: "Path"},
                    { data:"message", name:'message', title: "Description", class:"mmw-200px"},
                    { data:"created_at", name:'created_at', title: "Last Activity"},
                ]
            };
            reloadUrlDataTable(dt_course_details, `activity-detail-list/${id}?type=${type}`)
        }else{
            let dt_account_details = {
                dt_url: `activity-detail-list/${id}?type=${type}`,
                dt_name: 'account_detail_activity',
                dt_column: [
                    { data:"id", name:'id', title: "#ID", class:"mmw-36px"},
                    { data:"message", name:'message', title: "Description", class:"mmw-200px"},
                    { data:"action_perform_id", name:'action_perform_id', title: "Action Perform for", class:"mmw-100px"},
                    { data:"created_at", name:'created_at', title: "Last Activity", class:"mmw-100px"},
                ],
            };
            reloadUrlDataTable(dt_account_details, `activity-detail-list/${id}?type=${type}`)
        }
    }

    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>XTLMS | Activity Log</title>
        </Helmet>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h5 className="m-0 heading-lines pb-2 fs-20px">Activity Log</h5>
                        </div>
                        <div className="col-auto mb-2 opacity-0">
                            <button type="button" className="btn btn-blue mb-1">&nbsp;</button>
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className={activity_type === 'account' ? 'nav-link text-dark active fw-medium' : 'nav-link text-dark fw-medium'} data-bs-toggle="tab" data-bs-target="#accountbox" type="button" role="tab" onClick={() => handleActivityList('account')} title='Accounts'>Accounts</button>
                                        </li>
                                        
                                        <li className="nav-item" role="presentation">
                                            <button className={activity_type === 'course' ? 'nav-link text-dark active fw-medium' : 'nav-link text-dark fw-medium'} data-bs-toggle="tab" data-bs-target="#coursebox" type="button" role="tab" onClick={() => handleActivityList('course')} title='Courses'>Courses</button>
                                        </li>
                                    </ul>
                                    <div className="tab-content border border-top-0">
                                        <div className={activity_type === 'account' ? 'tab-pane fade p-3 show active' : 'tab-pane fade p-3 show'} id="#accountbox" role="tabpanel">
                                            <div className="table-responsive p-1">
                                                <DataTables dt_name="account_activity"/>
                                            </div>
                                        </div>

                                        <div className={activity_type === 'course' ? 'tab-pane fade p-3 show active' : 'tab-pane fade p-3 show'} id="#coursebox" role="tabpanel">
                                            <div className="table-responsive p-1">
                                                <DataTables dt_name="course_activity"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="modal fade" id={activity_type === 'course' ? "viewCourseModal" : "viewAccountModal" } tabIndex="-1">
                <div className="modal-dialog modal-dialog-scrollable modal-xl">
                    <div className="modal-content border-0 shadow">
                        <div className="modal-header bg-light">
                            <h5 className="modal-title"> Activity Details</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" title='Close'></button>
                        </div>
                    
                        <div className="modal-body p-sm-4">
                            <div className={activity_type === 'course' ? "table-responsive p-1" : "table-responsive p-1 d-none"}>
                                <DataTables dt_name="course_detail_activity"/>
                            </div>
                            <div className={activity_type === 'course' ? "table-responsive p-1 d-none" : "table-responsive p-1"}>
                                <DataTables dt_name="account_detail_activity"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ActivityList
