import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { useLocation } from "react-router-dom";
import { fetchData, validateForm, initialFormState } from "../../components/Helper"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import _ from 'lodash';

function Timing(props) {
    const location = useLocation()
    const courseId = useParams().course_id
    const [opPerform, setOpPerform] = useState('add')
    const [disableField, setDisableField] = useState(false)
    const [start_class_time, setStart_class_time] = useState(new Date());
    const [end_class_time, setEnd_class_time] = useState(new Date());
    const [recordingData] = useState({
        'recording_name': '',
        'class_id': '',
    });
    const [timing_name, setTiming_name] = useState();
    const [timing_day, setTiming_day] = useState();
    const [timing_id, setTiming_id] = useState()
    const [timingData, setTimingData] = useState()
   
    const initialState = (type) => {
        initialFormState("timingForm", recordingData)
        setOpPerform('add')
        setDisableField(false)
        setStart_class_time(new Date())
        setEnd_class_time(new Date())
        setTiming_name('')
        
    }


    const handleChangeStatus = (status, id, type = false) => {
            fetchData(`delete-timing`, 'POST', {'id': id, 'course_id': courseId, 'pathname': location.pathname}, true, false, (res) => {
                if(res.status) {
                    fetchData(`get-timing/${courseId}`, 'GET', '', true, false, (res) => { 
                        if(res.status){
                            setTimingData(res.data)
                        }
                    }, (err) => {})
                }
            }, (err) => {})
     

    }

    const saveData = (status, modal_type) => {
            let timing_data = {
                'timing_name': timing_name, 
                'start_time' : start_class_time, 
                'end_time' : end_class_time, 
                'course_id' : courseId, 
                'day' : timing_day,
                'pathname': location.pathname
            }
            fetchData('add-timing', 'POST', timing_data, true, false, (res) => {
                if(res.status){
                    document.getElementById('timingModal').click()
                    openFormModal('timing')
                }
            }, (err) => {})
    }

    const updateData = (status, modal_type) => {
            let timing_data = {
                'timing_name': timing_name, 
                'start_time' : start_class_time, 
                'end_time' : end_class_time, 
                'day' : timing_day,
                'pathname': location.pathname
            }
            fetchData(`timing-update/${timing_id}`, 'POST', timing_data, true, false, (res) => { 
                if(res.status) {
                    document.getElementById('timingModal').click()
                    openFormModal('timing')
                }
            }, (err) => {})
       
        
    }

    const openEditClassModal = (id, set, type='') => {
            initialState('timing')
            openFormModal('timing')
            setDisableField(set)
            fetchData(`timing-update/${id}`, 'GET', '', true, false, (res) => { 
                if(res.status){
                    setStart_class_time(new Date(res.data.start_time))
                    setEnd_class_time(new Date(res.data.end_time))
                    setTiming_day(res.data.day)
                    setTiming_name(res.data.name)
                    setTiming_id(res.data.id)
                    setOpPerform('update')
                }
            }, (err) => {})
        
    }  
    const handelTimingSubmitForm = (e) => {
        if(validateForm(e, 'timingForm')){
            if(opPerform !== 'add') {
                updateData(' ', 'timing')
            }else {
                saveData('active', 'timing')
            }
        }
    }

    const openFormModal = (modal_name) => {
        setTiming_day('')
        initialState(modal_name)
            fetchData(`get-timing/${courseId}`, 'GET', '', true, false, (res) => { 
                if(res.status){
                    setTimingData(res.data)
                }
            }, (err) => {})
        
    }

    useEffect(() => {
        // if(props.loadComponent === 'timing'){
            fetchData(`get-timing/${courseId}`, 'GET', '', true, false, (res) => { 
                if(res.status){
                    setTimingData(res.data)
                }
            }, (err) => {})
        // }
    }, [courseId])
    

    return(
        <>
            <div className="tab-pane fade show active" id="timingBox" role="tabpanel">
                <div className="d-flex flex-wrap align-items-center justify-content-between mb-1">
                    <div className="text-orange mb-1"></div>
                    <div className="">
                        <button className="btn btn-success mb-1 me-1" data-bs-toggle="modal" data-bs-target="#addtimingModal" onClick={() => openFormModal('timing')}>Add Timing</button>
                    </div>
                </div>
                <div className="table-responsive p-1">
                    <table className="table align-middle">
                        <thead className="bg-light">
                            <tr>
                                <th scope="col" width="120">#ID</th>
                                <th className="text-nowrap" scope="col">Timing</th>
                                <th className="text-nowrap" scope="col">Day</th>
                                <th className="text-nowrap" scope="col">Start Time</th>
                                <th className="text-nowrap" scope="col">End Time</th>
                                <th className="text-nowrap" scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                
                                _.isEmpty(timingData) ? <tr><td colSpan="6" className="alert alert-warning text-center">No data available in table</td></tr>
                                    : timingData.map((item, key) => {
                                    return (
                                        <tr key={key}>
                                        <td>{item.id}</td>
                                        <td>{item.name}</td>
                                        <td>{_.upperFirst(item.day)}</td>
                                        <td>{item.start_time}</td>
                                        <td>{item.end_time}</td>
                                        <td>
                                            <button type="button" className="table-icons-text" title="Edit" data-bs-toggle="modal" data-bs-target="#addtimingModal" onClick={() => openEditClassModal(item.id, false, 'timing')} >
                                                <i class="bi bi-pencil"></i>
                                            </button>
                                            <button type="button" className="badge bg-danger" title="Delete" onClick={ () => handleChangeStatus('delete', item.id, 'timing')} >
                                                <i class="bi bi-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    )
                                }) 
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="modal fade" id="addtimingModal" tabIndex="-1" data-bs-backdrop="static" aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                    <div className="modal-content border-0 shadow">
                        <div className="modal-header bg-light">
                            <h5 className="modal-title">{opPerform === 'add' ? "Add" : disableField ? "Show" : "Update"} Timing</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" id='timingModal' aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-sm-4">
                            <form action="#" method="post" className="needs-validation" id="timingForm" noValidate>
                                <div className="row mx-0 mb-2 mb-sm-4">
                                    <label htmlFor="timing_name" className="col-sm-4 col-form-label">Timing<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                    <div className="col-sm-8 col-xxl-7">
                                        <input type="text" className="form-control" id="timing_name" name="timing_name" defaultValue={timing_name}   onChange={(e) => setTiming_name(e.target.value)} disabled={disableField} required />
                                    </div>
                                </div>
                                <div className="row mx-0 mb-2 mb-sm-4">
                                    <label htmlFor="reference_class" className="col-sm-4 col-form-label">Day<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                    <div className="col-sm-8 col-xxl-7">
                                        <select className="form-select" name="timing_day" id="timing_day" value={timing_day} onChange={(e) => setTiming_day(e.target.value)} disabled={disableField} required  >
                                            <option value=''>--select day--</option>
                                            <option value="sunday">Sunday</option>
                                            <option value="monday">Monday</option>
                                            <option value="tuesday" >Tuesday</option>
                                            <option value="wednesday">Wednesday</option>
                                            <option value="thursday">Thursday</option>
                                            <option value="friday">Friday</option>
                                            <option value="saturday">Saturday</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row mx-0 mb-2 mb-sm-4">
                                    <label htmlFor="class_date" className="col-sm-4 col-form-label">Start Time<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                    <div className="col-sm-8 col-xxl-7">
                                        <DatePicker 
                                            selected={start_class_time} 
                                            onChange={(date) => setStart_class_time(date)} 
                                            className="form-control"
                                            timeInputLabel="Time:"
                                            dateFormat="h:mm aa"
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            onChangeRaw={(e) => e.preventDefault()}
                                            disabled={disableField}
                                            popperPlacement="left-end"
                                            
                                        />
                                    </div>
                                </div>
                                <div className="row mx-0 mb-2 mb-sm-4">
                                    <label htmlFor="class_date" className="col-sm-4 col-form-label">End Time<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                    <div className="col-sm-8 col-xxl-7">
                                        <DatePicker 
                                            selected={end_class_time} 
                                            onChange={(date) => setEnd_class_time(date)} 
                                            className="form-control"
                                            minDate={new Date()}
                                            timeInputLabel="Time:"
                                            dateFormat="h:mm aa"
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            onChangeRaw={(e) => e.preventDefault()}
                                            disabled={disableField}
                                            id="end_time"
                                            popperPlacement="left-end"

                                        />
                                    </div>
                                </div>
                                <div className="row mx-0">
                                    <div className="col-sm-8 offset-sm-4">
                                        { disableField ? '' : 
                                            <button type="button" className="btn btn-blue w-120px px-4 me-2" onClick={handelTimingSubmitForm}>{opPerform === 'add' ? "Add" : "Update"}</button>
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Timing