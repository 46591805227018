import React from 'react'
import { fetchData } from "../../components/Helper"
import { useEffect, useState, useContext } from "react"
import Context from "../../components/Context";
import CourseCard from '../../components/cards/Course';
import _, { now } from "lodash";

function MyProfile(props) {
    const [context] = useContext(Context)
    const [profile, setProfile] = useState('')
    const [image, setImage] = useState();
    const [remove, setRemove] = useState(false);
    const [refresh, setRefresh] = useState(now)

    useEffect(() => {
        document.getElementById('group-title').innerHTML = 'My Profile';
        document.title = 'XTLMS | My Profile';

        if (context && context.auth) {
            fetchData(`get-profile/${props.student_id}`, 'GET', '', true, false, (res) => {
                setProfile(res.data)
                setRemove(_.last(_.split(res.data.user_details.image, '/')) === 'default.png' ? false : true)
            }, (err) => { });
        }
    }, [context, props.student_id, refresh])

    const uploadMyProfile = (e) => {
        let image = e.target.files[0];
        let id = profile.id;
        const formData = new FormData();
        formData.append('image', image)
        fetchData(`update-profile/${id}`, 'POST', formData, true, true, (res) => {
            setRemove(true)
            setImage(res.data.image)
        }, (err) => {

        })
    }

    const deleteProfile = (id) => {
        fetchData(`update-profile/${id}`, 'POST', '', true, true, (res) => {
            setRemove(false)
            setImage(res.data.image)
        }, (err) => {})
    }

    const getVerficationCode = (method) => {
        fetchData(`verification-code`, method, '', true, true, (res) => {
            if(res.status){
                if(res.data){
                    document.getElementById('copy-text').innerText = res.data
                }else{
                    setRefresh(now)
                }
            }
        }, (err) => {})
    }

    const copyText = (e) => {
        let text = document.getElementById('copy-text').innerText;
        let textField = document.createElement('textarea')
        textField.innerText = text
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
    }

    return (
        <>
        {profile ?
            <div className="card-body">
                <div className="d-sm-flex flex-wrap align-items-start"> 
                    <div className="ratio ratio-1x1 rounded-10 overflow-hidden mb-3 mmw-180px position-relative">
                        <div className="d-flex justify-content-center align-items-center"> 
                            <img src={image ? image : profile.user_details.image} alt={profile.name} className="img-coveimager h-100" />
                        </div>
                        {context && context.auth && (context.auth.role_id === 5 || context.auth.role_id === 6) ?
                            <div className="img-controller justify-content-end">
                                { remove ? 
                                    <label title="Change Image"> 
                                        <i className="bi-camera-fill bi-2x"></i>
                                        <input type="file" accept="image/png, image/jpeg, image/jpg" onChange={uploadMyProfile} />
                                    </label> : ''
                                    // <button type="button" title="Remove" onClick={() => deleteProfile(profile.id)}>
                                    //     <i className="bi-x bi-6x"></i>
                                    // </button>
                                 }
                            </div> : ''  }
                        
                    </div>
                    {profile.role_id === 4 ?
                        <div className="ps-sm-4">
                            <div className="mb-3">
                                <div className="fs-14px fw-bold text-muted">Teacher Name</div>
                                <span className="fs-17px d-block">{profile.name}</span>
                            </div>
                            <div className="mb-3">
                                <div className="fs-14px fw-bold text-muted">Teaching Experience</div>
                                <span className="fs-17px">{profile.user_details.experience}</span>
                            </div>
                            <div className="mb-4">
                                <div className="fs-14px fw-medium text-muted">Rating</div>
                                <div className="star-rating">
                                    <span style={{width:Number(profile.user_details.rating * 20)+'%'}}></span>
                                </div>
                                {/* <span className={`star star-${profile.user_details.rating} d-block fs-25px`}></span> */}
                                <span className="fs-17px">{profile.user_details.rating} out of 5</span>
                            </div>
                        </div>
                        :
                        <div className="ps-sm-4">
                            <h4 className={`pb-2 m-0 fw-medium ${profile ? '' : 'placeholder'}`}>{profile ? profile.name : ''}</h4>
                            {
                                (profile && profile.user_details.brief === 'Lorem Ipsum is simply dummy text...') ?  <div className="mb-3"></div>
                                    :
                                    <p className="opacity-90 mb-3">
                                        {profile ? profile.user_details.brief : ''}
                                    </p>

                            }
                            <div className="mb-3">
                                <strong className="text-dark d-block fs-15px fw-bold">Email</strong>
                                <span className="opacity-90">{profile ? profile.email : ''}</span>
                            </div>
                            <div className="mb-3">
                                <strong className="text-dark d-block fs-15px fw-bold">Mobile Number</strong>
                                <span className="opacity-90">{profile ? profile.user_details.phone : ''}</span>
                            </div>
                        </div>}
                        { (profile.user_details && profile.user_details.telegram_chat_id) || (context.auth.id !== profile.user_details.user_id) ? '' :
                        <div className="ms-auto dropdown">
                            <button type="button" className='btn btn-sm btn-orange dropdown-toggle' data-bs-auto-close="outside" aria-label="Close" title="Activate Notification" data-bs-toggle="dropdown" onClick={() => getVerficationCode('GET')}>
                                Activate Notification
                            </button>
                            <form className='dropdown-menu dropdown-menu-end shadow p-3 mt-2' style={{minWidth:'250px'}}>
                                <div className='d-flex mb-3'>
                                    <div className='form-control bg-light me-1' id='copy-text' title='Verification Code'></div>
                                    <button type='button' className='btn btn-light border' title='Copy' onClick={copyText}>
                                        <i className="bi-clipboard"></i>
                                    </button>
                                </div>
                                <button type='button' title='Verify' className='btn btn-success w-100' onClick={() => getVerficationCode('POST')}>Verify</button>
                            </form>
                        </div> }
                </div>
                {profile.role_id === 4 ?
                    <div className="d-flex flex-column mt-2">
                        {
                            (profile.user_details.brief === 'Lorem Ipsum is simply dummy text...') ? <div className="mb-2"></div>
                                :
                                <div className="mb-3 border-lightblue rounded p-3">
                                    <div className="fs-14px fw-bold text-muted">Brief Experience</div>
                                    <span className="fs-17px">{profile.user_details.brief}</span>
                                </div>
                        }

                        {profile.feedbacks ? profile.feedbacks.map((description, i) => {
                            return (
                                <div key={i} className="mb-3 border-lightblue rounded p-3">
                                    <div className="fs-14px fw-bold text-muted">Feedback</div>
                                    <span className="fs-17px">
                                        <p>{description.description}</p>
                                    </span>
                                </div>)
                        }) :
                            <div className="mb-3 border-lightblue rounded p-3">
                                <div className="fs-14px fw-bold text-muted">Feedback</div>
                                <span className="fs-17px">Feedback, is a section where some feedbacks cards will show about each teacher, (admin is the one whom add these feedbacks and shouls be able to able edit them later)</span>
                            </div>
                        }
                        <div className="border-lightblue rounded p-3">
                            <div className="fs-14px fw-bold text-muted">Taught Courses </div>
                            <div className="cources-section pt-3" >
                                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 pb-0 row-cols-lg-4 g-2" >
                                    {profile.course ? <CourseCard course_details={profile.course} /> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''}
            </div>
            : ''
            // <div className="card-body">
            //     <div className="d-sm-flex align-items-start placeholder-wave">
            //         <div className="ratio ratio-1x1 rounded-10 overflow-hidden mb-3 mmw-180px">
            //             <div className="img-cover placeholder"></div>
            //         </div>
            //         <div className="ps-sm-4 placeholder-wave w-100">
            //             <p className="mb-3 placeholder w-50"></p>
            //             <p className="opacity-90 mb-3 placeholder w-100"></p>
            //             <p className="opacity-90 mb-3 placeholder w-100"></p>
            //             <p className="opacity-90 mb-3 placeholder w-50"></p>
            //             <div className="my-3 placeholder-wave">
            //                 <strong className="text-dark d-block fs-15px fw-bold placeholder w-25">Email</strong>
            //             </div>
            //         </div>
            //     </div>
            // </div>
        }
        </>
    )
}

export default MyProfile
