import { useEffect, useState} from "react";
import { fetchData} from "../../components/Helper";
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';

function FeedbackCard() {
  const [feedback, setFeedback] = useState([]);

  useEffect(() => {
    fetchData('feedback/yes', 'GET', '', false, false, (res) => {
      if (res.status && res.data) {
        setFeedback(res.data);
      }
    }, (err) => {});
  }, []);

  const responsiveOptions = {
    0: {
      nav: false,         
      dots: true,
      items: 1, // Number of items to show at 0px screen width
    },
    600: {
      nav: false,         
      dots: true,
      items: 1, // Number of items to show at 600px screen width
    },
    1000: {
      nav: false,         
      dots: true,
      items: 1, 
    },
  };


  return (  
    <>        
        {feedback.length > 0 && (
          <OwlCarousel items={feedback.length} className="owl-carousel owl-arrow-dots" nav margin={20} responsive={responsiveOptions}>
            {feedback.map((feedbackDetails, i) => (
              <div className="item" key={i}>
                <div className="feedback-item">
                  <div className="feedback-topbox">
                   {feedbackDetails.feedback_description}
                  </div>
                  <div className="feedback-bottombox">
                    <img src={feedbackDetails.feedback_image} className="" alt="XTLMS" />
                    
                    <div className="feedback-bottombox-meta">
                      <h5>{feedbackDetails.teacher_name}</h5>
                      <p>{feedbackDetails.course_name}</p> 
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        )}
        <OwlCarousel className="owl-carousel owl-arrow-dots" nav margin={20} responsive={responsiveOptions}>
          <div className="item">
            <div className="feedback-item">              
              <div className="feedback-topbox text-center">
              I absolutely love how user-friendly the LMS is! The courses are engaging, and the interactive elements make learning so much fun. I can easily track my progress and connect with my classmates for group projects. This platform has truly transformed my learning experience!

              </div>
              <div className="feedback-bottombox">
                <img src="https://cdn-icons-png.flaticon.com/512/727/727399.png" className="" alt="XTLMS" />
                <div className="feedback-bottombox-meta">
                  <h5>Alison</h5>
                  <p>Researcher</p> 
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="feedback-item">              
              <div className="feedback-topbox text-center">
              As a parent, I appreciate the transparency and accessibility of the LMS. I can easily monitor my child's progress and see what they are learning. The resources provided are fantastic, and it gives me peace of mind knowing my child is receiving a high-quality education.

                </div>
                <div className="feedback-bottombox">
                <img src="https://cdn-icons-png.flaticon.com/512/727/727399.png" className="" alt="XTLMS" />
                <div className="feedback-bottombox-meta">
                  <h5>Andrea</h5>
                  <p>Teacher</p> 
                </div>
              </div>
            </div>
          </div>
        <div className="item">
          <div className="feedback-item">           
              <div className="feedback-topbox text-center">
              The LMS has made it so easy for me to balance my studies with extracurricular activities. The flexible scheduling and access to recorded lectures mean I can learn at my own pace. I feel more confident in my abilities, and I’m achieving results that reflect my hard work!

              </div>
              <div className="feedback-bottombox">
                  <img src="https://cdn-icons-png.flaticon.com/512/727/727399.png" className="" alt="XTLMS" />
                  <div className="feedback-bottombox-meta">
                      <h5>Samantha</h5>
                      <p>IT Expert</p>
                  </div>
              </div>
          </div>
        </div>

        <div className="item">
          <div className="feedback-item">           
              <div className="feedback-topbox text-center">
              The courses on this platform have transformed my learning experience! The interactive materials and engaging instructors make complex subjects easy to understand. I love being able to access the content anytime, anywhere!

              </div>
              <div className="feedback-bottombox">
                  <img src="https://cdn-icons-png.flaticon.com/512/727/727399.png" className="" alt="XTLMS" />
                  <div className="feedback-bottombox-meta">
                      <h5>Leonard Campbell</h5>
                      <p>Independent Researcher</p>
                  </div>
              </div>
          </div>
        </div>

        
        <div className="item">
          <div className="feedback-item">            
              <div className="feedback-topbox text-center">
              I appreciate the variety of courses available. The community forums allow me to connect with my peers, and the career development resources have helped me find internships. This platform is a game-changer!

              </div>
            
              <div className="feedback-bottombox">
                <img src="https://cdn-icons-png.flaticon.com/512/727/727399.png" className="" alt="XTLMS" />
                <div className="feedback-bottombox-meta">
                    <h5>Roman threw</h5>
                    <p>Physics Teacher</p>
                </div>
              </div>
            </div>
        </div>
    </OwlCarousel>
    </>  
  );
}

export default FeedbackCard;
