import { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import Context from "./Context";
import BackToTop from "../modules/BackToTop";

function FrontendFooter(){
    const [context] = useContext(Context)
    return(
        <>
            <BackToTop />
            <footer className="footer-front d-none">
                <img src="images/courses-bg.png" className="position-absolute top-0 opacity-10 zindex-0 pointer-none" style={{filter:"brightness(0.7)"}}  alt="XTLMS"/>
                <div className="container position-relative pt-5">
                    <div className="row">
                        <div className="col-sm-12 col-lg-4 mt-5">
                            <h5 className="text-white mb-2">Our Vision</h5>
                            <p className="pe-lg-5 mb-1">
                            Providing a convenient and efficient learning environment where students of  all needs can be catered to. Our goal is to make students reach a point where they can enhance their knowledge and skills, applying them while stepping into the real world.
                            </p>
                            <Link to="/" className="small text-hoverblue" title="Read more">Read more &#10140;</Link>
                        </div>
                        <div className="col-sm-6 col-lg-3 offset-lg-1 mt-5">
                            <h5 className="text-white">Links</h5>
                            <hr className="mt-2 h-2px w-40px opacity-60 text-blue"/>
                            <ul className="p-0 m-0 list-unstyled">
                                <li className="mb-2">
                                    <NavLink to="/home" title="Home">Home</NavLink>
                                </li>
                                <li className="mb-2">
                                    <NavLink to="/courses" title="Courses">Courses</NavLink>
                                </li>
                                {/* <li className="mb-2">
                                    <NavLink to="/time-table" title="Time Table">Time Table</NavLink>
                                </li> */}
                                <li className="mb-2">
                                    <NavLink to="/teachers" title="Teachers">Teachers</NavLink>
                                </li>
                                <li className="mb-2">
                                    <NavLink to="/about-us" title="About Us">About Us</NavLink>
                                </li>
                                <li className="mb-2">
                                    <NavLink to="/contact-us" title="Contact Us">Contact Us</NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="col-sm-6 col-lg-4 mt-5">
                            <h5 className="text-white">Contact Us</h5>
                            <hr className="mt-2 h-2px w-40px opacity-60 text-blue"/>
                            <ul className="p-0 m-0 list-unstyled">
                                <li className="mb-3">
                                    <span className="d-flex">
                                        <i className="bi-geo-alt-fill bi-3x lh-1 mmw-20px mt-1 me-2"></i>
                                        Cyber Tower, Pickup Bldg Rd, Vibhuti Khand
                                    </span>
                                </li>
                                <li className="mb-3 d-none">
                                    <span className="d-flex flex-wrap">
                                        <i className="bi-whatsapp bi-1x lh-1 mmw-20px mt-1 me-2"></i>
                                        <div className="d-block">
                                            <a href="https://api.whatsapp.com/send?phone=201117822404&text=Hello">+91 000 0000 000</a><br/>
                                            <a href="https://api.whatsapp.com/send?phone=201067472903&text=Hello" className="mt-1 d-inline-block">+91 000 0000 000</a><br/>
                                            <a href="https://api.whatsapp.com/send?phone=971505291834&text=Hello" className="mt-1 d-inline-block">+91 000 0000 000</a>
                                        </div>
                                    </span>
                                </li>
                                <li className="mb-3">
                                    <span to="mailto:registrations@lms.com" className="d-flex">
                                        <i className="bi-envelope-fill bi-1x lh-1 mmw-20px mt-1 me-2"></i>
                                        <div className="d-block">
                                            <a href="mailto:admin@xtlms.ae">admin@xtlms.ae</a><br/>
                                        </div>
                                    </span>
                                </li>
                            </ul>
                            <hr className="mt-4 h-2px w-40px opacity-60 text-blue"/>
                            <ul className="p-0 m-0 list-unstyled list-inline">
                                <li className="list-inline-item">
                                    <a href="https://www.facebook.com/XTLMS021/" title="Facebook">
                                        <i className="bi-facebook fs-20px"></i>
                                    </a>
                                </li>
                                <li className="list-inline-item px-2"> 
                                    <a href="https://api.whatsapp.com/send?phone=+9999999999&text=Hello" title="WhatsApp">
                                        <i className="bi-whatsapp fs-20px"></i>
                                    </a>
                                </li>
                                <li className="list-inline-item pe-2">
                                    <a href="https://www.instagram.com/XTLMS.ae/?fbclid=IwAR3yAU_YFlUWqQBqNxbieCDca_TrRwdHQwswpvJ5jE0UNeR1P7tN1cNeExs" title="Instagram">
                                        <i className="bi-instagram fs-20px"></i>
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="https://www.youtube.com/channel/XTLMS021" title="YouTube">
                                        <i className="bi-youtube fs-20px"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-sm-12 mt-5">
                            <hr/>
                        </div>
                        <div className="col-md-6 order-md-1 text-center text-md-end pt-3 small">
                            <ul className="p-0 m-0 list-unstyled list-inline">
                                <li className="list-inline-item">
                                    <Link to="/" title="Privacy">Privacy</Link>
                                </li>
                                <li className="list-inline-item">|</li>
                                <li className="list-inline-item">
                                    <Link to="/" title="Terms & Conditions">Terms & Conditions</Link>
                                </li>
                                <li className="list-inline-item">|</li>
                                <li className="list-inline-item">
                                    <Link to="/" title="FAQ">FAQ</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 text-center text-md-start pt-3 small">
                            {/* { context && context.site && context.site.copyright }
                            */}
                            &copy; 2024 - XTLimited All rights reserved.
                        </div>
                    </div>
                </div>
            </footer> 
            <footer id="footer">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="footer-upper d-flex flex-wrap">
                                <div class="footer-upper-left">
                                    <a href="index.php" class="footer-logo">
                                        <img src="images/logo.png" alt="" />
                                    </a>
                                </div>
                                <div class="footer-upper-right">
                                    <ul class="social-links d-flex flex-wrap">
                                        <li>
                                            <a href="https://www.facebook.com/xipetech/" class="social-link" target="_blank"><i class="bi-facebook fs-20px"></i></a>
                                        </li>
                                        <li>
                                            <a href="https://wa.me/+918090920216" class="social-link" target="_blank"><i class="bi-whatsapp fs-20px"></i></a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/xipe.tech/" class="social-link" target="_blank"><i class="bi-instagram fs-20px"></i></a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/company/xipe-tech/" class="social-link" target="_blank"><i class="bi-linkedin fs-20px"></i></a>
                                        </li>
                                        <li>
                                            <a href="https://x.com/xipetech/" class="social-link" target="_blank">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
                                                    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                                                </svg>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.youtube.com/@XipeTechLucknow" class="social-link" target="_blank"><i class="bi-youtube fs-20px"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="footer-lower d-flex flex-wrap">
                                <div class="footer-lower-left d-flex flex-wrap">
                                    <div class="footer-nav-box"> 
                                        <h4>Quick Links</h4>
                                        <ul class="footer-nav-links">
                                            <li>
                                                <Link to="/" class="footer-nav-link">Home</Link>
                                            </li>
                                            {/* <li>
                                                <Link to="/courses" class="footer-nav-link">Courses</Link>
                                            </li> */}
                                            <li>
                                                <Link to="/teachers" class="footer-nav-link">Teachers</Link>
                                            </li>
                                            <li>
                                                <Link to="/about-us" class="footer-nav-link">About Us</Link>
                                            </li>
                                            <li>
                                                <Link to="/contact-us" class="footer-nav-link">Contact Us</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="footer-nav-box">
                                        <h4>Courses</h4>
                                        <ul class="footer-nav-links">
                                            <li>
                                                  <Link to="/course/5/join" class="footer-nav-link">Mastering Digital Marketing Strategies</Link>
                                            </li>
                                            <li>
                                                  <Link to="/course/4/join" class="footer-nav-link">AI-Powered Solutions for Everyday Life</Link>
                                            </li>
                                            <li>
                                                  <Link to="/course/3/join" class="footer-nav-link">Creative Web Design Essentials</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="footer-nav-box">
                                        <h4>Other Link</h4>
                                        <ul class="footer-nav-links">
                                            <li>
                                                <Link to="/privacy-policy" class="footer-nav-link">Privacy</Link>
                                            </li>
                                            <li>
                                                <Link to="/terms-and-conditions" class="footer-nav-link">Terms and Conditions</Link>
                                            </li>
                                            <li>
                                                <Link to="/faq" class="footer-nav-link">Faqs</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="footer-lower-right d-none d-md-flex flex-wrap">
                                    <div class="search-box">
                                        <input type="text" class="form-control" placeholder="Enter your mobile Number" />
                                        <button type="submit" class="btn btn-primary">Subscribe</button>
                                    </div>
                                    <p>Providing a convenient and efficient learning environment where students of all needs can be catered to. Our goal is to make students reach a point where they can enhance their knowledge and skills, applying them while stepping into the real world</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-socket">
                    <p class="copyright mb-0">&copy; 2024 - XTLimited All rights reserved.</p>
                </div>
            </footer>
        </>
    )
}

export default FrontendFooter