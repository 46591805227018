import CourseCard from "../components/cards/Course"
import { useEffect, useState} from "react";
import { fetchData, scrollbarSetToTop, showAlert} from  "../components/Helper"
import Pagination from "../components/Pagination";
import { Helmet } from "react-helmet";
import InnerBanner from "../components/InnerBanner";

function Course(){
    const [course, setCourse] = useState(''); 
    const [search, setSearch] = useState(localStorage.getItem('search') ? localStorage.getItem('search') : ''); 
    const [level, setLevel] = useState('');
    const [system, setSystem] = useState('');
    const [subject, setSubject] = useState(''); 
    const [levelId, setLevelId] = useState('');
    const [systemId, setSystemId] = useState(localStorage.getItem('system_id') ? localStorage.getItem('system_id') : '');
    const [subjectId, setSubjectId] = useState(localStorage.getItem('subject_id') ? localStorage.getItem('subject_id') : ''); 
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        scrollbarSetToTop();
        async function getCourse(){
            fetchData(`courses?page=${currentPage}&search=${search}&system_id=${systemId}&subject_id=${subjectId}`, 'GET', '', false, false, (res) => {
                if(res.status && res.data){
                    setCourse(res.data)
                    localStorage.removeItem("search");
                    localStorage.removeItem("system_id");
                    localStorage.removeItem("subject_id"); 
                }
            }, (err) => {}) 
        } 
        getCourse(); 
        fetchData(`educational-classification-list/level`, 'GET', '', false, false, (res) => {
           setLevel(res.data)
        }, (err) => {}) 
        fetchData(`educational-classification-list/system`, 'GET', '', false, false, (res) => {
            setSystem(res.data)
        }, (err) => {})  
        fetchData(`educational-classification-list/subject`, 'GET', '', false, false, (res) => {
            setSubject(res.data)
        }, (err) => {}) 
   }, [currentPage, search, systemId, subjectId])
    
   const searchCourse = (e, search_query = '') => { 
        e.preventDefault(); 
        let search_q = search_query ? search_query : e.target.value
        setSearch(search_q);  
        if(search && search.length >= 2){
            fetchData(`courses?search=${search_q}`, 'GET', '', false, false, (res) => {   
                setCourse(res.data)
                setTotal(res.data.total)
            }, (err) => {
                
            }) 
        } 
    }

    const submitSearchBtn = (e) =>{
        e.preventDefault(); 
        if(search && search.length >= 3){
            searchCourse(e, search)
        }else{
            let msg = {message:'Please fill minimum three characters.', status:false}
            showAlert(msg)
        }
    }

    const advanceSearch = (e) => {
        e.preventDefault(); 
        if(levelId || (systemId) || (subjectId)){
            fetchData(`courses?search=${search}&system_id=${systemId}&level_id=${levelId}&subject_id=${subjectId}`, 'GET', '', false, false, (res) => {   
                if(res.status && res.data){
                    setCourse(res.data)
                    setTotal(res.data.total)
                }
            }, (err) => {}) 
        }else{
            let msg = {message:'Please fill some record.', status:false}
            showAlert(msg)
        }
    }

    const submitRestBtn = () => {
        setLevelId('');
        setSystemId('')
        setSubjectId('')
        setTotal(0)
        setSearch('')
        fetchData(`courses`, 'GET', '', false, false, (res) => { 
            document.getElementById('admin-course-search').reset()
            setCourse(res.data)
        }, (err) => {}) 
    }

    const handlePageClick = (data) => {
        setCurrentPage(data.selected >= 0 ? data.selected + 1 : 0)
	}
    
    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>XTLMS | Courses</title>
        </Helmet>
        <InnerBanner title="Courses" />

        
        <section className="pt-5" hidden>
            <div className="container">
                <div className="row g-3">
                    <div className="col-md-12">
                        <form className="p-4 bg-grey border rounded-6" id="admin-course-search">
                            <div className="input-group input-group-xl">
                                <input type="text" className="form-control py-1" placeholder="Search..."  value={search}  onChange={searchCourse} />
                                <button className="input-group-text btn-blue py-2 px-4" id="basic-addon2" title="Search" onClick={submitSearchBtn}>
                                    <i className="bi-search fs-25px"></i>
                                </button>
                            </div>
                            <div className="ps-3 py-2">
                                <a className="text-body text-decoration-none fw-medium" data-bs-toggle="collapse" title="Advance Search" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                                    Advance Search
                                    <i className="bi-arrow-down-up ms-1 fs-18px"></i>
                                </a>
                            </div>
                            <div className="collapse" id="collapseExample">
                                <div className="card border-0 p-2 p-sm-3">
                                    <div className="row m-0">
                                        <div className="col-sm-12 pt-2">
                                            <h5>Advance Search</h5>
                                        </div>
                                        <div className="col-sm-6 col-md-4 mb-2">
                                            <select className="form-select" name="system" title="System" value={systemId} onChange={(e) => setSystemId(e.target.value)} role="button">
                                                <option value="">---System---</option>
                                                {
                                                    system ? system.map((system_detail) => {
                                                        return (<option key={system_detail.id} value={system_detail.id}>{system_detail.name}</option>)
                                                    }) : ''   
                                                }
                                            </select>
                                        </div>
                                        <div className="col-sm-6 col-md-4 mb-2">
                                            <select className="form-select" name="level" title="Level" value={levelId} onChange={(e) => setLevelId(e.target.value)} role="button">
                                            <option value="">---Level---</option>
                                            {
                                                level ?level.map((level_detail) => {
                                                    return (<option key={level_detail.id} value={level_detail.id}>{level_detail.name}</option>)
                                                }) :''  
                                                
                                        }
                                            </select>
                                        </div>
                                        <div className="col-sm-6 col-md-4 mb-2">
                                            <select className="form-select" name="subject" title="Subject" value={subjectId} onChange={(e) => setSubjectId(e.target.value)} role="button">
                                                <option value="">---Subject---</option>
                                                {
                                                    subject ? subject.map((subject_detail) => {
                                                        return (<option key={subject_detail.id} value={subject_detail.id}>{subject_detail.name}</option>)
                                                    }) :''  
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row m-0 justify-content-between pt-3 pb-3">
                                        <div className="col">
                                            <p className="btn position-relative pointer-none fw-medium">
                                                Results
                                                <span className="position-absolute top-0 mt-1 px-2 start-100 translate-middle badge rounded-pill bg-blue">
                                                    {total}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="col text-end">
                                            <button type="button" className="btn btn-blue text-white me-1 fw-medium px-4 mb-2" title="Reset" onClick={submitRestBtn}>Reset</button>
                                            <button  onClick={advanceSearch} className="btn btn-orange fw-medium px-4 border-0 mb-2" title="Search">Search</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>

        <section className="cources-section py-5">
            <div className="container py-sm-4">
                <div className="row">
                    <div className="col-md-4 col-lg-3 mb-4 mb-md-0">
                        <form className="p-3 bg-grey border rounded-6 sticky-top" id="admin-course-search">
                            <div className="input-group input-group-xl">
                                <input type="text" className="form-control py-1" placeholder="Search..."  value={search}  onChange={searchCourse} />
                                <button className="input-group-text btn-blue py-2 px-3" id="basic-addon2" title="Search" onClick={submitSearchBtn}>
                                    <i className="bi-search fs-20px"></i>
                                </button>
                            </div>
                            <div className="ps-1 py-2">
                                <a className="text-body text-decoration-none fw-medium" data-bs-toggle="collapse" title="Advance Search" href="#collapseExample" role="button" aria-expanded="true" aria-controls="collapseExample">
                                    Advance Search
                                    <i className="bi-arrow-down-up ms-1 fs-18px"></i>
                                </a>
                            </div>
                            <div className="collapse show" id="collapseExample">
                                <div className="card border-0 py-2 py-sm-3">
                                    <div className="row m-0">
                                        <div className="col-sm-12 pt-2">
                                            <h5>Advance Search</h5>
                                        </div>
                                        <div className="col-sm-6 col-md-12 mb-2">
                                            <select className="form-select" name="system" title="System" value={systemId} onChange={(e) => setSystemId(e.target.value)} role="button">
                                                <option value="">---System---</option>
                                                {
                                                    system ? system.map((system_detail) => {
                                                        return (<option key={system_detail.id} value={system_detail.id}>{system_detail.name}</option>)
                                                    }) : ''   
                                                }
                                            </select>
                                        </div>
                                        <div className="col-sm-6 col-md-12 mb-2">
                                            <select className="form-select" name="level" title="Level" value={levelId} onChange={(e) => setLevelId(e.target.value)} role="button">
                                            <option value="">---Level---</option>
                                            {
                                                level ?level.map((level_detail) => {
                                                    return (<option key={level_detail.id} value={level_detail.id}>{level_detail.name}</option>)
                                                }) :''  
                                                
                                        }
                                            </select>
                                        </div>
                                        <div className="col-sm-6 col-md-12 mb-2">
                                            <select className="form-select" name="subject" title="Subject" value={subjectId} onChange={(e) => setSubjectId(e.target.value)} role="button">
                                                <option value="">---Subject---</option>
                                                {
                                                    subject ? subject.map((subject_detail) => {
                                                        return (<option key={subject_detail.id} value={subject_detail.id}>{subject_detail.name}</option>)
                                                    }) :''  
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row justify-content-between pt-3 pb-3">
                                        <div className="col">
                                            <p className="btn position-relative pointer-none fw-medium">
                                                Results
                                                <span className="px-2 ms-1 badge rounded-pill bg-blue">
                                                    {total}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="col-12 px-4 text-start">
                                            <button type="button" className="btn btn-blue text-white me-2 fw-medium px-4 mb-2" title="Reset" onClick={submitRestBtn}>Reset</button>
                                            <button  onClick={advanceSearch} className="btn btn-orange fw-medium px-4 border-0 mb-2" title="Search">Search</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-8 col-lg-9">
                        <div className="item_list_two">
                            <CourseCard course_details={course.data} />
                        </div>
                        <Pagination paginate_data={course} onPageChange={handlePageClick}/>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Course