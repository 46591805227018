import { createRoot } from "react-dom/client";
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import Datatables, { reloadDataTable, reloadUrlDataTable } from '../../components/Datatables';
import DateRangeFilter from '../Filter';
import { times } from 'lodash';

function  Attendance(props) {
    const courseId = useParams().course_id
    const [attendance_dt] = useState({
        dt_url: `course-details/${courseId}`,
        dt_name: 'attendance_details',
        dt_column: [
            { data:"id", name:'id', title: "#ID"},
            { data:"class_id", name:`class_id`, title:"Class Id"},
            { data:"reference_class", name:'reference_class', title:"Timing"},
            { data:"class_date", name:'class_date', title:"Date"},
            { data:"start_class_time", name:'start_class_time', title:"Start Time"},
            { data:"end_class_time", name:'end_class_time', title:"End Time"},
            { data:"status", name:'status',  title:"Status" },
            { data:"created_at", name:'created_at',  title:"Class Created Date" },
            { data:"id", title: 'Action', sortable:false, searchable:false, class:"text-nowrap text-center", width:160}
        ],
        dt_column_defs: [
            {
                targets: 6,
                createdCell: (td, cellData, rowData, row, col) =>{
                    createRoot(td).render(<> 
                    { rowData.status === "over" ? <span className="badge bg-warning">Over</span> : '' }
                    { rowData.status === "active" ? <span className="badge bg-success">Active</span> : '' }
                    { rowData.status === "inactive" ? <span className="badge bg-danger">Inactive</span> : '' }
                    { rowData.status === "draft" ? <span className="badge bg-warning">Draft</span> : '' }
                    </>)
                },
            },
            {
                targets: 8,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(<>
 
                        { rowData.status !== 'draft' ?
                        <button type="button" className="table-icons-text"  title="View" data-bs-toggle="modal" data-bs-target="#attendanceviewModal" onClick={() => attendanceDetails(rowData.id,rowData.course_id)}>View</button>
                        : '' } 
                                                    
                    </>)
                },
            }, 
        ],
        dt_order: [[ 7, 'desc' ]],
        dt_filter: () => {
            createRoot(document.querySelector('#wt_datatable_attendance_details_wrapper .dt-custom-filter')).render(
                <>
                    <div className="d-inline-block align-top me-2">
                        <DateRangeFilter daterange="All" name="a_daterange" maxDate={new Date()} className="form-control form-control-sm" disableUpdateInput={false} getDateRange={getDateRange} key={times()}/>
                    </div>
                </>
            )
        }
    })

    const getDateRange = (startDate, endDate) => {
        reloadUrlDataTable(attendance_dt, `course-details/${courseId}?startDate=${startDate}&endDate=${endDate}`)
    }

    const attendanceDetails = (id, course_id) => {
        let dt_attendance_view = {
            dt_url: `attendance/${course_id}/${id}`,
            dt_name: 'attendance_view',
            dt_export_name: 'Student Attendance',
            dt_export: true,
            dt_column: [
                { data:"user_id", name:`users.id`, title:"Student Id"}, 
                { data:"student_name", name:`users.name`, class:"text-nowrap", title:"Student Name"},
                { data:"email", name:`users.email`, title:"Student Email"}, 
                { data:"live_class", name:`live_class`, title:"Attendance"}, 
                { data:"recording", name:`recording`, title:"Recording"}, 
                { data:"attendance_date", name:`attendances.updated_at`, class:"text-nowrap", title:"Date", }, 
            ],
            dt_column_defs: [
                {
                    targets: 3,
                    createdCell: (td, cellData, rowData, row, col) =>{
                        createRoot(td).render(<> 
                        { rowData.live_class === "yes" ? <strong className="text-green">&#10003;</strong> : <strong className="text-danger">&#x274C;</strong> }
                        </>)
                    },
                },
                {
                    targets: 4,
                    createdCell: (td, cellData, rowData, row, col) =>{
                        createRoot(td).render(<> 
                        { rowData.recording === "yes" ? <strong className="text-green">&#10003;</strong> : <strong className="text-danger">&#x274C;</strong> }
                        </>)
                    },
                },
                {
                    targets: 5,
                    createdCell: (td, cellData, rowData, row, col) =>{
                        createRoot(td).render(<> 
                        { rowData.attendance_date === "-" ? <strong className="text-danger">&mdash;</strong> : rowData.attendance_date  }
                        </>)
                    },
                },
            ],
        };

        reloadUrlDataTable(dt_attendance_view, `attendance/${course_id}/${id}`)
    }

    useEffect(() => {
        if(props.loadComponent === 'attendance'){
            reloadDataTable(attendance_dt)
            
        }
    })

    return(
        <> 
            <div className="table-responsive p-1">
                <Datatables dt_name="attendance_details"  />
            </div>                    

            <div className="modal fade" id="attendanceviewModal" tabIndex="-1">
                <div className="modal-dialog modal-dialog-scrollable modal-xl">
                    <div className="modal-content border-0 shadow">
                        <div className="modal-header bg-light">
                            <h5 className="modal-title">Attendance View</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-sm-4">
                            <div className="table-responsive p-1">
                            <Datatables dt_name="attendance_view"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Attendance