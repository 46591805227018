import React, { useEffect, useState } from 'react'
import _ from 'lodash';
import $ from "jquery";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchData, initialFormState, validateForm,showAlert } from '../../../components/Helper';
import { useLocation } from 'react-router-dom';

function MaterilDetailsAddModal({ materialId, setMaterialId, materialType, courseId, setReload }) {
    const location = useLocation();
    const [data, setData] = useState({});
    const [scheduleTimeEnable, setscheduleTimeEnable] = useState(false);
    const [docType, setDocType] = useState(true);
    const [opPerform, setOpPerform] = useState("create");
    const [restrictedPdf, setRestrictedPdf] = useState(true);
    const [fileExtension, setFileExtension] = useState();
    const [isChecked, setIsChecked] = useState(true);

    useEffect(() => {
        document.getElementById('materialModal').addEventListener('show.bs.modal', function (event) {
            setData({});
            initialFormState("materialForm");
            setscheduleTimeEnable(false); 
        })
    }, [])

    useEffect(() => {
        document.querySelector('#material').value = ''
    },[docType])

    useEffect(() => {
        openModal("materialForm", materialId > 0 ? "edit" : "create");
        if (materialId && materialId > 0) {
            fetchData(`update-material/${materialId}`, "GET", "", true, false, (res) => {
                if (res.status) {
                    setData({
                        id: res.data.id,
                        name: res.data.name,
                        material: res.data.type === "file" ? "" : res.data.notes,
                        total_grade: res.data.total_grade,
                        grade_boundary: res.data.grade_boundary,
                        schedule_time: res.data.schedule_time ? new Date(res.data.schedule_time) : null,
                        due_date: new Date(res.data.due_date),
                        status: res.data.status,
                    });
                    setDocType(res.data.type === "file" ? true : false);
                    setIsChecked(res.data.restricted_pdf === "yes" ? true : false);
                    setscheduleTimeEnable(res.data.schedule_time ? true : false);
                }
            }, (err) => { });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [materialId])

    const openModal = (modal_name, type) => {
        initialFormState(modal_name, data);
        setOpPerform(type);
        setscheduleTimeEnable(false);
    };

    const handleInputChange = (e, key = false) => {
        if (_.isDate(e)) {
            document.getElementById(key).classList.remove("is-invalid");
            setData((prevState) => ({ ...prevState, [key]: e }));
        } else {
            document.getElementById(e.target.name).classList.remove("is-invalid");
            setData((prevState) => ({ ...prevState, [e.target.name]: e.target.type === "file" ? e.target.files[0] : e.target.value }));
            if (e.target.name === "upload_data") {
                const fileInput = e.target; // Reference to the file input element
                if (fileInput.files.length > 0) {
                  const uploadedFile = fileInput.files[0];
                  const fileName = uploadedFile.name;
                  const fileExtension = fileName.split('.').pop().toLowerCase();
                  setFileExtension(fileExtension);
                  if (restrictedPdf === 'yes' && fileExtension !== 'pdf') {
                    let msg = {message: `Please submit your work as a PDF file.`, status: false};
                    showAlert(msg);
                  } else {
                    setData((prevState) => ({
                      ...prevState,
                      pre_uploaded_task: '',
                    }));
                  }
                }
            }
        }
    };

    const clientSideValidation = (e, data, formId = "materialForm") => {
        if (validateForm(e, formId)) {
            const formData = new FormData();
            for (const [key, value] of Object.entries(data)) {
                if ((key === "due_date" || key === "schedule_time") && value) {
                    formData.append(key, value.toISOString());
                } else {
                    formData.append(key, value);
                }
                if(key === "material") {
                    if(data.restricted_pdf === "yes") {
                       if(value && ((value.name && value.name.split('.').pop().toLowerCase() !== "pdf") || !value.name)) {
                        let msg = {message: `If you choose restricted pdf yes then upload pdf file only.`, status: false};
                        showAlert(msg);
                        return false;
                       }
                    } 
                }
            }
            formData.append("pathname", location.pathname);
            return formData;
        } else {
            return false;
        }
    };

    const saveData = (e) => {
        let allData = { ...data, course_id: courseId, module_type: materialType, restricted_pdf : isChecked ? "yes" : "no" , status: "active" };
        let validData = clientSideValidation(e, allData);
        if (validData) {
            saveDataInDB("add-material", validData);
        }
    };

    const saveAsDraft = (e) => {
        let allData = { ...data, course_id: courseId, module_type: materialType,restricted_pdf : isChecked ? "yes" : "no" , status: "draft" };
        let validData = clientSideValidation(e, allData);
        if (validData) {
            saveDataInDB("add-material", validData);
        }
    };

    const updateData = (e) => {
        let allData = { ...data, type: docType ? "file" : "link", restricted_pdf : isChecked ? "yes" : "no" , status: "active" };
        let validData = clientSideValidation(e, allData);
        if (validData) {
            saveDataInDB(`update-material/${data.id}`, validData);
        }
    };

    const updateAsDraft = (e) => {
        let allData = { ...data, type: docType ? "file" : "link", status: "draft" };
        let validData = clientSideValidation(e, allData);
        if (validData) {
            saveDataInDB(`update-material/${data.id}`, validData);
        }
    };

    const savescheduleData = (e) => {
        setscheduleTimeEnable(true);
        let ele = $("#schedule_time").val();
        if (!ele) {
            return false;
        }
        let allData = { ...data, course_id: courseId, module_type: materialType };
        let validData = clientSideValidation(e, allData);
        if (validData) {
            saveDataInDB(`add-material`, validData);
        }
    };

    const updatescheduleData = (e) => {
        setscheduleTimeEnable(true);
        let ele = $("#schedule_time").val();
        if (!ele) {
            return false;
        }
        let allData = { ...data, type: docType ? "file" : "link", restricted_pdf : isChecked ? "yes" : "no", status: "schedule" };
        let validData = clientSideValidation(e, allData);
        if (validData) {
            saveDataInDB(`update-material/${data.id}`, validData);
        }
    };

    const saveDataInDB = (url, data) => {
        fetchData(url, "POST", data, true, true, (res) => {
            if (res.status) {
                initialFormState("materialForm", data);
                setReload(prev => prev + 1);
                document.querySelector(`#materialModal [data-bs-dismiss=modal]`).click();
                setMaterialId(0)
            }
        }, (err) => { });
    };

    return (
        <div className="modal fade" id="materialModal" tabIndex="-1" data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {_.capitalize(opPerform)} {_.capitalize(materialType)}
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" title="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form className="row needs-validation" method="post" id="materialForm" noValidate>
                            <div className="row mx-0 mb-3">
                                <label htmlFor="name" className="col-sm-4 col-form-label">
                                    Name<sup className="text-danger fw-bold fs-15px">*</sup>
                                </label>
                                <div className="col-sm-8 col-xxl-7">
                                    <input type="text" className="form-control" placeholder="Name" id="name" name="name" defaultValue={data && data.name ? data.name : ""} onChange={handleInputChange} required autoComplete="off" />
                                </div>
                            </div>
                            <div className="row mx-0 mb-2">
                                <label htmlFor="material" className="col-sm-4 col-form-label">
                                    File or Link
                                    <sup className="text-danger fw-bold fs-15px">
                                        {opPerform === "edit" && docType && data && data.material === "" ? "" : "*"}
                                    </sup>
                                </label>
                                <div className="col-sm-8 col-xxl-7">
                                    <label className="p-1 me-3">
                                        <input type="radio" defaultChecked={docType} name="file_link" onClick={() => setDocType(!docType)} />{" "}File
                                    </label>
                                    <label>
                                        <input type="radio" defaultChecked={!docType} name="file_link" onClick={() => setDocType(!docType)} />{" "}Link
                                    </label>
                                </div>
                            </div>
                            <div className="row mx-0 mb-4">
                                <label className="col-sm-4 col-form-label"></label>
                                <div className="col-sm-8 col-xxl-7">
                                    <input type={docType ? "file" : "url"} accept={docType ? ".png,.jpg,.jpeg,.doc,.pdf,.docx,.zip,.xlsx" : ''} className="form-control" defaultValue={data && data.material ? data.material : ""} placeholder="Link" name="material" id="material" onChange={handleInputChange} required={opPerform === "create" ? true : data && data.material ? true : false} autoComplete="off" />
                                </div>
                            </div>
                            {(materialType === "quizz" || materialType === "assignment") && <>
                                <div className="row mx-0 mb-4">
                                    <label className="col-sm-4 col-form-label">
                                        Due Date<sup className="text-danger fw-bold fs-15px">*</sup>
                                    </label>
                                    <div className="col-sm-8 col-xxl-7">
                                        <DatePicker
                                            selected={data && data.due_date ? data.due_date : new Date()}
                                            onChange={(e) => handleInputChange(e, "due_date")}
                                            className="form-control"
                                            minDate={new Date()}
                                            timeInputLabel="Time:"
                                            showTimeSelect
                                            dateFormat="MMM dd, yyyy h:mm aa"
                                            onChangeRaw={(e) => e.preventDefault()}
                                            id="due_date"
                                            name="due_date"
                                        />
                                    </div>
                                </div>
                                <div className="row mx-0 mb-4">
                                    <label htmlFor="total_grade" className="col-sm-4 col-form-label">
                                        Total Grade<sup className="text-danger fw-bold fs-15px">*</sup>
                                    </label>
                                    <div className="col-sm-8 col-xxl-7">
                                        <input type="number" min={0} className="form-control" placeholder="Total Grade" id="total_grade" name="total_grade" defaultValue={data && data.total_grade ? data.total_grade : ""} onChange={handleInputChange} required autoComplete="off" />
                                    </div>
                                </div>

                                <div className="row mx-0 mb-4">
                                    <label htmlFor="total_grade" className="col-sm-4 col-form-label">Restricted Pdf<sup className="text-danger fw-bold fs-15px">*</sup>
                                    </label>
                                    <div className="col-sm-8 col-xxl-7">
                                        <label className="p-1 me-3">
                                            <input
                                            type="radio"
                                            defaultChecked={isChecked}
                                            name="restricted_pdf"
                                            onClick={() => setIsChecked(true)}
                                            />{" "}
                                            Yes
                                        </label>
                                        <label>
                                            <input
                                            type="radio"
                                            name="restricted_pdf"
                                            defaultChecked={!isChecked}
                                            onClick={() => setIsChecked(false)}
                                            />{" "}
                                            No
                                        </label>
                                    </div>
                                </div>
                            </>}
                            {scheduleTimeEnable && <>
                                <div className="row mx-0 mb-4">
                                    <label className="col-sm-4 col-form-label">
                                        Schedule Time<sup className="text-danger fw-bold fs-15px"></sup>
                                    </label>
                                    <div className="col-sm-8 col-xxl-7">
                                        <DatePicker
                                            selected={data && data.schedule_time ? data.schedule_time : null}
                                            onChange={(e) => handleInputChange(e, "schedule_time")}
                                            className="form-control"
                                            minDate={new Date()}
                                            timeInputLabel="Time:"
                                            showTimeSelect
                                            dateFormat="MMM dd, yyyy h:mm aa"
                                            name="schedule_time"
                                            id="schedule_time"
                                            onChangeRaw={(e) => e.preventDefault()}
                                        />
                                        <div className={opPerform === "edit" ? "d-none" : "text-success"}>
                                            Please enter schedule date and time when create a schedule time.
                                        </div>
                                    </div>
                                </div>
                            </>}
                            <div className="row mx-0">
                                <div className="col-sm-8 offset-sm-4">
                                    {opPerform === "create" ? (
                                        <>
                                            <button type="button" className="btn btn-orange minw-120px me-2 mb-2" onClick={saveData} title="Submit">
                                                Submit
                                            </button>
                                            <button type="button" className="btn btn-blue minw-120px me-2 mb-2" onClick={savescheduleData} title="Schedule">
                                                Schedule
                                            </button>
                                            <button type="button" className="btn btn-warning minw-120px mb-2" onClick={saveAsDraft} title="Draft">
                                                Draft
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button type="button" className="btn btn-orange minw-120px me-2" onClick={updateData} title={data && data.status === "draft" ? "Publish" : "Update"}>
                                                {data && data.status === "draft" ? "Publish" : "Update"}
                                            </button>
                                            <button type="button" className="btn btn-blue minw-120px me-2" onClick={updatescheduleData} title="Schedule">
                                                Schedule
                                            </button>
                                            {data && data.status === "draft" && <>
                                                <button type="button" className="btn btn-warning minw-120px" onClick={updateAsDraft} title="Draft">
                                                    Draft
                                                </button>
                                            </>}
                                        </>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MaterilDetailsAddModal
