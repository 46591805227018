import React, {useEffect} from "react";
import {fetchData} from "../Helper";
import {useState} from "react";
//import { useLocation } from 'react-router-dom';

export default function SendReminderButton(props) {
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        
    }, [loading]);
    const sendReminder = (id, course_id) => {
        setLoading(true);
        fetchData(
            `student-reminder/${id}/${course_id}`,
            "GET",
            '',
            true,
            false,
            (res) => {
                setLoading(false);
                props.modal(false);
            },
            (err) => {}
        );
    };
    return (
        <div>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                {loading ? (
                    <>
                        <button
                            type="button"
                            className="btn btn-blue w-120px px-4 me-2 float-right"
                            title="Loading..."
                            disabled
                        >
                            Sending...
                        </button>
                    </>
                ) : (
                    <button
                        type="button"
                        className={`btn btn-success w-320px px-3 me-1 float-right`}
                        title="Send Reminder"
                        onClick={() => sendReminder(props.studentid, props.course_id)}
                    >
                        Send Reminder
                    </button>
                )}
            </div>
        </div>
    );
}
